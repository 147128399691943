import React, { useEffect, useState } from 'react'

import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

import { CameraLiveness as Liveness } from '@interco/inter-frontend-liveness-unico-lib'
import { ApplicationState } from '@store/types'
import { BridgeService } from '@services/bridge'
import { interWbSession } from '@interco/inter-webview-bridge'
import { Page } from '@templates'
import { ConveniosCodes, StepRefin } from '@utils/enums'
import routes from '@routes/RefinV2/routes'
import {
  postFotoBiometriaLiveness,
  setBase64,
  setEncrypted,
  setError,
} from '@store/biometria/actions'
import { trackingRefin } from '@store/refinanciamento/simulacao/actions'
import { Loading } from '@atoms'
import Tag from '@utils/Tag'

const modelsPath = `${process.env.PUBLIC_URL}/models`
const resourcePath = `${process.env.PUBLIC_URL}/resources`

export const configByEnviroment = (convenio: string) => {
  if (convenio === String(ConveniosCodes.INSS)) {
    return {
      projectId: 'InterPF',
      projectNumber: '9101052449336167334',
      mobileSdkAppId: '3:980239:js',
      hostname: 'https://proposta-consignado.uatbi.com.br',
      hostInfo: 'nRMqSJJeWMZ0K4n9Dxs/Zhb5RslAxes+pmH0gJgmVtYGpJIr0bKTzgxYiplLTVG3=',
      hostKey: 'nOXTBLpBy/2NcYtc/jycYv7WDnJ3A8BIIR/HLfSWeLUx7xukWav48Fl7uJPEa8f0',
    }
  }
  return {
    projectId: 'InterPF',
    projectNumber: '46049741958223038014',
    mobileSdkAppId: '3:980239:js',
    hostname: 'https://proposta-consignado.uatbi.com.br',
    hostInfo: 'nRMqSJJeWMZ0K4n9Dxs/Zhb5RslAxes+pmH0gJgmVtYGpJIr0bKTzgxYiplLTVG3',
    hostKey: 'OrhY5+jllQNg+FczKaUxqxpW55obg7p8nKGxhNwNyrxFhpS5Rbxlb0ezUQkUdFE0',
  }
}

type IResponseSucess = {
  encrypted: string
  base64: string
}

type ErrorPictureResponse = {
  code: number
  message: string
  type: string
  stack: Record<string, unknown>
}

const VideoSelfie = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [habilitaCamera, setHabilitaCamera] = useState(true)
  const { retryLiveness } = useSelector((state: ApplicationState) => state.biometria)
  const { convenioCodigo } = useSelector(
    (state: ApplicationState) => state.refinanciamento.contratos,
  )

  useEffect(() => {
    dispatch(trackingRefin(StepRefin.GRAVAR_VIDEO_SELFIE))
    if (!BridgeService.isBrowser()) {
      interWbSession.editToolbar('Tire uma foto do seu rosto', true)
    }
  }, [dispatch])

  useEffect(() => {
    if (retryLiveness) {
      setHabilitaCamera(false)
      navigate(-1)
    }
  }, [navigate, retryLiveness])

  return habilitaCamera ? (
    <Liveness
      configParameter={configByEnviroment(String(convenioCodigo))}
      pathResources={resourcePath}
      pathModels={modelsPath}
      callSuccessAction={(obj: IResponseSucess) => {
        dispatch(setBase64(obj.base64))
        dispatch(setEncrypted(obj.encrypted))
        setHabilitaCamera(false)
        dispatch(
          postFotoBiometriaLiveness({
            base64: obj.base64,
            encrypted: obj.encrypted,
            callback: () => {
              dispatch(trackingRefin(StepRefin.CONCLUSAO_ENVIO_DOCUMENTOS))
              Tag.enviarTagueamentoWeb({
                element_name: '',
                section_name: 'Biometria',
                element_action: 'submit',
                c_page: window.location.href,
                redirect_url: `${window.location.origin}${routes.REVISAO}`,
                step: 'null',
              })
              navigate(routes.REVISAO)
            },
          }),
        )
      }}
      callErrorAction={(err: ErrorPictureResponse) => {
        setError(Boolean(err.message))
      }}
    />
  ) : (
    <Page>
      <Loading height="150px" />
    </Page>
  )
}
export default VideoSelfie
