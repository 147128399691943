import React, { useEffect } from 'react'

import { useNavigate } from 'react-router-dom'
import { useDispatch } from 'react-redux'

import LightOn from '@interco/icons/core/action-navigation/ic_lightbulb_on'
import Security from '@interco/icons/core/privacy-security/ic_padlock'
import FeceId from '@interco/icons/core/privacy-security/ic_face_id'
import { Button } from '@interco/inter-ui/components/Button'
import { BridgeService } from '@services/bridge'
import { interWbSession } from '@interco/inter-webview-bridge'
import { Page } from '@templates'
import { ColorWrapper, TextParagraph } from '@atoms/TextParagraph'
import { Colors } from '@utils/Colors'
import routes from '@routes/RefinV2/routes'
import { trackingRefin } from '@store/refinanciamento/simulacao/actions'
import { StepRefin } from '@utils/enums'
import Tag from '@utils/Tag'

import { Container, DivLine, LineDescription } from './styles'

const Intrucoes = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  useEffect(() => {
    dispatch(trackingRefin(StepRefin.ORIENTACOES_VIDEO_SELFIE))
    if (!BridgeService.isBrowser()) {
      interWbSession.editToolbar('Validação de segurança', true)
    }
  }, [dispatch])

  return (
    <Page
      positionFooter="sticky"
      stickyFooter={
        <Button
          onClick={() => {
            Tag.enviarTagueamentoWeb({
              element_name: 'Tirar foto',
              section_name: 'Instruções biometria',
              element_action: 'click button',
              c_page: window.location.href,
              redirect_url: `${window.location.origin}${routes.BIOMETRIA}`,
              step: 'null',
            })
            navigate(routes.BIOMETRIA)
          }}
        >
          Tirar foto
        </Button>
      }
    >
      <ColorWrapper color={Colors.GRAY500} fontWeight="600" margin="22px 0 24px 0">
        <TextParagraph variant="headline-h1">Agora, precisamos tirar uma foto sua</TextParagraph>
      </ColorWrapper>
      <Container>
        <LineDescription>
          <LightOn width={24} height={24} color={Colors.GRAY500} />
          <DivLine>
            <TextParagraph variant="body-3">Escolha um ambiente bem iluminado.</TextParagraph>
          </DivLine>
        </LineDescription>
        <LineDescription>
          <FeceId width={24} height={24} color={Colors.GRAY500} />
          <DivLine>
            <TextParagraph variant="body-3">
              Centralize seu rosto na câmera e retire acessórios, como: boné, óculos ou afins.
            </TextParagraph>
          </DivLine>
        </LineDescription>
        <LineDescription>
          <Security width={24} height={24} color={Colors.GRAY500} />
          <DivLine>
            <TextParagraph variant="body-3">
              Você utilizará a própria câmera do seu celular e o processo é confidencial.
            </TextParagraph>
          </DivLine>
        </LineDescription>
      </Container>
    </Page>
  )
}
export default Intrucoes
