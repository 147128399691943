import styled from 'styled-components'

import { Colors } from '@utils/Colors'

interface PropsContent {
  isWeb?: boolean
  footerTwoButtonsSpace?: string
}

interface PropsFooterContent {
  stickyContainerFooterBottom?: string
  positionFooter?: string
}

export const Container = styled.div`
  margin: 1.5rem auto;
  max-width: 600px;
  display: grid;

  @media (max-width: 720px) {
    margin: 1.5rem;
  }
`

export const Content = styled.div<PropsContent>`
  display: flex;
  flex-direction: column;
  min-width: 35vw;
  min-height: ${({ isWeb, footerTwoButtonsSpace }) =>
    isWeb ? 'calc(100vh - 390px)' : `calc(100vh - ${footerTwoButtonsSpace})`};
`

export const ContainerFooter = styled.div<PropsFooterContent>`
  display: flex;
  flex-direction: column;
  margin: 3vh 0 0;
  row-gap: 2vh;
  ${(props) =>
    props.stickyContainerFooterBottom &&
    `
    bottom: ${props.stickyContainerFooterBottom};
    background: ${Colors.WHITE};
    `}
  ${(props) => props.positionFooter && `position: ${props.positionFooter};`}
`
