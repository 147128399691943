import { Reducer } from 'redux'

import { RefinanciamentoTypes } from '@store/refinanciamento/actions'

import { SimulacaoState, SimulacaoTypes } from './types'

const INITIAL_STATE: SimulacaoState = {
  parcelaAtual: {
    numberOfInstallments: 0,
    requestedAmount: 0,
    installmentAmount: 0,
    availableAmount: 0,
    amountIOF: 0,
    percentageIOF: 0,
    financedAmount: 0,
    cETAm: 0,
    cETAa: 0,
    monthlyNominalRate: 0,
    yearlyNominalRate: 0,
    contractualInterest: 0,
    lastDueDate: '',
    firstDueDate: '',
    moneyChange: 0,
    insuranceFee: 0,
  },
  simulations: [],
  simulationsWithInsurance: [],
  debtBalance: 0,
  totalParcelas: 0,
  simulacaoSelecionada: 'simulationsWithInsurance',
  loadingSimulacao: false,
  errorSimulacao: false,
  loadingLead: false,
  errorLead: false,
  simulacaoId: 0,
  loadingGetSimulacao: false,
  counterRecall: 0,
  dadosSimulacaoAlterados: false,
}

const reducer: Reducer<SimulacaoState> = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SimulacaoTypes.SIMULACAO_REQUEST:
      return { ...state, loadingSimulacao: true, errorSimulacao: false }
    case SimulacaoTypes.SIMULACAO_SUCCESS:
      return {
        ...state,
        loadingSimulacao: false,
        errorSimulacao: false,
        ...action.payload,
      }
    case SimulacaoTypes.SIMULACAO_ERROR:
      return { ...state, loadingSimulacao: false, errorSimulacao: true }

    case SimulacaoTypes.SALVAR_LEAD_REQUEST:
      return {
        ...state,
        loadingLead: true,
        errorLead: false,
        leadEnviado: false,
      }
    case SimulacaoTypes.SALVAR_LEAD_SUCCESS:
      return {
        ...state,
        loadingLead: false,
        errorLead: false,
        leadEnviado: true,
      }
    case SimulacaoTypes.SALVAR_LEAD_ERROR:
      return { ...state, loadingLead: false, errorLead: true }

    case SimulacaoTypes.SET_PARCELA_SELECIONADA:
      return { ...state, parcelaAtual: action.payload }
    case SimulacaoTypes.SET_SALDO_DEVEDOR:
      return { ...state, saldoDevedor: action.payload }
    case SimulacaoTypes.SET_TOTAL_PARCELAS:
      return { ...state, totalParcelas: action.payload }
    case SimulacaoTypes.SELECT_SIMULACAO:
      return { ...state, simulacaoSelecionada: action.payload }
    case SimulacaoTypes.SET_SIMULACAO_ID:
      return { ...state, simulacaoId: action.payload }
    case SimulacaoTypes.LOADING_SIMULACAO_ID:
      return { ...state, loadingGetSimulacao: action.payload }
    case SimulacaoTypes.SET_COUNTER_RECALL:
      return { ...state, counterRecall: state.counterRecall + action.payload }
    case SimulacaoTypes.SET_DADOS_SIMULACAO_ALTERADOS:
      return { ...state, dadosSimulacaoAlterados: action.payload }
    case SimulacaoTypes.CLEAR_SIMULACAO:
      return { ...INITIAL_STATE }
    case RefinanciamentoTypes.RESET:
      return { ...INITIAL_STATE }
    default:
      return state
  }
}

export default reducer
