export interface VariantDialogContratos {
  title: string
  text: string
}

export const dialogVariants = {
  SaibaMais: {
    title: 'Contratos disponíveis para refinanciamento',
    text: 'É possível refinanciar contratos de empréstimo consignado que tenham mais de 6 parcelas pagas. Você pode juntar contratos disponíveis que sejam do mesmo convênio e da mesma matrícula.',
  },
  SelecaoContratos: {
    title: 'Seleção de contratos',
    text: 'Os contratos precisam ser do mesmo convênio e matrícula para serem selecionados em um único refinanciamento.',
  },
  Indisponivel: {
    title: 'Indisponível',
    text: 'O Contrato não está disponível para refinanciamento.',
  },
}
