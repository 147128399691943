import { call, put, select } from 'typed-redux-saga'
import { AnyAction } from 'redux-saga'

import services from '@services'
import { showError } from '@store/ui/error/actions'
import { ErrorCodes } from '@utils/enums'
import { ApplicationState } from '@store/types'
import { BridgeService } from '@services/bridge'
import { HttpStatus } from '@services/base'
import { logout } from '@utils/functions'
import { ErroType } from '@utils/types'

import { CriacaoPropostaPayload } from '../types'
import { criarProposta, criarPropostaError, criarPropostaSuccess } from '../actions'

export default function* postCriarProposta(action: AnyAction) {
  const payload = action.payload as CriacaoPropostaPayload
  const { simulacaoId } = yield* select(
    (state: ApplicationState) => state.refinanciamento.simulacao,
  )
  const { matriculaSelecionada } = yield* select(
    (state: ApplicationState) => state.refinanciamento.contratos,
  )
  const { accessToken } = yield* select((state: ApplicationState) => state.session)
  try {
    const { data } = yield* call(
      services.postProposta,
      {
        simulacaoId,
        tipoSimulacao: 'REFINANCIAMENTO',
        matricula: matriculaSelecionada,
      },
      accessToken,
    )
    yield put(criarPropostaSuccess(data.codigo))
    if (typeof payload?.callback === 'function') {
      yield payload.callback()
    }
  } catch (error) {
    const erroTyped = error as ErroType
    yield put(criarPropostaError())
    if (BridgeService.isBrowser() && erroTyped.status === HttpStatus.UNAUTHORIZED) {
      yield put(
        showError({
          title: 'Sessão expirada',
          message: 'Efetue o login novamente.',
          actionTitle: 'Continuar',
          functionCallback: () => logout(),
        }),
      )
    } else if (erroTyped.status === HttpStatus.BAD_REQUEST) {
      if (typeof payload?.callbackError === 'function') {
        yield payload.callbackError()
      }
    } else {
      yield put(
        showError({
          title: 'Erro ao criar proposta',
          message: 'Ocorreu um erro ao criar proposta',
          actionTitle: 'Tentar Novamente',
          code: ErrorCodes.RE_007_CRIAR_PROPOSTA,
          actionCallback: () => criarProposta(payload),
        }),
      )
    }
  }
}
