export const variants = {
  autenticacaoRealizada: {
    title: 'Autenticação realizada',
    text: 'Confirmamos a autenticação do seu refinanciamento. Para contratar, selecione o botão continuar.',
    button: 'Continuar',
  },
  erroAutenticacao: {
    title: 'Erro na autenticação',
    text: 'Tivemos um problema ao autenticar. Tente novamente mais tarde.',
    button: 'Entendi',
  },
  erroAutorizacao: {
    title: 'Crédito indisponível',
    text: 'No momento, não foi possível te oferecer a Antecipação de Restituição de Imposto de Renda devido a políticas Internas do Inter.',
    button: 'Entendi',
  },
}
