import { BaseServices, HttpMethod } from '@services/base'
import { EstadosAPI } from '@store/refinanciamento/endereco/types'

import { BridgeService } from './bridge'

export default (accessToken?: string) => {
  const pathname = process.env.REACT_APP_GET_ESTADOS_BFF
  const method = HttpMethod.GET
  const host = process.env.REACT_APP_API_HOST
  const headers: Record<string, string> = {}
  if (BridgeService.isBrowser() && accessToken) {
    headers.Authorization = `Bearer ${accessToken}`
  }
  const response = BaseServices.request<EstadosAPI>({
    headers,
    pathname,
    method,
    host,
  })
  return response
}
