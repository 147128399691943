import { put, select, call } from 'typed-redux-saga'
import { AnyAction } from 'redux'

import postSalvarLeadRefinanciamento, {
  SalvarLeadRequest,
} from '@services/postSalvarLeadRefinanciamento'
import { ApplicationState } from '@store/types'
import {
  salvarLeadSuccess,
  salvarLeadError,
  salvarLeadRequest,
} from '@store/refinanciamento/simulacao/actions'
import { showError } from '@store/ui/error/actions'
import { ErrorCodes } from '@utils/enums'
import { setLoading } from '@store/refinanciamento/valida/actions'
import { BridgeService } from '@services/bridge'
import { HttpStatus } from '@services/base'
import { logout } from '@utils/functions'
import { ErroType } from '@utils/types'

import { SalvarLeadRequestPayload } from '../types'
import { buildLeadRequest } from './models'

export default function* postSalvarLead(action: AnyAction) {
  const payload = action.payload as SalvarLeadRequestPayload
  try {
    yield put(setLoading(true))
    const state = yield* select((s: ApplicationState) => s)
    const lead: SalvarLeadRequest = buildLeadRequest(state)
    yield* call(postSalvarLeadRefinanciamento, lead, state.session.accessToken)
    yield put(salvarLeadSuccess())
    yield put(setLoading(false))
    yield payload.callback()
  } catch (error) {
    const erroTyped = error as ErroType
    yield put(setLoading(false))
    yield put(salvarLeadError())
    if (BridgeService.isBrowser() && erroTyped.status === HttpStatus.UNAUTHORIZED) {
      yield put(
        showError({
          title: 'Sessão expirada',
          message: 'Efetue o login novamente.',
          actionTitle: 'Continuar',
          functionCallback: () => logout(),
        }),
      )
    } else {
      yield put(
        showError({
          title: 'Erro ao salvar',
          message: 'Ocorreu um erro ao salvar suas informações',
          actionTitle: 'Tentar Novamente',
          block: true,
          code: ErrorCodes.RE_003_SALVAR_LEAD,
          actionCallback: () => salvarLeadRequest(payload),
        }),
      )
    }
  }
}
