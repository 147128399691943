import styled, { keyframes } from 'styled-components'

import { Colors } from '@utils/Colors'

const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`

export const FadeIn = styled.div`
  animation: ${fadeIn} 0.5s ease-in-out;
`

export const LoadingComponent = styled.div<{
  backgroundColor: string
  size: string
}>`
  width: ${({ size }) => size};
  height: 24px;
  background: ${({ backgroundColor }) => backgroundColor};
  border-radius: 100px;
  transition: width 0.5s;
`

export const TextContainer = styled.div<{
  size: string
}>`
  display: flex;
  flex-flow: column wrap;
  text-align: left;
  margin-bottom: 10px;
  span {
    font-style: normal;
    font-weight: 600;
    line-height: 30px;
    color: ${Colors.GRAY500};
  }
`
