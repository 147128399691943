import { BaseServices, HttpMethod } from '@services/base'

import { BridgeService } from './bridge'

export default (cpf: string, accessToken?: string) => {
  const pathname = `${process.env.REACT_APP_CONSULTAR_SIMULACAO}?socialNumber=${cpf}`
  const method = HttpMethod.GET
  const host = process.env.REACT_APP_API_HOST
  const headers: Record<string, string> = { socialNumber: cpf }
  if (BridgeService.isBrowser() && accessToken) {
    headers.Authorization = `Bearer ${accessToken}`
  }

  const response = BaseServices.request<{ simulationId: number }>({
    headers,
    pathname,
    method,
    host,
  })
  return response
}
