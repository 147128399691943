import React from 'react'

import { useDispatch, useSelector } from 'react-redux'

import { formatCurrency } from '@utils/masks'
import { Colors } from '@utils/Colors'
import { ColorWrapper, TextParagraph } from '@atoms/TextParagraph'
import { BottomSheet } from '@interco/inter-ui/components/BottomSheet'
import { ApplicationState } from '@store/types'
import { StepRefin } from '@utils/enums'
import { useTrackingRefin } from '@hooks'
import IcChevronRight from '@interco/icons/core/action-navigation/ic_chevron_right'
import {
  setDadosSimulacaoAlterados,
  setParcelaSelecionada,
  trackingRefin,
} from '@store/refinanciamento/simulacao/actions'

import { LineInfo, TitleLine, Bolder, ListStyle } from './styles'

interface OwnProps {
  setShowBottomsheetParcelas: React.Dispatch<React.SetStateAction<boolean>>
}

const Parcelas = ({ setShowBottomsheetParcelas }: OwnProps) => {
  const dispatch = useDispatch()
  const parcelasDisponiveis = useSelector(
    (state: ApplicationState) => state.refinanciamento.simulacao,
  )
  const { simulacaoSelecionada } = useSelector(
    (state: ApplicationState) => state.refinanciamento.simulacao,
  )

  useTrackingRefin(StepRefin.PARCELAMENTO)

  return (
    <BottomSheet noSwipe onClose={() => setShowBottomsheetParcelas(false)}>
      <TitleLine>
        <ColorWrapper color={Colors.GRAY500} fontWeight="600" margin="0">
          <TextParagraph variant="headline-h3">Opções de parcelas</TextParagraph>
        </ColorWrapper>
      </TitleLine>

      <ListStyle>
        {parcelasDisponiveis[simulacaoSelecionada]?.map((opcaoParcela, index) => (
          <LineInfo
            id={`line-select-${index}`}
            key={`line-select-${opcaoParcela.numberOfInstallments}`}
            onClick={() => {
              dispatch(trackingRefin(StepRefin.SELECIONADO_PARCELAMENTO))
              dispatch(setParcelaSelecionada(opcaoParcela))
              dispatch(setDadosSimulacaoAlterados(true))
              setShowBottomsheetParcelas(false)
            }}
          >
            <ColorWrapper color={Colors.GRAY500} margin="0">
              <TextParagraph variant="body-3">
                <Bolder>{opcaoParcela.numberOfInstallments}x</Bolder>{' '}
                {formatCurrency(opcaoParcela.installmentAmount)}
              </TextParagraph>
            </ColorWrapper>
            <IcChevronRight height={24} width={24} color={Colors.PRIMARY500} />
          </LineInfo>
        ))}
      </ListStyle>
    </BottomSheet>
  )
}
export default Parcelas
