import { BaseServices, HttpMethod } from '@services/base'

import { BridgeService } from './bridge'

export interface DataCreatePropostaType {
  simulacaoId: number
  tipoSimulacao: string
}

export interface CreatePropostaRequest {
  codigo: string
}

export default async (data: DataCreatePropostaType, accessToken?: string) => {
  const pathname = process.env.REACT_APP_CRIAR_PROPOSTA
  const method = HttpMethod.POST
  const host = process.env.REACT_APP_API_HOST
  const headers: Record<string, string> = {}
  if (BridgeService.isBrowser() && accessToken) {
    headers.Authorization = `Bearer ${accessToken}`
  }

  const response = await BaseServices.request<CreatePropostaRequest>({
    headers,
    pathname,
    method,
    host,
    data,
  })
  return response
}
