import { action, Action } from 'typesafe-actions'

import { BuscarPayload, DocumentosTypes } from './types'

export const setTipoDocumento = (payload: string | number | undefined): Action =>
  action(DocumentosTypes.SET_TIPO_DOCUMENTO, payload)

export const setEstadoSelecionado = (payload: string | number | undefined): Action =>
  action(DocumentosTypes.SET_ESTADO_SELECIONADO, payload)

export const setNumeroDocumento = (payload: string | number | undefined): Action =>
  action(DocumentosTypes.SET_NUMERO_DOCUMENTO, payload)

export const setOrgaoExpedidor = (payload: string | number | undefined): Action =>
  action(DocumentosTypes.SET_ORGAO_EXPEDIDOR, payload)

export const setDataEmissao = (payload: string | number | undefined): Action =>
  action(DocumentosTypes.SET_DATA_EMISSAO, payload)

export const setNomeMae = (payload: string | number | undefined): Action =>
  action(DocumentosTypes.SET_NOME_MAE, payload)

export const setGenero = (payload: string | number | undefined): Action =>
  action(DocumentosTypes.SET_GENERO, payload)

export const orgaosRequest = (): Action => action(DocumentosTypes.ORGAO_EXPEDIDOR_REQUEST)

export const orgaosSuccess = (payload: string[]): Action =>
  action(DocumentosTypes.ORGAO_EXPEDIDOR_SUCCESS, payload)

export const orgaosError = (): Action => action(DocumentosTypes.ORGAO_EXPEDIDOR_ERROR)

export const showLoading = (): Action => action(DocumentosTypes.SHOW_LOADING)

export const hideLoading = (): Action => action(DocumentosTypes.HIDE_LOADING)

export const postCapturaDocumentos = (): Action =>
  action(DocumentosTypes.CAPTURA_DOCUMENTOS_REQUEST)

export const setNome = (payload: string): Action =>
  action(DocumentosTypes.SET_NOME_CLIENTE, payload)

export const setTelefone = (payload: string): Action =>
  action(DocumentosTypes.SET_TELEFONE, payload)

export const setEmail = (payload: string): Action => action(DocumentosTypes.SET_EMAIL, payload)

export const setValorLiberado = (payload: string): Action =>
  action(DocumentosTypes.SET_VALOR_LIBERADO, payload)

export const setValorParcela = (payload: string): Action =>
  action(DocumentosTypes.SET_VALOR_PARCELA, payload)

export const setQtdParcelas = (payload: string): Action =>
  action(DocumentosTypes.SET_QTD_PARCELAS, payload)

export const setTaxa = (payload: string): Action => action(DocumentosTypes.SET_TAXA, payload)

export const setCet = (payload: string): Action => action(DocumentosTypes.SET_CET, payload)

export const setValorTotal = (payload: string): Action =>
  action(DocumentosTypes.SET_VALOR_TOTAL, payload)

export const setIdClient = (payload: string): Action =>
  action(DocumentosTypes.SET_ID_CLIENT, payload)

export const setIsReassinatura = (payload: string): Action =>
  action(DocumentosTypes.SET_IS_REASSINATURA, payload)

export const setCreditoProtegido = (payload: string): Action =>
  action(DocumentosTypes.SET_CREDITO_PROTEGIDO, payload)

export const clearStore = (): Action => action(DocumentosTypes.RESET)

export const carregarCaptura = (): Action => action(DocumentosTypes.CARREGAR_CAPTURA_REQUEST)

export const postDocumentosAssinatura = (): Action =>
  action(DocumentosTypes.DOCUMENTOS_ASSINATURA_REQUEST)

export const buscarDocumentos = (payload?: BuscarPayload): Action =>
  action(DocumentosTypes.BUSCAR_DOCUMENTOS_SAGA, payload)

export const setEtapaBusca = (payload: number): Action =>
  action(DocumentosTypes.SET_ETAPA_BUSCA, payload)

export const setErroUpdateProposal = (payload: boolean): Action =>
  action(DocumentosTypes.SET_ERRO_UPDATE_PROPOSAL, payload)
