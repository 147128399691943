import React, { useEffect, useState } from 'react'

import { useNavigate } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { useSelector } from 'react-redux'

import { IWbISafeResponse } from '@interco/inter-webview-bridge'
import { Page } from '@templates'
import imagemOnboarding from '@images/OnboardingRefin2x.png'
import { VerticalListNumber } from '@molecules'
import { Loading, Paragraph, Separator } from '@atoms'
import { Colors } from '@utils/Colors'
import { Checkbox } from '@interco/inter-ui/components/Checkbox'
import { Button } from '@interco/inter-ui/components/Button'
import ChevronRight from '@interco/icons/core/action-navigation/ic_chevron_right'
import { ISafeBottomSheet } from '@interco/inter-ui/components/BottomSheet'
import routes from '@routes/Refin/routes'
import { getSimulacaoId } from '@store/refinanciamento/simulacao/actions'
import { isOldBridge } from '@services/base/oldBridge'
import { BridgeService } from '@services/bridge'
import { postIsafeAutorizacao } from '@store/refinanciamento/valida/actions'
import { criarProposta } from '@store/refinanciamento/proposta/actions'
import { ApplicationState } from '@store/types'

import { LinkPrivacidade, ImgOnboarding, PFlexStart, WrapLoadingText } from './style'
import { BottomSheetVariant } from './Bottomsheet/BottomSheetVariant'
import { variants } from './Bottomsheet/Variants'

const OnboardingContrato = () => {
  const [isChecked, setIsChecked] = useState(false)
  const [showBottomSheet, setShowBottomSheet] = useState(false)
  const [showISafe, setShowISafe] = useState(false)
  const [variant, setVariant] = useState(variants.confirmarRefinanciamento)
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { loadingCriarProposta, numeroProposta } = useSelector(
    (state: ApplicationState) => state.refinanciamento.proposta,
  )
  const { loadingGetSimulacao } = useSelector(
    (state: ApplicationState) => state.refinanciamento.simulacao,
  )

  const { conta } = useSelector((state: ApplicationState) => state.refinanciamento.dadosPessoais)

  const consultaMargemInss = useSelector(
    (state: ApplicationState) => state.refinanciamento.consultaMargemInss,
  )
  const { contratosSelecionados } = useSelector(
    (state: ApplicationState) => state.refinanciamento.contratos || [],
  )
  let valorProximaParcela = 0
  contratosSelecionados.map((contrato) => {
    valorProximaParcela += contrato.valorProximaParcela
    return true
  })

  const valor = consultaMargemInss.consultaMargemRealizada
    ? consultaMargemInss.valorTotalParcela
    : valorProximaParcela

  let isafeResponse: IWbISafeResponse | undefined

  useEffect(() => {
    dispatch(
      getSimulacaoId({
        callback: () => {
          if (!numeroProposta) {
            dispatch(criarProposta())
          }
        },
      }),
    )
  }, [dispatch, numeroProposta])

  const onAuthenticateComplete = (status: boolean) => {
    if (status && isafeResponse) {
      const { authenticationToken, authenticationType, authenticationValue } = isafeResponse

      dispatch(
        postIsafeAutorizacao({
          callback: () => {
            setVariant(variants.autenticacaoRealizada)
            setShowBottomSheet(true)
          },
          erro: () => {
            setVariant(variants.erroAutenticacao)
            setShowBottomSheet(true)
          },
          tipoAutenticacao: `${authenticationType || ''}`,
          token: `${authenticationToken || ''}`,
          valor: `${authenticationValue || ''}`,
          conta: conta || '',
        }),
      )
    } else {
      setVariant(variants.erroAutenticacao)
      setShowBottomSheet(true)
    }
  }

  const iSafeAuthenticate = async (): Promise<void> => {
    if (!isOldBridge() && !BridgeService.isBrowser()) {
      isafeResponse = await BridgeService?.authenticateRequestISafe(valor)
    }
    return Promise.resolve()
  }

  const SafeData = () => (
    <>
      <Separator variant="small" margin="0 0 24px" />

      <Checkbox
        id="checkbox-onboarding"
        reversed
        onChange={(ev) => setIsChecked(ev.target.checked)}
        checked={isChecked}
        label={
          <>
            <Paragraph
              fontSize="14px"
              fontWeight="700"
              variant="inter"
              lineHeight="17px"
              color={Colors.GRAY500}
              textAlign="left"
            >
              Privacidade de dados
            </Paragraph>
            <Paragraph
              fontSize="14px"
              fontWeight="400"
              variant="inter"
              lineHeight="17px"
              color={Colors.GRAY500}
              textAlign="left"
            >
              Autorizo o Inter a tratar meus dados para a contratação do refinanciamento e entre em
              contato comigo. Também concordo com a
              <LinkPrivacidade href="https://www.bancointer.com.br/politica-de-privacidade/">
                {' '}
                Política de Privacidade{' '}
              </LinkPrivacidade>
              do Inter.
            </Paragraph>
          </>
        }
      />
    </>
  )

  if (loadingGetSimulacao || loadingCriarProposta) {
    return (
      <Page>
        <WrapLoadingText>
          <Loading width={50} height={50} />
          <Paragraph margin="14px 0" fontSize="14px" fontWeight={700} color={Colors.GRAY500}>
            Criando proposta...
          </Paragraph>
        </WrapLoadingText>
      </Page>
    )
  }

  return (
    <Page
      stickyFooter={
        <Button
          variant="primary"
          onClick={() => {
            if (isOldBridge() || BridgeService.isBrowser()) {
              navigate(routes.CONFERIR_REFINANCIAMENTO)
            } else {
              setVariant(variants.confirmarRefinanciamento)
              setShowBottomSheet(true)
            }
          }}
          disabled={!isChecked || loadingCriarProposta || loadingGetSimulacao || !numeroProposta}
          isLoading={loadingCriarProposta || loadingGetSimulacao}
          icon={
            <ChevronRight
              height={24}
              width={24}
              color={!isChecked ? Colors.GRAY300 : Colors.WHITE}
            />
          }
        >
          Continuar
        </Button>
      }
    >
      <ImgOnboarding src={imagemOnboarding} alt="Homem sorrindo com celular na mão" />
      <PFlexStart
        fontSize="24px"
        fontWeight="600"
        variant="sora"
        lineHeight="28px"
        color={Colors.GRAY500}
        textAlign="left"
        margin="20px 0 16px"
      >
        Falta pouco para a contratação
      </PFlexStart>
      <Paragraph
        fontSize="14px"
        fontWeight="normal"
        variant="inter"
        lineHeight="17px"
        color={Colors.GRAY400}
        textAlign="left"
        margin="0 0 30px"
      >
        Para finalizarmos sua contratação do refinanciamento, precisamos que você siga as seguintes
        etapas:
      </Paragraph>
      <VerticalListNumber list={['Verificação de Segurança', 'Confirmar contrato']} />
      <SafeData />
      {showBottomSheet ? (
        <BottomSheetVariant
          callFunction={() => {
            setShowBottomSheet(false)
            if (variant === variants.confirmarRefinanciamento) {
              setShowISafe(true)
            } else if (variant !== variants.erroAutenticacao) {
              navigate(routes.CONFERIR_REFINANCIAMENTO)
            }
          }}
          setShowBottomSheet={() => {
            setShowBottomSheet(false)
          }}
          titleVariant={variant.title}
          descriptionVariant={variant.text}
          buttonVariant={variant.button}
        />
      ) : (
        <></>
      )}
      {showISafe ? (
        <ISafeBottomSheet
          authenticate={iSafeAuthenticate}
          onComplete={onAuthenticateComplete}
          onClose={() => setShowISafe(false)}
          description="Para sua segurança, estamos autenticando o seu cadastro com o i-Safe."
        />
      ) : (
        <></>
      )}
    </Page>
  )
}

export default OnboardingContrato
