/**
 * Actions Types
 */

export enum DadosBancariosTypes {
  BANCOS_REQUEST = '@refinanciamento/dadosBancarios/BANCOS_REQUEST',
  BANCOS_SUCCESS = '@refinanciamento/dadosBancarios/BANCOS_SUCCESS',
  BANCOS_LOADING = '@refinanciamento/dadosBancarios/BANCOS_LOADING',
  BANCOS_ERROR = '@refinanciamento/dadosBancarios/BANCOS_ERROR',

  SET_BANCO_SELECIONADO = '@refinanciamento/dadosBancarios/SET_BANCO_SELECIONADO',
  SET_TIPO_CONTA = '@refinanciamento/dadosBancarios/SET_TIPO_CONTA',
  SET_TIPO_CREDITO_INSS = '@refinanciamento/dadosBancarios/SET_TIPO_CREDITO_INSS',
  SET_AGENCIA = '@refinanciamento/dadosBancarios/SET_AGENCIA',
  SET_CONTA = '@refinanciamento/dadosBancarios/SET_CONTA',
  SET_DIGITO = '@refinanciamento/dadosBancarios/SET_DIGITO',

  SALVAR_PROPOSTA_REQUEST = '@refinanciamento/dadosBancarios/SALVAR_PROPOSTA_REQUEST',
  SALVAR_PROPOSTA_SUCCESS = '@refinanciamento/dadosBancarios/SALVAR_PROPOSTA_SUCCESS',
  SALVAR_PROPOSTA_ERROR = '@refinanciamento/dadosBancarios/SALVAR_PROPOSTA_ERROR',

  DADOS_BANCARIOS_INSS_SUCCESS = '@refinanciamento/dadosBancarios/DADOS_BANCARIOS_INSS_SUCCESS',
  DADOS_BANCARIOS_INSS_REQUEST = '@refinanciamento/dadosBancarios/DADOS_BANCARIOS_INSS_REQUEST',
  DADOS_BANCARIOS_INSS_ERROR = '@refinanciamento/dadosBancarios/DADOS_BANCARIOS_INSS_ERROR',

  INFO_VALIDA = '@refinanciamento/dadosBancarios/INFO_VALIDA',
  SET_CONTATO = '@refinanciamento/dadosBancarios/SET_CONTATO',

  SET_VALIDA_LOADING = '@refinanciamento/dadosBancarios/SET_VALIDA_LOADING',
  RESET = 'RESET',
}

/**
 * Data Types
 */

export interface Banco {
  nome: string
  codigo: string
  ispb: string
}

/**
 * State Type
 */

export interface DadosBancariosState {
  readonly bancos?: Banco[]
  readonly bancosError?: boolean
  readonly bancosLoading?: boolean

  readonly bancoSelecionado?: string | undefined

  readonly tipoContaSelecionado?: number
  readonly tipoCreditoInss?: number
  readonly agencia: string
  readonly conta: string
  readonly digito: string

  readonly propostaEnviada: boolean
  readonly loading?: boolean
  readonly error?: boolean

  readonly dadosBancariosError?: boolean
  readonly dadosBancariosLoading?: boolean

  readonly contato?: boolean
  readonly validaLoading?: boolean
}

/**
 * APIs Type
 */

export interface BancosAPI {
  bancos: Banco[]
}

export interface DadosBancariosInssApi {
  data: DadosBancoInss
}

export interface DadosBancoInss {
  banco?: string
  agencia?: string
  conta?: string
  digitoConta?: string
  tipoCredito: string
}

/**
 * Actions Payload Types
 */

export type SalvarPropostaPayload = {
  callback: () => void
}

export interface ValidaPropostaPayload {
  callback?: (contato: boolean) => void
}
