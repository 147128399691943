import { action, Action } from 'typesafe-actions'

import {
  Cidade,
  Endereco,
  EnderecoPayload,
  EnderecoTypes,
  Estado,
  RiscoEnderecoPayload,
} from './types'

export const enderecoRequest = (payload: EnderecoPayload): Action =>
  action(EnderecoTypes.ENDERECO_REQUEST, payload)

export const enderecoSuccess = (payload: Endereco[] | null): Action =>
  action(EnderecoTypes.ENDERECO_SUCCESS, payload)
export const enderecoLoading = (): Action => action(EnderecoTypes.ENDERECO_LOADING)
export const enderecoError = (): Action => action(EnderecoTypes.ENDERECO_ERROR)

export const setCep = (payload: string): Action => action(EnderecoTypes.SET_CEP, payload)

export const setTipoLogradouro = (payload: string): Action =>
  action(EnderecoTypes.SET_TIPO_LOGRADOURO, payload)

export const setLogradouro = (payload: string): Action =>
  action(EnderecoTypes.SET_LOGRADOURO, payload)

export const setBairro = (payload: string): Action => action(EnderecoTypes.SET_BAIRRO, payload)

export const setNumero = (payload: string): Action => action(EnderecoTypes.SET_NUMERO, payload)

export const setComplemento = (payload: string): Action =>
  action(EnderecoTypes.SET_COMPLEMENTO, payload)

export const setEstadoSelecionado = (payload: string): Action =>
  action(EnderecoTypes.SET_ESTADO_SELECIONADO, payload)

export const setCidadeSelecionada = (payload: string): Action =>
  action(EnderecoTypes.SET_CIDADE_SELECIONADA, payload)

export const validarRiscoEnderecoRequest = (payload: RiscoEnderecoPayload): Action =>
  action(EnderecoTypes.RISCO_ENDERECO_REQUEST, payload)

export const cidadesSuccess = (payload: Cidade[] | null): Action =>
  action(EnderecoTypes.CIDADES_SUCCESS, payload)
export const cidadesLoading = (): Action => action(EnderecoTypes.CIDADES_LOADING)
export const cidadesError = (): Action => action(EnderecoTypes.CIDADES_ERROR)
export const cidadesRequest = (payload: string): Action =>
  action(EnderecoTypes.CIDADES_REQUEST, payload)

export const estadosRequest = (): Action => action(EnderecoTypes.ESTADOS_REQUEST)
export const estadosSuccess = (payload: Estado[] | null): Action =>
  action(EnderecoTypes.ESTADOS_SUCCESS, payload)
export const estadosLoading = (): Action => action(EnderecoTypes.ESTADOS_LOADING)
export const estadosError = (): Action => action(EnderecoTypes.ESTADOS_ERROR)
