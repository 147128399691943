import { BaseServices, HttpMethod } from '@services/base'

import { BridgeService } from './bridge'

export interface DataType {
  cpf: string
  proposta: string
  clientId: string
}

export default ({ cpf, proposta, clientId }: DataType, accessToken?: string) => {
  const pathname = `${process.env.REACT_APP_GET_POST_CAPTURA_REFIN}?cpf=${cpf}&proposta=${proposta}&clientid=${clientId}`
  const method = HttpMethod.GET
  const host = process.env.REACT_APP_API_HOST
  const headers: Record<string, string> = {}
  if (BridgeService.isBrowser() && accessToken) {
    headers.Authorization = `Bearer ${accessToken}`
  }
  const response = BaseServices.request({
    headers,
    pathname,
    method,
    host,
  })
  return response
}
