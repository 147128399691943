import { BaseServices, HttpMethod } from '@services/base'

import { BridgeService } from './bridge'

export default (data: SalvarLeadRequest, accessToken?: string) => {
  const pathname = process.env.REACT_APP_POST_SALVAR_REFINANCIAMENTO
  const method = HttpMethod.POST
  const host = process.env.REACT_APP_API_HOST
  const headers: Record<string, string> = {}
  if (BridgeService.isBrowser() && accessToken) {
    headers.Authorization = `Bearer ${accessToken}`
  }

  const response = BaseServices.request({
    headers,
    pathname,
    method,
    host,
    data,
  })
  return response
}

/**
 * API Types
 */
export interface SalvarLeadRequest {
  contracts: string[]
  socialNumber: string
  email: string
  phone: string
  numberOfInstallments: number
  installmentAmount: number
  requestedAmount: number
  debtBalance: number
  moneyChange: number
  percentageIOF: number
  cETAm: number
  contractualInterest: number
  gaClid: string
  categoryId: number
  categoryDescription: string
  agencyId: number
  agencyDescription: string
  firstName: string
  secondName: string
  origin: string
  utmSource: string
  utmContent: string
  utmMedium: string
  utmCampaign: string
  gaClientId: string
  clientId: string
  facebookId: string
  birthDate: string
  availableWhatsapp: boolean
  firstDueDate: string
  lastDueDate: string
  insuredCredit: boolean
  finalTotal: number
  productCode: number
  campaign: string
  registration: string
}
