import { call, put, select } from 'typed-redux-saga'

import { showError } from '@store/ui/error/actions'
import { ApplicationState } from '@store/types'
import { HttpStatus } from '@services/base'
import postLivenessBiometria, { IBiometria } from '@services/postLivenessBiometria'

import {
  postFotoBiometriaLiveness,
  setLoadingLiveness,
  setRetryLiveness,
  setUnicoId,
} from '../actions'

interface Props {
  payload: {
    base64: string
    encrypted: string
    callback: () => void
  }
  type: string
}

export type ErroType = {
  response: { status: HttpStatus; data: { message: string } }
  message: string
  status: HttpStatus
  data: {
    message: string
  }
}

export default function* postFotoBiometriaLivenessSaga({ payload }: Props) {
  const appState = yield* select((state: ApplicationState) => state)
  const cpfSession = yield* select((state: ApplicationState) => state.session.cpf)
  const cpfDados = yield* select(
    (state: ApplicationState) => state.refinanciamento.dadosPessoais.cpf,
  )
  const propostaAntiga = appState.refinanciamento.contratos.contratosSelecionados.map(
    (item) => item.numeroProposta,
  )[0]
  const numeroProposta = String(appState.refinanciamento.proposta.numeroProposta) || propostaAntiga
  const body: IBiometria = {
    document: cpfDados || cpfSession || '',
    name: appState.refinanciamento.dadosPessoais.nome,
    email: appState.refinanciamento.contato.email,
    celular: appState.refinanciamento.contato.telefone,
    proposalCode: numeroProposta ? String(numeroProposta) : '',
    onlySelfie: true,
    photo: payload.base64,
    encryptedPhoto: payload.encrypted,
  }
  try {
    const { data } = yield* call(postLivenessBiometria, body)

    yield put(setUnicoId(data.unicoId))
    yield put(setLoadingLiveness(false))
    yield payload.callback()
  } catch (error) {
    const erroTyped = error as ErroType
    yield put(setLoadingLiveness(false))
    if (
      erroTyped?.status === HttpStatus.PRECONDITION_FAILED ||
      erroTyped?.status === HttpStatus.BAD_REQUEST
    ) {
      yield put(
        showError({
          title:
            erroTyped?.message ||
            erroTyped?.data?.message ||
            erroTyped?.response.data.message ||
            'Ocorreu um erro ao validar a biometria',
          message: 'Por favor tente novamente.',
          actionTitle: 'Tentar Novamente',
          actionCallback: () => setRetryLiveness(true),
          block: true,
          historyBack: true,
        }),
      )
    } else {
      yield put(
        showError({
          title: 'Ocorreu um erro ao validar a biometria',
          message: 'Por favor tente novamente.',
          actionTitle: 'Tentar Novamente',
          actionCallback: () => postFotoBiometriaLiveness(payload),
          block: true,
        }),
      )
    }
  }
}
