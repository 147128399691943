import React from 'react'

import { useNavigate } from 'react-router-dom'

import { Dialog } from '@organisms'
import { Box, Paragraph } from '@atoms'
import { ListaDescricao } from '@molecules'
import { ContratoSelecionado } from '@store/refinanciamento/contratos/types'
import { formatCurrency } from '@utils/masks'
import routes from '@routes/Refin/routes'
import { Colors } from '@utils/Colors'

interface Props {
  setShowBottomSheetContratos: React.Dispatch<React.SetStateAction<boolean>>
  convenioSelecionado: string
  matriculaSelecionada: string
  contratosSelecionados: ContratoSelecionado[]
}
export const BottomSheetContratos = ({
  setShowBottomSheetContratos,
  convenioSelecionado,
  matriculaSelecionada,
  contratosSelecionados,
}: Props) => {
  const navigate = useNavigate()
  return (
    <Dialog
      onClose={() => setShowBottomSheetContratos(false)}
      headerTitle="Contratos desse refinanciamento"
      buttonText="Entendi"
      secondaryButtonText="Mudar os contratos"
      secondaryButtonAction={() => navigate(routes.CONTRATOS)}
    >
      <Paragraph
        variant="sora"
        fontWeight={600}
        fontSize="16px"
        lineHeight="19px"
        color={Colors.GRAY500}
      >
        {convenioSelecionado}
      </Paragraph>
      <Paragraph
        variant="inter"
        fontWeight="normal"
        fontSize="14px"
        lineHeight="17px"
        color={Colors.GRAY500}
      >
        {`${convenioSelecionado === 'INSS' ? 'Benefício' : 'Matricula'} ${matriculaSelecionada}`}
      </Paragraph>

      {contratosSelecionados?.map((contrato) => (
        <Box margin="16px 0px" key={contrato.numeroContrato} justify="flex-start">
          <ListaDescricao>
            <Paragraph
              variant="inter"
              fontWeight="normal"
              fontSize="12px"
              lineHeight="15px"
              color={Colors.GRAY400}
              margin="4px 0"
            >
              Valor da parcela
            </Paragraph>
            <Paragraph
              variant="inter"
              fontWeight="bold"
              fontSize="14px"
              lineHeight="17px"
              margin="4px 0px"
              color={Colors.GRAY500}
            >
              {formatCurrency(contrato.valorProximaParcela)}
            </Paragraph>
            <Paragraph
              variant="inter"
              fontWeight="normal"
              fontSize="12px"
              lineHeight="15px"
              color={Colors.GRAY400}
              margin="4px 0"
              textAlign="center"
            >{`Nº da proposta: ${contrato.numeroProposta}`}</Paragraph>
          </ListaDescricao>
        </Box>
      ))}
    </Dialog>
  )
}
