import React from 'react'

export default () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="12" cy="12" r="11" fill="var(--neutral)" stroke="var(--neutral)" strokeWidth="2" />
    <circle
      cx="12"
      cy="12.0001"
      r="9.28571"
      fill="var(--neutral)"
      stroke="var(--gray500)"
      strokeWidth="2"
    />
    <ellipse
      cx="16.2858"
      cy="11.9996"
      rx="0.535714"
      ry="0.535714"
      transform="rotate(90 16.2858 11.9996)"
      stroke="var(--gray500)"
    />
    <ellipse
      cx="7.71432"
      cy="12.0001"
      rx="0.535714"
      ry="0.535714"
      transform="rotate(90 7.71432 12.0001)"
      stroke="var(--gray500)"
    />
    <circle
      cx="12.0001"
      cy="12.0001"
      r="0.535714"
      transform="rotate(90 12.0001 12.0001)"
      stroke="var(--gray500)"
    />
  </svg>
)
