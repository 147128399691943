import React from 'react'

import { Paragraph } from '@atoms'
import { Dialog } from '@organisms'

import { VariantDialogContratos } from './dialogVariants'

interface Props {
  setShowBottomSheet: React.Dispatch<React.SetStateAction<boolean>>
  variant: VariantDialogContratos
  customTextButton?: string
  customActionButton?: () => void
}
export const BottomSheetsContrato = ({
  setShowBottomSheet,
  variant,
  customTextButton,
  customActionButton,
}: Props) => (
  <Dialog
    onClose={() => setShowBottomSheet(false)}
    headerTitle={variant.title}
    buttonText={customTextButton || 'Entendi'}
    buttonAction={customActionButton}
  >
    <Paragraph margin="0 0 20px 0">{variant.text}</Paragraph>
  </Dialog>
)
