import React from 'react'

import { useSelector } from 'react-redux'

import { PagePdf, TemplatesPDF } from '@interco/cp-react-ui-lib'
import { ApplicationState } from '@store/types'

const Refinanciamento = () => {
  const variant = 'web'
  const { dadosProposta, numeroProposta } = useSelector(
    (state: ApplicationState) => state.refinanciamento.proposta,
  )

  return (
    <PagePdf variant={variant}>
      <TemplatesPDF.TermoRefin
        variant={variant}
        emitente={{
          nome: dadosProposta.nomeCliente,
          cpf: dadosProposta.cpf,
          matricula: dadosProposta.matricula,
          documentoIdentificacao: dadosProposta.documentoIdentificacao,
          endereco: `${dadosProposta.enderecoResidencial}, ${dadosProposta.numeroResidencial}`,
        }}
        dadosLiquidados={{
          contratos: dadosProposta.contratosRefin,
          saldoDevedorTotalRefin: `R$${dadosProposta.saldoDevedorTotalRefin}`,
        }}
        dadosNovoEmprestimo={{
          valorMutuo: `R$${dadosProposta.valorDoMutuo}`,
          dataEmissao: dadosProposta.dataEmissao,
          iof: `R$${dadosProposta.valorIOF}`,
          taxaJuros: `${dadosProposta.taxa}%a.a`,
          previsaoPrimeiroDesconto: dadosProposta.primeiroDesconto,
          previsaoUltimoDesconto: dadosProposta.ultimoDesconto,
          quantidadeParcelas: String(dadosProposta.quantidadeDeParcelas),
          valorParcelas: `R$${dadosProposta.valorParcela}`,
          valorTotalASerPago: `R$${dadosProposta.valorTotalASerPago}`,
          numeroPropostaContrato: String(numeroProposta),
          jurosContratados: `R${dadosProposta.jurosContratados}`,
          pracaPagamento: dadosProposta.estadoResidencial,
          cetMensal: `${dadosProposta.atributosCetMensal}%`,
          cetAnual: `${dadosProposta.atributosCetAnual}%`,
          jurosContratadosPercentual: `${dadosProposta.jurosContratadosPercentual}%`,
          iofPercentual: `${dadosProposta.percentualIOF}%`,
        }}
        dadosLiberacao={{
          banco: dadosProposta.banco,
          agencia: dadosProposta.agencia,
          contaCorrente: dadosProposta.conta,
          valor: dadosProposta.valorLiberado,
        }}
      />
    </PagePdf>
  )
}

export default Refinanciamento
