import React, { useEffect, useState } from 'react'

import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'

import { cepMask } from '@utils/masks'
import { Option } from '@interco/inter-ui/components/BottomSheet/SelectBottomSheet/types'
import IcPadlock from '@interco/icons/core/privacy-security/ic_padlock'
import {
  cidadesRequest,
  enderecoRequest,
  estadosRequest,
  setBairro,
  setCep,
  setCidadeSelecionada,
  setComplemento,
  setEstadoSelecionado,
  setLogradouro,
  setNumero,
  setTipoLogradouro,
} from '@store/refinanciamento/endereco/actions'
import { ApplicationState } from '@store/types'
import {
  buildCidadesNovo,
  buildEstadosNovo,
  transformCidadeToOption,
  transformEstadoToOption,
} from '@utils/transformers'
import { StepRefin } from '@utils/enums'
import { trackingRefin } from '@store/refinanciamento/simulacao/actions'
import { Button } from '@interco/inter-ui/components/Button'
import { Page } from '@templates'
import { ColorWrapper, TextParagraph } from '@atoms/TextParagraph'
import routes from '@routes/RefinV2/routes'
import { Colors } from '@utils/Colors'
import Select from '@molecules/SelectNovo'
import { Loading } from '@atoms'

import { Container, CustomInput } from './styles'

const Endereco = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [disabled, setDisabled] = useState(false)
  const [disableCep, setDisableCep] = useState(false)
  const [cepLocal, setCepLocal] = useState('')
  const [enderecoLoaded, setEnderecoLoaded] = useState(false)

  const {
    cep,
    tipoLogradouro,
    logradouro,
    bairro,
    numero,
    complemento,
    estadoSelecionado,
    cidadeSelecionada,
  } = useSelector((state: ApplicationState) => state.refinanciamento.endereco)
  const { estados = [], cidades = [] } = useSelector(
    (state: ApplicationState) => state.refinanciamento.endereco,
  )
  const loading = useSelector(
    (state: ApplicationState) => state.refinanciamento.endereco.enderecoLoading,
  )

  useEffect(() => {
    dispatch(estadosRequest())
  }, [dispatch])

  useEffect(() => {
    if (cepLocal.length === 10) {
      dispatch(
        enderecoRequest({
          cep: cepLocal.replace(/[. -]/g, ''),
          callback: () => {
            setEnderecoLoaded(true)
          },
        }),
      )
      dispatch(setCep(cepLocal))
    }
  }, [cepLocal, dispatch])

  useEffect(() => {
    if (estadoSelecionado) {
      dispatch(cidadesRequest(estadoSelecionado))
    }
  }, [dispatch, estadoSelecionado])

  useEffect(() => {
    const requiredFields = [
      { value: !disableCep && !cepLocal },
      {
        value: !disableCep && (!cepLocal || cepLocal.length < 10),
      },
      { value: !disableCep && !cep },
      { value: !disableCep && (cep || '').length < 8 },
      { value: !tipoLogradouro },
      { value: !logradouro },
      { value: !bairro },
      { value: !estadoSelecionado },
      { value: !cidadeSelecionada },
      { value: !numero },
    ]
    const hasEmptyFields = requiredFields.some((field) => field.value)
    setDisabled(hasEmptyFields)
  }, [
    bairro,
    cep,
    cepLocal,
    cidadeSelecionada,
    disableCep,
    estadoSelecionado,
    logradouro,
    numero,
    tipoLogradouro,
  ])

  return (
    <Page
      stickyContainerFooterBottom="0px"
      positionFooter="sticky"
      stickyFooter={
        <Button
          disabled={disabled}
          onClick={() => {
            dispatch(trackingRefin(StepRefin.ENDERECO))
            navigate(routes.DADOS_BANCARIOS)
          }}
        >
          Continuar
        </Button>
      }
    >
      <ColorWrapper color={Colors.GRAY500} fontWeight="600" margin="22px 0 16px 0">
        <TextParagraph variant="headline-h2">Cadastre o seu endereço</TextParagraph>
      </ColorWrapper>
      <CustomInput
        id="cep"
        type="text"
        label="Informe seu CEP"
        placeholder="00.000-000"
        maxLength={10}
        value={cepLocal}
        disabled={disableCep}
        iconRight={disableCep ? <IcPadlock height={24} width={24} color={Colors.GRAY300} /> : <></>}
        onChange={(e) => {
          setCepLocal(cepMask((e.target as HTMLInputElement).value))
        }}
      />
      {disableCep || loading || (!loading && cepLocal.length === 10) ? (
        <></>
      ) : (
        <ColorWrapper
          color={Colors.PRIMARY500}
          fontWeight="700"
          margin="15px 0 16px 0"
          style={{ textAlign: 'center' }}
          onClick={() => {
            setDisableCep(true)
          }}
        >
          <TextParagraph variant="body-3">Não sei o meu CEP</TextParagraph>
        </ColorWrapper>
      )}

      {loading ? (
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignContent: 'center',
            justifyContent: 'center',
          }}
        >
          <Loading width={40} height={40} />
        </div>
      ) : (
        <></>
      )}
      {(!loading && cepLocal.length === 10 && enderecoLoaded) || disableCep ? (
        <>
          <CustomInput
            id="tipo"
            type="text"
            value={tipoLogradouro}
            label="Tipo"
            placeholder="Selecione um tipo"
            onChange={(e) => {
              dispatch(setTipoLogradouro((e.target as HTMLInputElement).value))
            }}
          />
          <CustomInput
            id="logradouro"
            type="text"
            value={logradouro}
            label="Endereço"
            placeholder="Insira o endereço"
            onChange={(e) => {
              dispatch(setLogradouro((e.target as HTMLInputElement).value))
            }}
          />
          <Container>
            <CustomInput
              id="numero"
              type="number"
              value={numero}
              label="Número"
              placeholder="Insira o número"
              onlyNumber
              onChange={(e) => {
                dispatch(setNumero((e.target as HTMLInputElement).value))
              }}
              style={{ width: '45%' }}
            />
            <CustomInput
              id="complemento"
              type="text"
              value={complemento}
              label="Complemento"
              placeholder="Complemento"
              onChange={(e) => {
                dispatch(setComplemento((e.target as HTMLInputElement).value))
              }}
              style={{ width: '50%' }}
            />
          </Container>
          <CustomInput
            id="bairro"
            type="text"
            value={bairro}
            label="Bairro"
            placeholder="Insira o bairro"
            onChange={(e) => {
              dispatch(setBairro((e.target as HTMLInputElement).value))
            }}
          />
          <Select
            label="Estado"
            name="dropdown"
            margin="0 0 20px 0"
            value={transformEstadoToOption(estados, String(estadoSelecionado))}
            hideSearch={false}
            options={buildEstadosNovo(estados)}
            placeholder="Selecione o Estado"
            onChange={(option: Option) => {
              dispatch(setEstadoSelecionado(String(option.value)))
              dispatch(cidadesRequest(String(option.value)))
              if (cidadeSelecionada) {
                dispatch(setCidadeSelecionada(''))
              }
            }}
          />
          <Select
            label="Cidade"
            name="dropdown"
            margin="0 0 20px 0"
            value={transformCidadeToOption(cidades, String(cidadeSelecionada))}
            hideSearch={false}
            options={buildCidadesNovo(cidades)}
            placeholder="Selecione a Cidade"
            onChange={(option: Option) => {
              dispatch(setCidadeSelecionada(String(option.value)))
            }}
            disabled={!estadoSelecionado}
          />
        </>
      ) : (
        <></>
      )}
    </Page>
  )
}
export default Endereco
