import { BaseServices, HttpMethod } from '@services/base'
import { InfoInssType } from '@store/refinanciamento/consultaMargemInss/types'

import { BridgeService } from './bridge'

export default (data: ConsultaMargemInssRequest, accessToken?: string) => {
  const pathname = process.env.REACT_APP_CONSULTA_MARGEM_INSS
  const method = HttpMethod.POST
  const host = process.env.REACT_APP_API_HOST
  const headers: Record<string, string> = {}
  if (BridgeService.isBrowser() && accessToken) {
    headers.Authorization = `Bearer ${accessToken}`
  }

  const response = BaseServices.request<ConsultarMargemInssResponse>({
    headers,
    pathname,
    method,
    host,
    data,
  })

  return response
}

export interface ContratosRequest {
  numeroContrato: string
  valorParcela: number
}

export interface ConsultaMargemInssRequest {
  cpf: string
  beneficio: string
  contratos: ContratosRequest[]
}

export interface ConsultarMargemInssResponse {
  valorTotalParcela: number
  dadosBancarios: InfoInssType
}
