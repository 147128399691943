import { call, put, select } from 'typed-redux-saga'
import { AnyAction } from 'redux'

import services from '@services'
import { showError } from '@store/ui/error/actions'
import { ApplicationState } from '@store/types'
import { HttpStatus } from '@services/base'

import { RiscoEnderecoPayload } from '../types'
import { validarRiscoEnderecoRequest } from '../actions'

export type ErroType = {
  response: { status: HttpStatus; data: { message: string } }
  message: string
  status: HttpStatus
  data: {
    message: string
  }
}

export default function* postRiscoEnderecoSaga(action: AnyAction) {
  const payload = action.payload as RiscoEnderecoPayload

  try {
    const { convenioCodigo } = yield* select(
      (state: ApplicationState) => state.refinanciamento.contratos,
    )
    const { estadoSelecionado, cidadeSelecionada } = yield* select(
      (state: ApplicationState) => state.refinanciamento.endereco,
    )
    const { accessToken } = yield* select((state: ApplicationState) => state.session)

    yield* call(
      services.postRiscoEndereco,
      {
        idConvenio: Number(convenioCodigo),
        estado: estadoSelecionado,
        cidade: cidadeSelecionada,
      },
      accessToken,
    )

    yield payload.callback(false)
  } catch (error) {
    const erroTyped = error as ErroType
    if (erroTyped.status === HttpStatus.BAD_REQUEST) {
      yield payload.callback(true)
    } else {
      yield put(
        showError({
          title: 'Ocorreu um erro',
          message: 'Ocorreu um erro ao validar o risco do endereço',
          actionCallback: () => validarRiscoEnderecoRequest(payload),
          actionTitle: 'Tentar novamente',
          block: true,
        }),
      )
    }
  }
}
