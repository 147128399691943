import { select, call, put } from 'typed-redux-saga'
import { AnyAction } from 'redux'

import { ApplicationState } from '@store/types'
import postConsultarMargemInss, {
  ConsultaMargemInssRequest,
  ContratosRequest,
} from '@services/postConsultarMargemInss'

import {
  consultaMargemInssError,
  consultaMargemInssSuccess,
  setInfoInssDadosBancarios,
} from '../actions'
import { ConsultaMargemInssPayload } from '../types'

export default function* postConsultaMargemInssSaga(action: AnyAction) {
  const payload = action.payload as ConsultaMargemInssPayload
  const { refinanciamento } = yield* select((s: ApplicationState) => s)

  const contratosPayload: ContratosRequest[] = []

  refinanciamento.contratos.contratosSelecionados?.map((contratoAtual) =>
    contratosPayload.push({
      numeroContrato: contratoAtual.numeroContrato,
      valorParcela: contratoAtual.valorProximaParcela,
    }),
  )

  const bodyRequest: ConsultaMargemInssRequest = {
    cpf: refinanciamento.dadosPessoais?.cpf,
    beneficio: refinanciamento.contratos.matriculaSelecionada,
    contratos: contratosPayload,
  }

  try {
    const { data } = yield* call(postConsultarMargemInss, bodyRequest)
    if (data) {
      yield put(consultaMargemInssSuccess(data.valorTotalParcela))
      yield put(setInfoInssDadosBancarios(data.dadosBancarios))
    } else {
      yield put(consultaMargemInssError())
    }
    yield payload.callback()
  } catch (error) {
    yield put(consultaMargemInssError())
  }
}
