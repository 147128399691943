import styled from 'styled-components'

import { Grid } from '@interco/inter-ui/components/Grid'

export const GridForIcon = styled(Grid)<{ margin?: string }>`
  direction: column;
  justify-content: ${(props) => props.justifyContent || 'center'};
  align-items: ${(props) => props.alignItems || 'center'};
  margin: ${(props) => props.margin};
`
