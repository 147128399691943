import React from 'react'

import { useDispatch, useSelector } from 'react-redux'

import { ColorWrapper, TextParagraph } from '@atoms/TextParagraph'
import IcSignOut from '@interco/icons/core/action-navigation/ic_sign_out'
import IcPlayFill from '@interco/icons/core/media-communication/ic_play_fill'
import { Colors } from '@utils/Colors'
import { setAccessToken } from '@store/session/actions'
import { ApplicationState } from '@store/types'

import { FirstColumn, HeaderContainer } from './styles'

const ProfileHeader = () => {
  const dispatch = useDispatch()
  const { userName } = useSelector((state: ApplicationState) => state.session)

  const logout = () => {
    dispatch(setAccessToken(undefined))
    window.location.href = `${process.env.REACT_APP_EMPRESTIMO_NOVO}`
  }

  return (
    <div style={{ marginBottom: '24px' }}>
      <HeaderContainer>
        <FirstColumn>
          <ColorWrapper margin="0">
            <TextParagraph variant="caption-1">Olá!</TextParagraph>
          </ColorWrapper>

          <ColorWrapper color={Colors.GRAY500} fontWeight="700" margin="0 0 8px 0">
            <TextParagraph variant="body-3">{userName}</TextParagraph>
          </ColorWrapper>
        </FirstColumn>
        <div
          role="button"
          tabIndex={0}
          onClick={() => logout()}
          onKeyDown={() => logout()}
          aria-label="Logout"
        >
          <IcSignOut width={24} height={24} color={Colors.PRIMARY500} />
        </div>
      </HeaderContainer>
      <TextParagraph variant="caption-1">
        Área do cliente <IcPlayFill color={Colors.GRAY400} width={7} height={7} /> Refinanciamentos
      </TextParagraph>
    </div>
  )
}

export default ProfileHeader
