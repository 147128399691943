import React from 'react'

import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

import { Box, Paragraph, Separator } from '@atoms'
import { Page } from '@templates'
import routes from '@routes/Refin/routes'
import { ApplicationState } from '@store/types'
import { EmprestimoBeneficios, EmprestimoProtegidoBeneficios, TipoEmprestimo } from '@utils/enums'
import { Radio } from '@interco/inter-ui/components/Radio'
import { Tag } from '@interco/inter-ui/components/Tag'
import Seguros from '@interco/icons/core/home-objects/ic_umbrella'
import Services from '@interco/icons/core/human/ic_hand_config'
import {
  selectSimulacao,
  setParcelaSelecionada,
  setTotalParcelas,
} from '@store/refinanciamento/simulacao/actions'
import { getParcelaAtualSimulacoes } from '@utils/functions'
import { Colors } from '@utils/Colors'
import { Button } from '@interco/inter-ui/components/Button'

import ParcelasInfo from './ParcelasInfo'
import ListaBeneficios from './ListaBeneficios'
import { Wrap, WrapNoTag, WrapTitleBox } from './styles'

const Simulacao = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { contratosSelecionados } = useSelector(
    (state: ApplicationState) => state.refinanciamento.contratos,
  )
  const {
    simulacaoSelecionada = TipoEmprestimo.CREDITO_PROTEGIDO,
    simulations,
    simulationsWithInsurance,
  } = useSelector((state: ApplicationState) => state.refinanciamento.simulacao)

  const selecionarParcelasPorSimulacao = () => {
    if (simulacaoSelecionada === TipoEmprestimo.CREDITO_PROTEGIDO) {
      const getParcelaAtual = getParcelaAtualSimulacoes(simulationsWithInsurance)
      dispatch(setParcelaSelecionada(getParcelaAtual))
      dispatch(setTotalParcelas(getParcelaAtual.numberOfInstallments))
    } else {
      const getParcelaAtual = getParcelaAtualSimulacoes(simulations)
      dispatch(setParcelaSelecionada(getParcelaAtual))
      dispatch(setTotalParcelas(getParcelaAtual.numberOfInstallments))
    }
    navigate(routes.CONFIRMAR_CONTATO)
  }

  const titleContracts = (numContratos: number) =>
    numContratos === 1
      ? `${numContratos} Contrato Selecionado`
      : `${numContratos} Contratos Selecionados`

  return (
    <Page
      stickyFooter={
        <Button
          variant="primary"
          onClick={() => {
            selecionarParcelasPorSimulacao()
          }}
        >
          Continuar
        </Button>
      }
    >
      <Paragraph
        variant="sora"
        fontWeight={600}
        fontSize="16px"
        lineHeight="19px"
        color={Colors.GRAY500}
      >
        Escolha o tipo de Refinanciamento
      </Paragraph>
      <Paragraph fontSize="12px" lineHeight="15px" color={Colors.GRAY500}>
        {titleContracts(contratosSelecionados.length)}
      </Paragraph>
      <Separator variant="small" margin="24px 0" />

      <Box
        key="simulacao-seguro"
        onClick={() => dispatch(selectSimulacao(TipoEmprestimo.CREDITO_PROTEGIDO))}
        actived={simulacaoSelecionada === TipoEmprestimo.CREDITO_PROTEGIDO}
      >
        <div>
          <Wrap>
            <div>
              <Tag theme="green" size="small" intensity="dark">
                Recomendado
              </Tag>
            </div>

            <Radio
              id="input-radio-seguro"
              onChange={() => dispatch(selectSimulacao(TipoEmprestimo.CREDITO_PROTEGIDO))}
              checked={simulacaoSelecionada === TipoEmprestimo.CREDITO_PROTEGIDO}
              value=""
            />
          </Wrap>

          <WrapTitleBox>
            <Seguros height={24} width={24} color={Colors.GRAY500} />
            <Paragraph
              variant="sora"
              fontSize="16px"
              lineHeight="20px"
              color={Colors.GRAY500}
              fontWeight="600"
              textAlign="left"
              style={{ marginLeft: '8px' }}
            >
              Refinanciamento com seguro
            </Paragraph>
          </WrapTitleBox>
          <ParcelasInfo
            qtdeParcelas={getParcelaAtualSimulacoes(simulationsWithInsurance).numberOfInstallments}
            valorParcela={getParcelaAtualSimulacoes(simulationsWithInsurance).installmentAmount}
          />
          <Separator margin="12px 0 16px 0" color={Colors.GRAY200} height="1px" />
          <ListaBeneficios listaBeneficios={EmprestimoProtegidoBeneficios} />
        </div>
      </Box>

      <Box
        key="simulacao"
        onClick={() => dispatch(selectSimulacao(TipoEmprestimo.SEM_CREDITO_PROTEGIDO))}
        actived={simulacaoSelecionada === TipoEmprestimo.SEM_CREDITO_PROTEGIDO}
        margin="16px 0 0 0"
      >
        <div>
          <WrapNoTag>
            <Radio
              id="input-radio"
              onChange={() => dispatch(selectSimulacao(TipoEmprestimo.SEM_CREDITO_PROTEGIDO))}
              checked={simulacaoSelecionada === TipoEmprestimo.SEM_CREDITO_PROTEGIDO}
              value=""
            />
          </WrapNoTag>

          <WrapTitleBox>
            <Services height={24} width={24} color={Colors.GRAY500} />
            <Paragraph
              variant="sora"
              fontSize="16px"
              lineHeight="20px"
              color={Colors.GRAY500}
              fontWeight="600"
              textAlign="left"
              style={{ marginLeft: '8px' }}
            >
              Refinanciamento sem seguro
            </Paragraph>
          </WrapTitleBox>
          <ParcelasInfo
            qtdeParcelas={getParcelaAtualSimulacoes(simulations).numberOfInstallments}
            valorParcela={getParcelaAtualSimulacoes(simulations).installmentAmount}
          />
          <Separator margin="12px 0 16px 0" color={Colors.GRAY200} height="1px" />
          <ListaBeneficios listaBeneficios={EmprestimoBeneficios} />
        </div>
      </Box>
    </Page>
  )
}
export default Simulacao
