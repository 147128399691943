import styled, { keyframes } from 'styled-components'

import { Checkbox } from '@interco/inter-ui/components/Checkbox'
import { Colors } from '@utils/Colors'
import { BoxComponent } from '@atoms/Box/styles'

export const LineInfo = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: 0 0 16px 0;
`
export const TitleLine = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 24px;
`
const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`
export const FadeIn = styled.div`
  animation: ${fadeIn} 0.5s ease-in-out;
`
export const CardConta = styled.div`
  display: flex;
  padding: 16px;
  flex-direction: column;
  border-radius: 8px;
  border: 1px solid var(--light-neutral-grayscale-200, #dedfe4);
  background: var(--light-neutral-theme, #fff);
`
export const CardLine = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 8px;
`
export const Bold = styled.b`
  font-weight: 700;
  color: ${Colors.GRAY500};
`
export const CheckboxCustom = styled(Checkbox)`
  & label {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }
`
export const BoxCustom = styled(BoxComponent)`
  margin-top: 40px;
  min-height: 60px;
  padding: 20px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`
