/**
 * Actions Types
 */

export enum ConsultaMargemInssTypes {
  CONSULTA_MARGEM_INSS_SUCCESS = '@refinanciamento/consultaMargemInss/CONSULTA_MARGEM_INSS_SUCCESS',
  CONSULTA_MARGEM_INSS_ERROR = '@refinanciamento/consultaMargemInss/CONSULTA_MARGEM_INSS_ERROR',
  CONSULTA_MARGEM_INSS_REQUEST = '@refinanciamento/consultaMargemInss/CONSULTA_MARGEM_INSS_REQUEST',

  SET_INFO_INSS_DADOS_BANCARIOS = '@refinanciamento/consultaMargemInss/SET_INFO_INSS_DADOS_BANCARIOS',

  DADOS_INSS_START_ASYNC = '@refinanciamento/consultaMargemInss/DADOS_INSS_START_ASYNC',

  SET_LOADING_INSS_APIS = '@refinanciamento/consultaMargemInss/SET_LOADING_INSS_APIS',
  SET_COUNTER_RECALL = '@refinanciamento/consultaMargemInss/SET_COUNTER_RECALL',
}

export interface InfoInssType {
  banco: string
  agencia: string
  conta: string
  digitoConta: string
  tipoCredito: string
  margem: number
  nome: string
}

/**
 * State Type
 */

export interface ConsultaMargemInssState {
  readonly loading: boolean
  readonly error: boolean
  readonly consultaMargemRealizada: boolean
  readonly valorTotalParcela: number
  readonly dadosBancarios: InfoInssType
  readonly loadingInssApis?: boolean
  readonly counterRecall: number
}

export interface ConsultaMargemInssPayload {
  callback: () => void
}

export type StartPayload = {
  callback: () => void
  callbackError?: () => void
}
