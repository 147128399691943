import styled from 'styled-components'

export const PageVisualizarPdf = styled.div`
  margin: 12px;
  height: auto;
`

export const PdfGrid = styled.div<{
  isCartilha?: boolean
  isAdf?: boolean
  isWeb?: boolean
}>`
  overflow: scroll;
  height: auto;
  margin: 0;

  ${({ isWeb }) =>
    isWeb &&
    `
        height: calc(100vh - 258px);
        @media (max-width: 991px) {
          height: calc(100vh - 330px);
        }
        @media (max-width: 767px) {
          height: calc(100vh - 294px);
        }
        @media (max-width: 523px) {
          height: calc(100vh - 307px);
        }
    `}
`
