import { call, put, select } from 'typed-redux-saga'
import { AnyAction } from 'redux'

import services from '@services'
import { showError } from '@store/ui/error/actions'
import { ErrorCodes } from '@utils/enums'
import {
  contratosSuccess,
  contratosError,
  contratosResquest,
  contratosNaoElegiveis,
} from '@store/refinanciamento/contratos/actions'
import { setNome, setDataNascimento, setCpf } from '@store/refinanciamento/dadosPessoais/actions'
import { removeMask } from '@utils/masks'
import { ApplicationState } from '@store/types'
import { HttpStatus } from '@services/base'
import { logout } from '@utils/functions'
import { BridgeService } from '@services/bridge'
import { ErroType } from '@utils/types'

import { ContractsData, ContratosAPI, ContratosRequestPayload } from '../types'

export default function* getContratos(action: AnyAction) {
  const payload = action.payload as ContratosRequestPayload
  try {
    const { accessToken } = yield* select((state: ApplicationState) => state.session)
    const cpfSession = yield* select((state: ApplicationState) => state.session.cpf)
    const cpfDados = yield* select(
      (state: ApplicationState) => state.refinanciamento.dadosPessoais.cpf,
    )
    const cpf = payload.cpf || cpfDados || cpfSession || ''

    const { data } = yield* call(
      services.getContratos,
      removeMask(cpf),
      payload.newApiVersion,
      accessToken,
    )

    let novoObj

    if (payload.newApiVersion) {
      novoObj = {
        naoElegivel: false,
        dataNascimento: '',
        nome: '',
        contratos: (data as ContractsData).contracts.map(
          ({ categoryDescription: category, registrations }) => ({
            descricaoConvenio: category,
            listMatricula: registrations?.map(({ registration, contractItems }) => ({
              numeroMatricula: registration,
              listContrato: contractItems.map(
                ({
                  contract,
                  proposal,
                  outstandingBalance,
                  installmentAmount,
                  productCode,
                  categoryId,
                  categoryDescription,
                  agencyId,
                  agencyDescription,
                }) => ({
                  numeroContrato: contract,
                  numeroProposta: proposal,
                  codProduto: productCode,
                  valorQuitacao: outstandingBalance,
                  orgaoId: agencyId,
                  orgaoDesc: agencyDescription,
                  convenioId: categoryId,
                  convenioDesc: categoryDescription,
                  valorProximaParcela: installmentAmount,
                  // numParcelasPagas: number
                  //  numParcelasTotais: number
                  //  vencimentoProximaParcela: string
                  // valorEmprestimo: number,
                }),
              ),
            })),
          }),
        ),
      } as ContratosAPI
    } else {
      novoObj = data as ContratosAPI
    }

    yield put(contratosNaoElegiveis(novoObj?.naoElegivel))
    yield put(contratosSuccess(novoObj?.contratos))

    if (novoObj?.naoElegivel || !novoObj?.contratos || novoObj?.contratos.length === 0) {
      payload.semContratosCallback()
    } else {
      payload.successCallback()
    }
    yield put(setNome(novoObj.nome))
    yield put(setDataNascimento(novoObj.dataNascimento))
    yield put(setCpf(cpf))
  } catch (error) {
    const erroTyped = error as ErroType
    if (BridgeService.isBrowser() && erroTyped.status === HttpStatus.UNAUTHORIZED) {
      yield put(
        showError({
          title: 'Sessão expirada',
          message: 'Efetue o login novamente.',
          actionTitle: 'Continuar',
          functionCallback: () => logout(),
        }),
      )
    } else if (erroTyped.status === HttpStatus.PRECONDITION_FAILED) {
      payload.semContratosCallback()
    } else {
      yield put(contratosError())
      yield put(
        showError({
          title: 'Erro ao buscar os contratos',
          message: 'Ocorreu um erro ao buscar a listagem de contratos.',
          actionTitle: 'Tentar Novamente',
          code: ErrorCodes.RE_001_CONTRATOS,
          actionCallback: () => contratosResquest(payload),
        }),
      )
    }
  }
}
