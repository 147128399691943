import { call, put, select } from 'typed-redux-saga'
import { AnyAction } from 'redux'

import services from '@services'
import { showError } from '@store/ui/error/actions'
import { ApplicationState } from '@store/types'

import {
  cidadesSuccess,
  enderecoError,
  enderecoLoading,
  enderecoRequest,
  enderecoSuccess,
  setBairro,
  setCep,
  setCidadeSelecionada,
  setEstadoSelecionado,
  setLogradouro,
  setTipoLogradouro,
} from '../actions'
import { Cidade, CidadesAPI, EnderecoAPI, EnderecoPayload } from '../types'

const enderecoBuild = (data: EnderecoAPI) =>
  data && {
    cep: data.cep,
    tipoLogradouro: data.tipoLogradouro,
    logradouro: data.logradouro,
    bairro: data.bairro,
    uf: data.uf,
    cidade: data.cidade,
  }

const cidadesBuild = (data: CidadesAPI): Cidade[] =>
  data && data?.cidades.map((item) => ({ codigo: item.codigo, nome: item.nome }))

export default function* getEndereco(action: AnyAction) {
  const payload = action.payload as EnderecoPayload
  const { accessToken } = yield* select((state: ApplicationState) => state.session)
  try {
    yield put(enderecoLoading())
    const response = yield* call(services.getEndereco, payload.cep, accessToken)
    const data = enderecoBuild(response.data)
    if (data) {
      yield put(setCep(data.cep))
      yield put(setTipoLogradouro(data.tipoLogradouro))
      yield put(setLogradouro(data.logradouro))
      yield put(setBairro(data.bairro))
      yield put(setEstadoSelecionado(data.uf))
      yield put(setCidadeSelecionada(data.cidade.toUpperCase()))
    }
    const responseCidade = yield* call(services.getCidades, data.uf)
    yield put(cidadesSuccess(cidadesBuild(responseCidade.data)))
    yield put(enderecoSuccess([data]))
    if (responseCidade && payload.callback) {
      yield payload.callback()
    }
  } catch (error) {
    yield put(enderecoError())
    yield put(
      showError({
        title: 'Erro ao buscar o endereço',
        message: 'Ocorreu um erro na busca do endereço.',
        actionTitle: 'Tentar Novamente',
        actionCallback: () => enderecoRequest(payload),
      }),
    )
  }
}
