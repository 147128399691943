import React, { useCallback, useEffect, useState } from 'react'

import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'

import { Page } from '@templates'
import { StepRefin } from '@utils/enums'
import { useTrackingRefin } from '@hooks'
import { validaEnvHomeByBridge } from '@utils/functions'
import GridStyled from '@atoms/GridStyled'
import { Loading, Paragraph } from '@atoms'
import { Button } from '@interco/inter-ui/components/Button'
import { SuccessIcon } from '@icons/Signals'
import { Colors } from '@utils/Colors'
import { ApplicationState } from '@store/types'
import { LinkTermoAutorizacao } from '@pages/Refin/Contratos/styles'
import { TipoDocs } from '@store/refinanciamento/listaDocumentos/types'
import { ErrorPage } from '@organisms'
import { finalizarProposta } from '@store/refinanciamento/documentosAssinatura/actions'

import { WrapLoadingText } from './styles'

const DadosRecebidos = () => {
  const dispatch = useDispatch()
  const [loading, setLoading] = useState(true)
  const [errorPage, setErrorPage] = useState(false)
  useTrackingRefin(StepRefin.DADOS_RECEBIDO)
  const { isContratacaoCompleta, origemGoBack, isApp } = useSelector(
    (state: ApplicationState) => state.ui.navigation,
  )
  const assinatura = useSelector(
    (state: ApplicationState) => state.refinanciamento.documentosAssinatura,
  )
  const { listaDocumentos } = useSelector((state: ApplicationState) => state.refinanciamento)
  const { erroFinalizarProposta, propostaFinalizada } = useSelector(
    (state: ApplicationState) => state.refinanciamento.documentosAssinatura,
  )

  const navigate = useNavigate()

  const hasPendenciasFinalizarCaptura = useCallback(
    () =>
      assinatura?.documentos.some(
        (item: { idTipoDocumento: TipoDocs }) =>
          !listaDocumentos[item.idTipoDocumento as TipoDocs].isDone,
      ),
    [assinatura?.documentos, listaDocumentos],
  )

  useEffect(() => {
    if (isContratacaoCompleta && !propostaFinalizada) {
      if (!hasPendenciasFinalizarCaptura()) {
        dispatch(
          finalizarProposta({
            callback: () => {
              setLoading(false)
              setErrorPage(false)
            },
          }),
        )
      } else {
        setLoading(false)
        setErrorPage(true)
      }
    } else {
      setLoading(false)
    }
  }, [dispatch, hasPendenciasFinalizarCaptura, isContratacaoCompleta, propostaFinalizada])

  if (errorPage || erroFinalizarProposta) {
    return (
      <ErrorPage
        erroTitle="Ocorreu um erro"
        erroMessage="Não foi possível finalizar a proposta. Verifique se todos os dados foram enviados."
        onClick={() => navigate(-1)}
        footerText="Voltar"
      />
    )
  }

  if (loading) {
    return (
      <Page>
        <WrapLoadingText>
          <Loading width={50} height={50} />
          <Paragraph margin="14px 0" fontSize="14px" fontWeight={700} color={Colors.GRAY500}>
            Finalizando a proposta...
          </Paragraph>
        </WrapLoadingText>
      </Page>
    )
  }

  const contentConcluded = () => (
    <>
      Aguarde até 3 dias para o valor ser liberado na sua conta.
      <br />
      <br />
      Enviamos para o seu e-mail os dados do refinanciamento. Você também pode acompanhar o{' '}
      <LinkTermoAutorizacao
        onClick={() => {
          window.location.href = validaEnvHomeByBridge(String(origemGoBack), isApp) || ''
        }}
      >
        status da sua proposta
      </LinkTermoAutorizacao>
      .
    </>
  )
  return (
    <Page
      stickyFooter={
        <>
          <Button
            variant="primary"
            onClick={() => {
              window.location.href = validaEnvHomeByBridge(String(origemGoBack), isApp) || ''
            }}
          >
            Voltar ao início
          </Button>
        </>
      }
    >
      <GridStyled justify="space-around">
        <SuccessIcon />
      </GridStyled>
      <Paragraph
        variant="sora"
        fontWeight={600}
        fontSize="24px"
        lineHeight="30px"
        color={Colors.GRAY500}
        margin="40px 0 0"
        textAlign="left"
      >
        {isContratacaoCompleta
          ? 'Refinanciamento concluído'
          : 'Recebemos sua solicitação de refinanciamento'}
      </Paragraph>
      <Paragraph
        fontWeight="normal"
        fontSize="14px"
        lineHeight="17px"
        margin="8px 10px 0 0"
        textAlign="left"
      >
        {isContratacaoCompleta
          ? contentConcluded()
          : 'Em breve, entraremos em contato para confirmar a negociação.'}
      </Paragraph>
    </Page>
  )
}

export default DadosRecebidos
