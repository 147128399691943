import React, { useEffect, useState } from 'react'

import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'

import { ApplicationState } from '@store/types'
import { Option } from '@interco/inter-ui/components/BottomSheet/SelectBottomSheet/types'
import { Alert } from '@interco/inter-ui/components/Alert'
import {
  bancosRequest,
  setAgencia,
  setBancoSelecionado,
  setConta,
  setDigito,
  setTipoConta,
} from '@store/refinanciamento/dadosBancarios/actions'
import { ConveniosCodes } from '@utils/enums'
import { agenciaMask } from '@utils/masks'
import { Button } from '@interco/inter-ui/components/Button'
import Tag from '@utils/GoogleTag'
import { StepRefin } from '@utils/enums'
import { trackingRefin } from '@store/refinanciamento/simulacao/actions'
import { Page } from '@templates'
import { ColorWrapper, TextParagraph } from '@atoms/TextParagraph'
import { Colors } from '@utils/Colors'
import routes from '@routes/RefinV2/routes'
import Select from '@molecules/SelectNovo'
import {
  buildBancosNovo,
  TiposContaNovo,
  transformBancoToOption,
  transformTipoContaToOption,
} from '@utils/transformers'
import { salvarPropostaRequest } from '@store/refinanciamento/proposta/actions'

import { Container, CustomInput } from './styles'

const DadosBancarios = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const [disabled, setDisabled] = useState(false)
  const [closeAlert, setCloseAlert] = useState(false)
  const {
    bancos = [],
    bancoSelecionado,
    tipoContaSelecionado,
    agencia,
    conta,
    digito,
    dadosBancariosLoading,
    loading,
  } = useSelector((state: ApplicationState) => state.refinanciamento.dadosBancarios)
  const { documentoId } = useSelector((state: ApplicationState) => state.refinanciamento.proposta)
  const { convenioCodigo } = useSelector(
    (state: ApplicationState) => state.refinanciamento.contratos,
  )

  useEffect(() => {
    if (!bancos || bancos.length === 0) {
      dispatch(bancosRequest())
    }
  }, [bancos, dispatch])

  useEffect(() => {
    setDisabled(!bancoSelecionado || !tipoContaSelecionado || !agencia || !conta || !digito)
  }, [agencia, bancoSelecionado, conta, digito, tipoContaSelecionado])

  return (
    <Page
      stickyContainerFooterBottom="30px"
      positionFooter="sticky"
      stickyFooter={
        <Button
          onClick={() => {
            Tag.simuladorConsignadoTag({
              content_name: 'Continuar',
              tela: 'Dados Bancários',
              content_action: 'toque',
            })
            dispatch(trackingRefin(StepRefin.DADOS_BANCARIOS))
            if (!documentoId) {
              dispatch(
                salvarPropostaRequest({
                  callback: () => {
                    dispatch(trackingRefin(StepRefin.INICIO_CAPTURA))
                    navigate(routes.CARREGAMENTO_DADOS)
                  },
                }),
              )
            }
          }}
          disabled={disabled || dadosBancariosLoading || loading}
          isLoading={loading || dadosBancariosLoading}
        >
          Continuar
        </Button>
      }
    >
      <ColorWrapper color={Colors.GRAY500} fontWeight="600" margin="22px 0 16px 0">
        <TextParagraph variant="headline-h2">
          Cadastre uma conta bancária para depósito
        </TextParagraph>
      </ColorWrapper>
      {Number(convenioCodigo) === ConveniosCodes.INSS && !closeAlert ? (
        <Alert
          title=""
          type="warning"
          showCloseButton
          onClose={() => {
            setCloseAlert(true)
          }}
        >
          Utilize a mesma conta de recebimento do seu salário do INSS.
        </Alert>
      ) : (
        <></>
      )}

      <Select
        label="Informe o banco"
        name="dropdown"
        margin="24px 0 20px 0"
        value={transformBancoToOption(bancos, String(bancoSelecionado))}
        hideSearch={false}
        options={buildBancosNovo(bancos)}
        placeholder="Selecione o banco"
        onChange={(option: Option) => {
          dispatch(setBancoSelecionado(option.value))
        }}
      />
      <Select
        label="Tipo de conta"
        name="dropdown"
        margin="0 0 20px 0"
        value={transformTipoContaToOption(TiposContaNovo, String(tipoContaSelecionado))}
        hideSearch={false}
        options={TiposContaNovo}
        placeholder="Selecione o tipo"
        onChange={(option: Option) => {
          dispatch(setTipoConta(option.value))
        }}
      />
      <CustomInput
        id="agencia"
        type="text"
        value={agencia}
        label="Agência"
        placeholder="Insira a agência"
        onlyNumber
        onChange={(e) => {
          dispatch(setAgencia(agenciaMask((e.target as HTMLInputElement).value)))
        }}
      />
      <Container>
        <CustomInput
          id="conta"
          type="text"
          value={conta}
          label="Conta"
          placeholder="Insira a conta"
          onChange={(e) => {
            dispatch(setConta((e.target as HTMLInputElement).value))
          }}
          style={{ width: '45%' }}
          maxLength={11}
          onlyNumber
        />
        <CustomInput
          id="digito"
          type="text"
          value={digito}
          label="Dígito"
          placeholder="Dígito"
          onlyNumber
          maxLength={1}
          onChange={(e) => {
            dispatch(setDigito((e.target as HTMLInputElement).value))
          }}
          style={{ width: '50%' }}
        />
      </Container>
      <Alert title="" type="grayscale">
        Caso o dígito seja X, digite 0.
      </Alert>
    </Page>
  )
}

export default DadosBancarios
