import { BaseServices, HttpMethod } from './base'
import { BridgeService } from './bridge'

export interface RiscoEnderecoType {
  idConvenio: number
  estado: string
  cidade: string
}

export default async (payload: RiscoEnderecoType, accessToken?: string) => {
  const pathname = process.env.REACT_APP_RISCO_ENDERECO_BFF
  const method = HttpMethod.POST
  const host = process.env.REACT_APP_API_HOST
  const headers: Record<string, string> = {}
  if (BridgeService.isBrowser() && accessToken) {
    headers.Authorization = `Bearer ${accessToken}`
  }

  const data = {
    idConvenio: payload.idConvenio,
    endereco: {
      estado: payload.estado,
      cidade: payload.cidade,
    },
  }

  const response = await BaseServices.request({
    headers,
    pathname,
    method,
    host,
    data,
  })
  return response
}
