import React, { useState } from 'react'

import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'

import { useTrackingRefin } from '@hooks'
import { formatCurrency } from '@utils/masks'
import { ApplicationState } from '@store/types'
import routes from '@routes/Refin/routes'
import { setParcelaSelecionada, trackingRefin } from '@store/refinanciamento/simulacao/actions'
import { StepRefin } from '@utils/enums'
import { Page } from '@templates'
import { Paragraph, Separator } from '@atoms'
import { Button } from '@interco/inter-ui/components/Button'
import { Colors } from '@utils/Colors'
import { roundToTwo } from '@pages/Refin/ResultadoSimulacao'

import { FullParagraph, Grid, RadioCustom } from './styles'

const Parcelamento = () => {
  const parcelasDisponiveis = useSelector(
    (state: ApplicationState) => state.refinanciamento.simulacao,
  )
  const { simulacaoSelecionada } = useSelector(
    (state: ApplicationState) => state.refinanciamento.simulacao,
  )
  const atual = parcelasDisponiveis.parcelaAtual
  const [labelButton, setLabelButton] = useState(
    `${atual.numberOfInstallments} x ${formatCurrency(atual.installmentAmount)}`,
  )
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [itemSelecionado, setItemSelecionado] = useState(atual)

  useTrackingRefin(StepRefin.PARCELAMENTO)

  return (
    <Page
      stickyFooter={
        <Button
          variant="primary"
          onClick={() => {
            dispatch(trackingRefin(StepRefin.SELECIONADO_PARCELAMENTO))
            dispatch(setParcelaSelecionada(itemSelecionado))
            navigate(routes.RESULTADO)
          }}
        >
          {`Escolher - ${labelButton}`}
        </Button>
      }
    >
      <Paragraph
        variant="sora"
        fontWeight="600"
        fontSize="16px"
        lineHeight="20px"
        margin="24px 0 8px"
        color={Colors.GRAY500}
      >
        Selecione o parcelamento
      </Paragraph>
      <Paragraph
        variant="inter"
        fontWeight="400"
        fontSize="14px"
        lineHeight="15px"
        color={Colors.GRAY500}
        margin="0 0 24px"
      >
        {`Você pode parcelar o valor de ${formatCurrency(
          parcelasDisponiveis.parcelaAtual.requestedAmount,
        )} em até ${parcelasDisponiveis.totalParcelas} vezes, com juros de ${roundToTwo(
          parcelasDisponiveis.parcelaAtual.monthlyNominalRate,
        ).toFixed(2)}% ao mês.`}
      </Paragraph>

      <Grid id="quantas-parcelas">
        {parcelasDisponiveis[simulacaoSelecionada]?.map((opcaoParcela, index) => (
          <>
            <RadioCustom
              id={`input-radio-${index}`}
              name="input-radio"
              reversed
              checked={
                labelButton ===
                `${opcaoParcela.numberOfInstallments} x ${formatCurrency(
                  opcaoParcela.installmentAmount,
                )}`
              }
              defaultChecked={atual.numberOfInstallments === opcaoParcela.numberOfInstallments}
              onChange={(e) => {
                setLabelButton((e.target as HTMLInputElement).value)
                setItemSelecionado(opcaoParcela)
              }}
              value={`${opcaoParcela.numberOfInstallments} x ${formatCurrency(
                opcaoParcela.installmentAmount,
              )}`}
              key={opcaoParcela.numberOfInstallments}
              label={
                <FullParagraph
                  variant="inter"
                  fontWeight="700"
                  fontSize="14px"
                  lineHeight="17px"
                  margin="0"
                  color={Colors.GRAY500}
                >
                  {`${opcaoParcela.numberOfInstallments} x ${formatCurrency(
                    opcaoParcela.installmentAmount,
                  )}`}
                </FullParagraph>
              }
            />
            <Separator height="1px" margin="20px 0" />
          </>
        ))}
      </Grid>
    </Page>
  )
}

export default Parcelamento
