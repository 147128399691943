import { takeLatest, SagaGenerator } from 'typed-redux-saga'

import { BiometriaTypes } from './types'
import {
  getBiometricsValidateSaga,
  postBiometricsSaveDataSaga,
  postFotoBiometriaLivenessSaga,
} from './sagas'

export default function* watch(): SagaGenerator<void> {
  yield* takeLatest(BiometriaTypes.POST_FOTO_BIOMETRIA_LIVENESS, postFotoBiometriaLivenessSaga)
  yield* takeLatest(BiometriaTypes.POST_BIOMETRICS_SAVE, postBiometricsSaveDataSaga)
  yield* takeLatest(BiometriaTypes.GET_VALIDATE_BIOMETRICS, getBiometricsValidateSaga)
}
