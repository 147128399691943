import React, { useState } from 'react'

import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'

import { Page } from '@templates'
import { formatCurrency } from '@utils/masks'
import routes from '@routes/Refin/routes'
import { useTrackingRefin } from '@hooks'
import { StepRefin, TipoEmprestimo } from '@utils/enums'
import { trackingRefin } from '@store/refinanciamento/simulacao/actions'
import { Paragraph } from '@atoms'
import { Button } from '@interco/inter-ui/components/Button'
import { ListaDescricao, ListaDetalhes } from '@molecules'
import { Separator } from '@atoms'
import { Alert } from '@interco/inter-ui/components/Alert'
import Info from '@interco/icons/core/status/ic_warning_circle'
import { ApplicationState } from '@store/types'
import ResumoDePedido from '@interco/icons/core/files/ic_document'
import ChevronRight from '@interco/icons/core/action-navigation/ic_chevron_right'
import Edit from '@interco/icons/core/action-navigation/ic_edit'
import Help from '@interco/icons/core/status/ic_help_circle'
import Seguros from '@interco/icons/core/home-objects/ic_umbrella'
import { Colors } from '@utils/Colors'
import { setIsContratacaoCompleta } from '@store/ui/navigation/actions'

import { AreaSeguro, CircleIcon, TextSeguro, WrapButton, WrapIcon } from './styles'
import { BottomSheetContratos } from './Bottomsheets/BottomSheetContratos'
import { BottomSheetCet } from './Bottomsheets/BottomSheetCet'
import { BottomSheetSeguro } from './Bottomsheets/BottomSheetSeguro'

interface OwnProps {
  children: string
}

export function roundToTwo(numero: number): number {
  return Math.round((numero + Number.EPSILON) * 100) / 100
}

const ResultadoSimulacao = () => {
  const [bottomSheetContratos, setShowBottomSheetContratos] = useState(false)
  const [bottomSheetCet, setShowBottomSheetCet] = useState(false)
  const [bottomSheetSeguro, setShowBottomSheetSeguro] = useState(false)
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { parcelaAtual, debtBalance, simulacaoSelecionada } = useSelector(
    (state: ApplicationState) => state.refinanciamento.simulacao,
  )
  const { convenioSelecionado, matriculaSelecionada, contratosSelecionados } = useSelector(
    (state: ApplicationState) => state.refinanciamento.contratos,
  )

  useTrackingRefin(StepRefin.RESULTADO)

  const Titulo = ({ children }: OwnProps) => (
    <Paragraph
      variant="sora"
      fontSize="16px"
      lineHeight="20px"
      fontWeight={600}
      color={Colors.GRAY400}
      margin="24px 0 8px"
      textAlign="center"
    >
      {children}
    </Paragraph>
  )

  const Texto = ({ children }: OwnProps) => (
    <Paragraph
      variant="inter"
      fontWeight="normal"
      fontSize="12px"
      lineHeight="15px"
      color={Colors.GRAY400}
      margin="4px 0"
      textAlign="center"
    >
      {children}
    </Paragraph>
  )

  return (
    <Page
      stickyFooter={
        <Button
          variant="primary"
          onClick={() => {
            dispatch(trackingRefin(StepRefin.SOLICITAR_CONTRATACAO))
            // if (convenioSelecionado === 'INSS' && !isIOS) {
            //   dispatch(setIsContratacaoCompleta(true))
            //   navigate(routes.ONBOARDING_CONTRATO)
            // } else {
            //   dispatch(setIsContratacaoCompleta(false))
            //   navigate(routes.DADOS_RECEBIDOS)
            // }
            dispatch(setIsContratacaoCompleta(false))
            navigate(routes.DADOS_RECEBIDOS)
          }}
        >
          Solicitar contratação
        </Button>
      }
    >
      <Paragraph
        fontFamily="sora"
        fontSize="24px"
        lineHeight="30px"
        fontWeight={600}
        color={Colors.GRAY500}
        textAlign="left"
        margin="40px 0 8px"
      >
        Resultado da sua simulação
      </Paragraph>
      <Titulo>Troco liberado pra você</Titulo>
      <Paragraph
        variant="sora"
        fontWeight={600}
        fontSize="24px"
        lineHeight="30px"
        color={Colors.SUCCESS500}
        margin="0"
        textAlign="center"
      >
        {formatCurrency(parcelaAtual.availableAmount)}
      </Paragraph>
      <Texto>Ao refinanciar, esse valor cai direto na sua conta</Texto>
      {simulacaoSelecionada === TipoEmprestimo.CREDITO_PROTEGIDO ? (
        <AreaSeguro>
          <Seguros width={32} height={32} color={Colors.GRAY400} />
          <TextSeguro>
            <Paragraph fontSize="12px" lineHeight="15px" color={Colors.GRAY500}>
              Refinanciamento com Seguro
            </Paragraph>
            <Paragraph
              fontSize="12px"
              lineHeight="15px"
              fontWeight={700}
              color={Colors.PRIMARY500}
              onClick={() => setShowBottomSheetSeguro(true)}
            >
              Confira seus benefícios
            </Paragraph>
          </TextSeguro>
        </AreaSeguro>
      ) : (
        <></>
      )}
      <Separator variant="small" margin="24px 0" />
      <ListaDetalhes
        title="Suas novas parcelas"
        list={[
          {
            title: 'Suas novas parcelas',
            values: [
              `${parcelaAtual.numberOfInstallments} x ${formatCurrency(
                parcelaAtual.installmentAmount,
              )}`,
            ],
          },
          {
            title: 'Valor total financiado',
            values: [
              formatCurrency(parcelaAtual.financedAmount + parcelaAtual.contractualInterest),
            ],
          },
        ]}
      />

      <WrapButton variant="secondary" onClick={() => navigate(routes.PARCELAMENTO)} size="small">
        Mudar número de parcelas
        <WrapIcon>
          <Edit height={16} width={16} color={Colors.PRIMARY500} />
        </WrapIcon>
      </WrapButton>

      <Separator variant="small" margin="24px 0" />
      <ListaDetalhes
        title="Detalhamento dos valores"
        list={[
          {
            title: 'Saldo devedor pré-refinanciamento',
            values: [formatCurrency(debtBalance)],
          },
          {
            title: 'Troco liberado',
            values: [formatCurrency(parcelaAtual.availableAmount)],
          },
          {
            title: 'Novo valor disponível',
            values: [
              formatCurrency(parcelaAtual.financedAmount + parcelaAtual.contractualInterest),
            ],
          },
        ]}
      />

      <Separator variant="small" margin="24px 0" />
      <ListaDetalhes
        title="Taxas"
        list={[
          {
            title: 'Taxa de juros a partir de',
            values: [`(${roundToTwo(parcelaAtual.monthlyNominalRate).toFixed(2)}% a.m.)`],
          },
          {
            title: 'IOF',
            values: [
              `(${roundToTwo(parcelaAtual.percentageIOF).toFixed(2)}%) ${formatCurrency(
                parcelaAtual.amountIOF,
              )}`,
            ],
          },
          {
            title: 'Custo efetivo total (CET)',
            values: [`(${roundToTwo(parcelaAtual.cETAa).toFixed(2)}% a.a.)`],
          },
          {
            title: 'Valor do CET',
            iconBesideItemTitle: (
              <Help
                color={Colors.PRIMARY500}
                width={16}
                height={16}
                onClick={() => setShowBottomSheetCet(true)}
              />
            ),
            values: [`${formatCurrency(parcelaAtual.contractualInterest)}`],
          },
        ]}
      />

      <Separator height="2px" margin="24px 0" />

      <ListaDetalhes
        title=""
        list={[
          {
            title: 'Novo valor total refinanciado',
            values: [
              formatCurrency(parcelaAtual.financedAmount + parcelaAtual.contractualInterest),
            ],
            color: Colors.GRAY500,
          },
        ]}
      />

      <ListaDescricao
        id="contratos-refinanciamento"
        margin="30px 0px 32px 0px"
        alignAllItems="center"
        iconLeft={
          <CircleIcon>
            <ResumoDePedido height={24} width={24} color={Colors.GRAY500} />
          </CircleIcon>
        }
        iconRight={<ChevronRight height={24} width={24} />}
        onClick={() => {
          setShowBottomSheetContratos(true)
        }}
      >
        <Paragraph fontWeight="bold" fontSize="12px" color={Colors.GRAY500} margin="0 0 4px">
          Contratos desse refinanciamento
        </Paragraph>
        <Paragraph fontSize="12px" color={Colors.GRAY400} margin="0">
          Esses são os contratos financiados nessa simulação
        </Paragraph>
      </ListaDescricao>

      <Alert
        title="Essa simulação é válida por 24 horas"
        customBackgroundColor={Colors.ALERT100}
        customIcon={<Info height={25} width={25} color={Colors.GRAY500} />}
      >
        Solicite e aproveite seu troco
      </Alert>

      {bottomSheetContratos ? (
        <BottomSheetContratos
          convenioSelecionado={convenioSelecionado}
          matriculaSelecionada={matriculaSelecionada}
          contratosSelecionados={contratosSelecionados}
          setShowBottomSheetContratos={setShowBottomSheetContratos}
        />
      ) : (
        <></>
      )}
      {bottomSheetCet ? <BottomSheetCet setShowBottomSheetCet={setShowBottomSheetCet} /> : <></>}
      {bottomSheetSeguro ? (
        <BottomSheetSeguro setShowBottomSheetSeguro={setShowBottomSheetSeguro} />
      ) : (
        <></>
      )}
    </Page>
  )
}

export default ResultadoSimulacao
