import React from 'react'

import { Dialog } from '@organisms'
import { Paragraph } from '@atoms'
import { ListaDescricao } from '@molecules'
import ISafe from '@interco/icons/core/privacy-security/ic_i_safe'
import PrepaymentOfReceivables from '@interco/icons/core/action-navigation/ic_calendar_money'
import { Colors } from '@utils/Colors'

interface Props {
  setShowBottomSheetSeguro: React.Dispatch<React.SetStateAction<boolean>>
}
export const BottomSheetSeguro = ({ setShowBottomSheetSeguro }: Props) => (
  <Dialog
    onClose={() => setShowBottomSheetSeguro(false)}
    headerTitle="Benefícios do Refinanciamento com seguro"
    buttonText="Entendi"
  >
    <Paragraph margin="0 0 20px 0">Para quem quer uma opção mais segura.</Paragraph>

    <ListaDescricao
      margin="15px 0"
      iconLeft={<ISafe height={25} width={25} color={Colors.GRAY500} />}
    >
      <Paragraph
        variant="inter"
        fontWeight="600"
        fontSize="12px"
        lineHeight="15px"
        color={Colors.GRAY500}
        textAlign="left"
        margin="0"
      >
        Tenha tranquilidade
      </Paragraph>
      <Paragraph
        variant="inter"
        fontWeight="400"
        fontSize="12px"
        lineHeight="15px"
        color={Colors.GRAY700}
        textAlign="justify"
      >
        Em caso de morte ou invalidez, seu empréstimo é quitado.
      </Paragraph>
    </ListaDescricao>

    <ListaDescricao
      margin="15px 0"
      iconLeft={<PrepaymentOfReceivables height={25} width={25} color={Colors.GRAY500} />}
    >
      <Paragraph
        variant="inter"
        fontWeight="600"
        fontSize="12px"
        lineHeight="15px"
        color={Colors.GRAY500}
        textAlign="left"
        margin="0"
      >
        Crédito rápido
      </Paragraph>
      <Paragraph
        variant="inter"
        fontWeight="400"
        fontSize="12px"
        lineHeight="15px"
        color={Colors.GRAY700}
        textAlign="justify"
      >
        Seu crédito é liberado em até 3 dias se estiver tudo certo com a sua documentação.
      </Paragraph>
    </ListaDescricao>
  </Dialog>
)
