import React from 'react'

import { useSelector } from 'react-redux'

import { ApplicationState } from '@store/types'
import { Button } from '@interco/inter-ui/components/Button'
import { Colors } from '@utils/Colors'
import { Page } from '@templates'
import { validaEnvHomeByBridge } from '@utils/functions'
import Timer from '@icons/Signals/Timer'
import { GridStyled } from '@atoms'
import { ColorWrapper, TextParagraph } from '@atoms/TextParagraph'

const InssIndisponivel = () => {
  const { origemGoBack, isApp } = useSelector((state: ApplicationState) => state.ui.navigation)

  return (
    <Page
      stickyFooter={
        <Button
          variant="primary"
          onClick={() => {
            window.location.href = validaEnvHomeByBridge(String(origemGoBack), isApp) || ''
          }}
        >
          Ir para home
        </Button>
      }
    >
      <div style={{ justifyContent: 'center' }}>
        <GridStyled justify="space-around" margin="24px 0">
          <Timer />
        </GridStyled>
        <ColorWrapper
          fontWeight="600"
          style={{ marginBottom: '8px', display: 'flex', justifyContent: 'center' }}
        >
          <TextParagraph variant="headline-h1" textAlign="center">
            INSS indisponível
          </TextParagraph>
        </ColorWrapper>
        <ColorWrapper color={Colors.GRAY500} style={{ display: 'flex', justifyContent: 'center' }}>
          <TextParagraph variant="body-3" margin="10px 0 16px 0" textAlign="center">
            Devido ao horário, não conseguimos dar sequência na contratação do empréstimo.
          </TextParagraph>
        </ColorWrapper>
        <ColorWrapper color={Colors.GRAY500} style={{ display: 'flex', justifyContent: 'center' }}>
          <TextParagraph variant="body-3" textAlign="center">
            Por favor, retorne entre 06h e 22h
          </TextParagraph>
        </ColorWrapper>
      </div>
    </Page>
  )
}

export default InssIndisponivel
