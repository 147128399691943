import { BaseServices, HttpMethod } from '@services/base'

import { BridgeService } from './bridge'

export interface DataAttPropostaType {
  simulacaoId: string
  documentoId: number
  possuiSeguro?: boolean
  tipoSimulacao?: string
}

export default async (data: DataAttPropostaType, accessToken?: string) => {
  const pathname = process.env.REACT_APP_PROPOSTA_ATUALIZAR
  const method = HttpMethod.POST
  const host = process.env.REACT_APP_API_HOST
  const headers: Record<string, string> = {}
  if (BridgeService.isBrowser() && accessToken) {
    headers.Authorization = `Bearer ${accessToken}`
  }

  const response = await BaseServices.request({
    headers,
    pathname,
    method,
    host,
    data,
  })
  return response
}
