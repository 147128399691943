import React from 'react'

import { useSelector } from 'react-redux'

import { ApplicationState } from '@store/types'
import { TemplatesPDF } from '@interco/cp-react-ui-lib'

const CapaCCB = () => {
  const { dadosProposta } = useSelector((state: ApplicationState) => state.refinanciamento.proposta)

  const variant = 'web'

  return (
    <TemplatesPDF.PageCapaCCB
      variant={variant}
      footer={{
        atualizado: new Date('2022-07-27T00:00'),
        codigo: 'CP101',
        edicao: '1a',
        divulgacao: 'Pública',
        contatos: [
          'www.bancointer.com.br - Central de Atendimento: 3003-4070 (Capitais e Regiões Metropolitanas)',
          'SAC 0800 940 9999 - Ouvidoria: 0800 940 7772 - Deficiente Fala e Audição 0800 979 7099',
        ],
      }}
      text={[
        {
          text: `Olá, ${dadosProposta.nomeCliente}!`,
        },
        {
          text: 'Agradecemos a sua confiança ao contratar o Consignado Inter!',
        },
        {
          text: 'Nos importamos muito com a sua experiência, por isso, atenção: não cobramos e nem autorizamos a cobrança de nenhum valor por terceiros para a intermediação ou liberação do seu empréstimo.',
        },
      ]}
      assinatura={{
        isDgAssinado: true,
      }}
    />
  )
}

export default CapaCCB
