import { action, Action } from 'typesafe-actions'

import {
  PropostaTypes,
  PropostaApi,
  DocumentosEnvioType,
  DadosPropostaPayload,
  CallbackSalvarProposta,
  CallbackInfoValida,
  CriacaoPropostaPayload,
} from './types'

export const dadosPropostaRequest = (): Action => action(PropostaTypes.DADOS_PROPOSTA_REQUEST)
export const dadosPropostaSucess = (payload: PropostaApi): Action =>
  action(PropostaTypes.DADOS_PROPOSTA_SUCCESS, payload)
export const dadosPropostaError = (): Action => action(PropostaTypes.DADOS_PROPOSTA_ERROR)

export const setNumeroProposta = (payload: string | number): Action =>
  action(PropostaTypes.SET_NUMERO_PROPOSTA, payload)

export const criarProposta = (payload?: CriacaoPropostaPayload): Action =>
  action(PropostaTypes.CRIAR_PROPOSTA, payload)

export const criarPropostaSuccess = (payload: string | number): Action =>
  action(PropostaTypes.CRIAR_PROPOSTA_SUCCESS, payload)

export const criarPropostaError = (): Action => action(PropostaTypes.CRIAR_PROPOSTA_ERROR)

export const setTipoDocumento = (payload: DocumentosEnvioType[]): Action =>
  action(PropostaTypes.SET_TIPO_DOCUMENTO, payload)

export const setDocumentoId = (payload: string): Action =>
  action(PropostaTypes.SET_DOCUMENTO_ID, payload)

export const setIdIteracao = (payload: string): Action =>
  action(PropostaTypes.SET_ID_ITERACAO, payload)

export const salvarPropostaRequest = (payload: CallbackSalvarProposta): Action =>
  action(PropostaTypes.SALVAR_PROPOSTA_REQUEST, payload)

export const salvarPropostaSuccess = (): Action => action(PropostaTypes.SALVAR_PROPOSTA_SUCCESS)

export const salvarPropostaError = (): Action => action(PropostaTypes.SALVAR_PROPOSTA_ERROR)

export const dadosPropostaAntigaRequest = (payload?: DadosPropostaPayload): Action =>
  action(PropostaTypes.DADOS_PROPOSTA_ANTIGA_REQUEST, payload)

export const dadosPropostaAntigaSucess = (payload: PropostaApi): Action =>
  action(PropostaTypes.DADOS_PROPOSTA_ANTIGA_SUCCESS, payload)

export const dadosPropostaAntigaError = (): Action =>
  action(PropostaTypes.DADOS_PROPOSTA_ANTIGA_ERROR)

export const infoValida = (payload: CallbackInfoValida): Action =>
  action(PropostaTypes.INFO_VALIDA, payload)

export const setContato = (payload: boolean): Action => action(PropostaTypes.SET_CONTATO, payload)

export const setValidaLoading = (payload: boolean): Action =>
  action(PropostaTypes.SET_VALIDA_LOADING, payload)
