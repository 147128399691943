import { put, select } from 'typed-redux-saga'
import { AnyAction } from 'redux'

import { showError } from '@store/ui/error/actions'
import services from '@services'
import { ApplicationState } from '@store/types'
import { setIdIteracao, setTipoDocumento } from '@store/refinanciamento/proposta/actions'
import { BridgeService } from '@services/bridge'
import { HttpStatus } from '@services/base'
import { logout } from '@utils/functions'
import { ErroType } from '@utils/types'

import { hideLoading, postCapturaDocumentos, showLoading } from '../actions'

interface Props {
  noRemoveLoader: boolean
  actionCallbackError: {
    message: string
    actionCallback: () => AnyAction
    actionTitle: string
  }
  type: string
}

export default function* postCaptureDocuments({
  noRemoveLoader,
  actionCallbackError = {
    message: 'Aguarde um instante e tente novamente.',
    actionCallback: () => postCapturaDocumentos(),
    actionTitle: 'Tentar Novamente',
  },
}: Props) {
  try {
    const appState = yield* select((state: ApplicationState) => state)
    const propostaInfo = yield* select((state: ApplicationState) => state.refinanciamento.proposta)
    const propostaAntiga = appState.refinanciamento.contratos.contratosSelecionados.map(
      (item) => item.numeroProposta,
    )[0]
    // Caso ja tenha carregado os documentos, não busca novamente para evitar erro de criação de integração com GEDIN
    if (!propostaInfo?.tipoDocumento) {
      yield put(showLoading())
      const { data, status } = yield services.postCaptureDocuments(
        {
          cpf: appState.refinanciamento.dadosPessoais.cpf,
          numeroProposta:
            String(appState.refinanciamento.proposta.numeroProposta) || propostaAntiga,
        },
        appState.session.accessToken,
      )

      if (data) {
        const tipoDocumento = data.enviados.concat(data.pendentes)
        yield put(setTipoDocumento(tipoDocumento))
        yield put(setIdIteracao(data.idInteracao))
      }

      if (!noRemoveLoader) {
        yield put(hideLoading())
      }
      if (data && status === 200) return true
    }

    if (propostaInfo?.tipoDocumento) return true
    yield put(showError(actionCallbackError))
    return false
  } catch (error) {
    const erroTyped = error as ErroType
    yield put(hideLoading())
    if (BridgeService.isBrowser() && erroTyped.status === HttpStatus.UNAUTHORIZED) {
      yield put(
        showError({
          title: 'Sessão expirada',
          message: 'Efetue o login novamente.',
          actionTitle: 'Continuar',
          functionCallback: () => logout(),
        }),
      )
      return false
    }
    yield put(showError(actionCallbackError))
    return false
  }
}
