import styled, { keyframes } from 'styled-components'

import { Colors } from '@utils/Colors'

export const LineInfo = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: 0 0 16px 0;
`

const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`

export const FadeIn = styled.div`
  animation: ${fadeIn} 0.5s ease-in-out;
  background: ${Colors.WHITE};
`
