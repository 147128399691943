import { InfoParcelas, pendencyType } from '@store/refinanciamento/simulacao/types'
import { BaseServices, HttpMethod } from '@services/base'

import { BridgeService } from './bridge'

export default (data: SimulacaoRequest, accessToken?: string) => {
  const pathname = process.env.REACT_APP_POST_SIMULACAO_REFINANCIAMENTO
  const method = HttpMethod.POST
  const host = process.env.REACT_APP_API_HOST
  const headers: Record<string, string> = {}
  if (BridgeService.isBrowser() && accessToken) {
    headers.Authorization = `Bearer ${accessToken}`
  }

  const response = BaseServices.request<SimulacaoResponse>({
    headers,
    pathname,
    method,
    host,
    data,
  })
  return response
}

export interface Contracts {
  contractNumber: string
  installmentAmount: number
  debtBalance: number
}

/**
 * API Types
 */
export interface SimulacaoRequest {
  socialNumber: string
  benefitNumber: string
  // Codigo do convenio
  categoryId: string
  proposalNumber: string
  contracts: Contracts[]
}

export interface SimulacaoResponse {
  debtBalance: number
  pending: pendencyType
  simulations: InfoParcelas[]
  simulationsWithInsurance: InfoParcelas[]
}
