import React from 'react'

import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'

import { BottomSheet } from '@interco/inter-ui/components/BottomSheet'
import ChevronRight from '@interco/icons/core/action-navigation/ic_chevron_right'
import { trackingRefin } from '@store/refinanciamento/simulacao/actions'
import routes from '@routes/RefinV2/routes'
import { StepRefin, TipoDocumento } from '@utils/enums'
import { setTipoDocumento } from '@store/refinanciamento/documentos/actions'
import { ColorWrapper, TextParagraph } from '@atoms/TextParagraph'
import { Colors } from '@utils/Colors'

import { Bolder, ColumnText, LineInfo } from '../../styles'

interface Props {
  setOpenTipoDoc: React.Dispatch<React.SetStateAction<boolean>>
}
const BottomsheetTipoDoc = ({ setOpenTipoDoc }: Props) => {
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const selectNavigate = (tipoDoc: string) => {
    dispatch(trackingRefin(StepRefin.INICIO_CAPTURA_DOCUMENTOS))
    dispatch(setTipoDocumento(tipoDoc))
    navigate(routes.MODO_ENVIO)
  }

  return (
    <BottomSheet onClose={() => setOpenTipoDoc(false)}>
      <ColorWrapper color={Colors.GRAY500} fontWeight="600" margin="22px 0 16px 0">
        <TextParagraph variant="headline-h3">Qual documento você quer enviar?</TextParagraph>
      </ColorWrapper>
      <LineInfo onClick={() => selectNavigate(TipoDocumento.CNH)}>
        <ColumnText>
          <ColorWrapper color={Colors.GRAY500} margin="0">
            <TextParagraph variant="body-3">
              <Bolder>Carteira de motorista (CNH)</Bolder>
            </TextParagraph>
          </ColorWrapper>
        </ColumnText>
        <ChevronRight height={24} width={24} color={Colors.PRIMARY500} />
      </LineInfo>
      <LineInfo onClick={() => selectNavigate(TipoDocumento.RG)}>
        <ColumnText>
          <ColorWrapper color={Colors.GRAY500} margin="0">
            <TextParagraph variant="body-3">
              <Bolder>RG</Bolder>
            </TextParagraph>
          </ColorWrapper>
        </ColumnText>
        <ChevronRight height={24} width={24} color={Colors.PRIMARY500} />
      </LineInfo>
      <LineInfo onClick={() => selectNavigate(TipoDocumento.CARTEIRA_FUNCIONAL)} removeBorder>
        <ColumnText>
          <ColorWrapper color={Colors.GRAY500} margin="0">
            <TextParagraph variant="body-3">
              <Bolder>Carteira funcional</Bolder>
            </TextParagraph>
          </ColorWrapper>
        </ColumnText>
        <ChevronRight height={24} width={24} color={Colors.PRIMARY500} />
      </LineInfo>
    </BottomSheet>
  )
}
export default BottomsheetTipoDoc
