/**
 * Data Type
 */
export interface InfoParcelas {
  numberOfInstallments: number
  requestedAmount: number
  installmentAmount: number
  availableAmount: number
  amountIOF: number
  percentageIOF: number
  financedAmount: number
  cETAm: number
  cETAa: number
  monthlyNominalRate: number
  yearlyNominalRate: number
  contractualInterest: number
  lastDueDate: string
  firstDueDate: string
  moneyChange: number
  insuranceFee: number
}

/**
 * Actions Types
 */

export enum SimulacaoTypes {
  SIMULACAO_REQUEST = '@refinanciamento/simulacao/SIMULACAO_REQUEST',
  SIMULACAO_SUCCESS = '@refinanciamento/simulacao/SIMULACAO_SUCCESS',
  SIMULACAO_ERROR = '@refinanciamento/simulacao/SIMULACAO_ERROR',
  SALVAR_LEAD_REQUEST = '@refinanciamento/simulacao/SALVAR_LEAD_REQUEST',
  SALVAR_LEAD_SUCCESS = '@refinanciamento/simulacao/SALVAR_LEAD_SUCCESS',
  SALVAR_LEAD_ERROR = '@refinanciamento/simulacao/SALVAR_LEAD_ERROR',
  SET_PARCELA_SELECIONADA = '@refinanciamento/simulacao/SET_PARCELA_SELECIONADA',
  SET_SALDO_DEVEDOR = '@refinanciamento/simulacao/SET_SALDO_DEVEDOR',
  SET_TOTAL_PARCELAS = '@refinanciamento/simulacao/SET_TOTAL_PARCELAS',
  TRACKING_REFIN = '@refinanciamento/simulacao/TRACKING_REFIN',
  SELECT_SIMULACAO = '@refinanciamento/simulacao/SELECT_SIMULACAO',
  SET_SIMULACAO_ID = '@refinanciamento/simulacao/SET_SIMULACAO_ID',
  GET_SIMULACAO_ID = '@refinanciamento/simulacao/GET_SIMULACAO_ID',
  LOADING_SIMULACAO_ID = '@refinanciamento/simulacao/LOADING_SIMULACAO_ID',
  CLEAR_SIMULACAO = '@refinanciamento/simulacao/CLEAR_SIMULACAO',
  SET_DADOS_SIMULACAO_ALTERADOS = '@refinanciamento/simulacao/SET_DADOS_SIMULACAO_ALTERADOS',

  SET_COUNTER_RECALL = '@novo/beneficiarioInss/SET_COUNTER_RECALL',
}
/**
 * State Type
 */

export interface SimulacaoState {
  readonly parcelaAtual: InfoParcelas
  readonly simulations: InfoParcelas[]
  readonly simulationsWithInsurance: InfoParcelas[]
  readonly debtBalance: number
  readonly totalParcelas: number
  readonly loadingSimulacao?: boolean
  readonly errorSimulacao?: boolean
  readonly loadingLead?: boolean
  readonly errorLead?: boolean
  readonly simulacaoSelecionada: 'simulations' | 'simulationsWithInsurance'
  readonly simulacaoId: number
  readonly loadingGetSimulacao: boolean
  readonly counterRecall: number
  readonly dadosSimulacaoAlterados: boolean
}

export type pendencyType =
  | 'PENDENCIA_IRREVERSIVEL'
  | 'PENDENCIA_TEIMOSINHA'
  | 'PENDENCIA_VALIDACAO'
  | ''

/**
 * Actions Payload Types
 */
export interface CallbackSimulacaoType {
  pending?: pendencyType
  simulations: InfoParcelas[]
  simulationsWithInsurance: InfoParcelas[]
}
export type SimulacaoRequestPayload = {
  callback: (params: CallbackSimulacaoType) => void
}
export type SalvarLeadRequestPayload = {
  callback: () => void
}
export type SimulacaoIdPayload = {
  callback: () => void
}
