import styled from 'styled-components'

import { Text } from '@interco/inter-ui/components/Text'

export const TextContainer = styled.div`
  width: 100%;
  display: flex;
  flex-flow: column wrap;
  text-align: left;
  margin-top: 24px;
  span {
    font-style: normal;
    font-weight: 600;
    line-height: 30px;
    color: #161616;
  }
`

export const ColorWrapper = styled.div<{ color?: string; fontWeight?: string; margin?: string }>`
  margin: ${({ margin }) => margin || '0 0 8px 0'};
  span {
    color: ${({ color }) => color};
    font-weight: ${({ fontWeight }) => fontWeight};
  }
`

export const TextParagraph = styled(Text)<{
  margin?: string
  fontSize?: string
  lineHeight?: string
  fontWeight?: string
  textAlign?: string
}>`
  margin: ${({ margin }) => margin};
  line-height: ${(props) => props.lineHeight};
  font-weight: ${(props) => props.fontWeight};
  text-align: ${(props) => props.textAlign};
`
