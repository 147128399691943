import { useSelector } from 'react-redux'

import { Paragraph } from '@atoms'
import GridStyled from '@atoms/GridStyled'
import { WarningIcon } from '@icons/Signals'
import { Page } from '@templates'
import { Button } from '@interco/inter-ui/components/Button'
import { ApplicationState } from '@store/types'
import { validaEnvHomeByBridge } from '@utils/functions'
import { Colors } from '@utils/Colors'
import GoogleTag from '@utils/GoogleTag'

const SemEmprestimo = () => {
  const { origemGoBack, isApp } = useSelector((state: ApplicationState) => state.ui.navigation)

  const callGoogleTag = (urlRedirect: string, buttonName: string) => {
    GoogleTag.simuladorConsignadoTag({
      only_app: true,
      content_name: buttonName,
      tela: 'Sem contratos pra refinanciamento',
      content_action: 'Clique',
      element_action: 'click button',
      c_page: window.location.href,
      redirect_url: urlRedirect,
    })
  }

  const handlePortabilidade = () => {
    callGoogleTag(
      `${process.env.REACT_APP_PORTABILIDADE_CONSIGNADO}?origem=app`,
      'Solicitar portabilidade de consignado',
    )
    window.location.href = `${process.env.REACT_APP_PORTABILIDADE_CONSIGNADO}?origem=app`
  }

  const handleProdutos = () => {
    callGoogleTag(`${validaEnvHomeByBridge(String(origemGoBack), isApp)}`, 'Ver todos os produtos')
    window.location.href = `${validaEnvHomeByBridge(String(origemGoBack), isApp)}`
  }

  return (
    <Page
      stickyFooter={
        <>
          <Button onClick={handlePortabilidade}>Solicitar portabilidade de consignado</Button>
          <Button variant="secondary" onClick={handleProdutos}>
            Ver todos os produtos
          </Button>
        </>
      }
    >
      <GridStyled>
        <WarningIcon />
      </GridStyled>
      <Paragraph
        variant="sora"
        fontWeight={600}
        fontSize="24px"
        lineHeight="30px"
        color={Colors.GRAY500}
        margin="40px 0 0"
        textAlign="left"
      >
        Sem contratos para refinanciamento
      </Paragraph>
      <Paragraph margin="8px 0 0 0" textAlign="left">
        Essa opção é válida apenas para quem tem contrato de Empréstimo Consignado com a gente.
        Traga seu contrato pra cá e tenha uma ótima experiência.
      </Paragraph>
    </Page>
  )
}
export default SemEmprestimo
