import { BaseServices, HttpMethod } from '@services/base'

import { BridgeService } from './bridge'

export default (data: VerificaPendenciaLeadRequest, accessToken?: string) => {
  const pathname = `${process.env.REACT_APP_VERIFICA_PENDENCIA_LEAD_BFF}`
  const method = HttpMethod.POST
  const host = process.env.REACT_APP_API_HOST
  const headers: Record<string, string> = {}
  if (BridgeService.isBrowser() && accessToken) {
    headers.Authorization = `Bearer ${accessToken}`
  }

  return BaseServices.request({
    headers,
    pathname,
    method,
    host,
    data,
  })
}

export interface VerificaPendenciaLeadRequest {
  dataNascimento: string
  cpf: string
  email: string
  primeiroNome: string
  segundoNome: string
  utmCampaign: string
  utmContent: string
  utmMedium: string
  utmSource: string
  clienteId: string
  gaClid: string
  gaClientId: string
  facebookId: string
  upag: string
  matricula: string
  origem: string
  familiaId: number
  telefoneCelular: string
  salarioBruto: number
  valorEconomia: number
  saldoDevedor: number
  valorParcelaNovo: number
  taxaNominalMensal: number
  taxaNominalMensalNovo: number
  valorSolicitado: number
  valorFinanciado: number
  valorParcela: number
  bancoContratoAtual: string
  fontePagamento: string
  convenioDesc: string
  numeroContrato: string
  situacaoFuncional: string
  convenioEstado: string
  convenioCidade: string
  orgaoDescricao: string
  familiaDescricao: string
  formaContato: string
  patente: string
  indicativo: string
  dataIngressoExercito: string
  convenioId: number
  orgaoId: number
  quantidadeParcelasFalta: number
  pendencia: string
  origemSimulacao: string
}
