import { call, put, select } from 'typed-redux-saga'

import services from '@services'
import { showError } from '@store/ui/error/actions'
import { ApplicationState } from '@store/types'

import { Estado, EstadosAPI } from '../types'
import { estadosError, estadosLoading, estadosRequest, estadosSuccess } from '../actions'

const estadosBuild = (data: EstadosAPI): Estado[] =>
  data && data?.uf.map((estado) => ({ uf: estado, nome: estado }))

export default function* getEstados() {
  const { accessToken } = yield* select((state: ApplicationState) => state.session)
  try {
    yield put(estadosLoading())
    const response = yield* call(services.getEstados, accessToken)
    yield put(estadosSuccess(estadosBuild(response.data)))
  } catch (error) {
    yield put(estadosError())
    yield put(
      showError({
        title: 'Erro ao buscar os estados',
        message: 'Ocorreu um erro na listagem de estados.',
        actionTitle: 'Tentar Novamente',
        actionCallback: () => estadosRequest(),
      }),
    )
  }
}
