import { BaseServices, HttpMethod } from '@services/base'
import { BancosAPI } from '@store/refinanciamento/dadosBancarios/types'

import { BridgeService } from './bridge'

export default (accessToken?: string) => {
  const pathname = process.env.REACT_APP_BANKS_BFF
  const method = HttpMethod.GET
  const host = process.env.REACT_APP_API_HOST
  const headers: Record<string, string> = {}
  if (BridgeService.isBrowser() && accessToken) {
    headers.Authorization = `Bearer ${accessToken}`
  }
  const response = BaseServices.request<BancosAPI>({ headers, pathname, method, host })
  return response
}
