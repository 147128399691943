import { Reducer } from 'redux'

import { ConsultaMargemInssState, ConsultaMargemInssTypes } from './types'

const INITIAL_STATE: ConsultaMargemInssState = {
  loading: false,
  error: false,
  consultaMargemRealizada: false,
  valorTotalParcela: 0,
  dadosBancarios: {
    banco: '',
    agencia: '',
    conta: '',
    digitoConta: '',
    tipoCredito: '',
    margem: 0,
    nome: '',
  },
  loadingInssApis: false,
  counterRecall: 0,
}

const reducer: Reducer<ConsultaMargemInssState> = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ConsultaMargemInssTypes.CONSULTA_MARGEM_INSS_REQUEST:
      return { ...state, consultaMargemRealizada: false, error: false, loading: true }
    case ConsultaMargemInssTypes.CONSULTA_MARGEM_INSS_SUCCESS:
      return {
        ...state,
        valorTotalParcela: action.payload,
        consultaMargemRealizada: true,
        error: false,
        loading: false,
      }
    case ConsultaMargemInssTypes.CONSULTA_MARGEM_INSS_ERROR:
      return { ...state, consultaMargemRealizada: false, error: true, loading: false }
    case ConsultaMargemInssTypes.SET_INFO_INSS_DADOS_BANCARIOS:
      return {
        ...state,
        dadosBancarios: action.payload,
      }
    case ConsultaMargemInssTypes.SET_LOADING_INSS_APIS:
      return { ...state, loadingInssApis: action.payload }
    case ConsultaMargemInssTypes.SET_COUNTER_RECALL:
      return { ...state, counterRecall: state.counterRecall + action.payload }
    default:
      return state
  }
}

export default reducer
