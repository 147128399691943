import React from 'react'

import { BottomSheet } from '@interco/inter-ui/components/BottomSheet'
import { Button } from '@interco/inter-ui/components/Button'
import { ColorWrapper, TextParagraph } from '@atoms/TextParagraph'
import { Colors } from '@utils/Colors'

interface Props {
  setShowBottomSheetRefazerBiometria: React.Dispatch<React.SetStateAction<boolean>>
  redirectBiometria: () => void
}
export const BottomSheetRefazerBiometria = ({
  setShowBottomSheetRefazerBiometria,
  redirectBiometria,
}: Props) => (
  <BottomSheet
    onClose={() => {
      setShowBottomSheetRefazerBiometria(false)
      redirectBiometria()
    }}
    noSwipe
  >
    <ColorWrapper color={Colors.GRAY500} fontWeight="600" margin="0">
      <TextParagraph variant="headline-h3">Biometria não validada!</TextParagraph>
    </ColorWrapper>

    <ColorWrapper color={Colors.GRAY500} margin="0 0 16px 0">
      <TextParagraph variant="body-3" margin="0 0 16px 0">
        Ocorreu um erro durante a biometria, por favor faça o processo novamente!
      </TextParagraph>
    </ColorWrapper>
    <Button
      fullWidth
      onClick={() => {
        setShowBottomSheetRefazerBiometria(false)
        redirectBiometria()
      }}
      style={{ marginTop: '14px' }}
    >
      Refazer biometria
    </Button>
  </BottomSheet>
)
