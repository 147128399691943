import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
`
export const LineDescription = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  width: 100%;
  margin-bottom: 24px;
`
export const DivLine = styled.div`
  width: 80%;
  margin-left: 16px;
`
