import { BaseServices, HttpMethod } from '@services/base'
import { CidadesAPI } from '@store/refinanciamento/endereco/types'

import { BridgeService } from './bridge'

export default (estadoUf: string, accessToken?: string) => {
  const pathname = `${process.env.REACT_APP_GET_CIDADES_BFF}?state=${estadoUf}`
  const method = HttpMethod.GET
  const host = process.env.REACT_APP_API_HOST
  const headers: Record<string, string> = {}
  if (BridgeService.isBrowser() && accessToken) {
    headers.Authorization = `Bearer ${accessToken}`
  }
  const response = BaseServices.request<CidadesAPI>({
    headers,
    pathname,
    method,
    host,
  })
  return response
}
