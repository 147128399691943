import { BaseServices, HttpMethod } from '@services/base'

import { BridgeService } from './bridge'

export interface FinalizarPropostaType {
  codigoProposta: string
  cpf: string
  email: string
  telefone: string
  produto: string
}

export default async (data: FinalizarPropostaType, accessToken?: string) => {
  const pathname = process.env.REACT_APP_PROPOSTA_FINALIZAR
  const method = HttpMethod.POST
  const host = process.env.REACT_APP_API_HOST
  const headers: Record<string, string> = {}
  if (BridgeService.isBrowser() && accessToken) {
    headers.Authorization = `Bearer ${accessToken}`
  }

  const response = await BaseServices.request({
    headers,
    pathname,
    method,
    host,
    data,
  })
  return response
}
