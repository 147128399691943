import { BaseServices, HttpMethod } from '@services/base'

export default (data: TrackRefinRequest) => {
  const pathname = process.env.REACT_APP_TRACKING_REFIN
  const method = HttpMethod.POST
  const host = process.env.REACT_APP_API_HOST

  const response = BaseServices.request({
    pathname,
    method,
    host,
    data,
  })
  return response
}

export interface TrackRefinRequest {
  clientId: string
  step: string
  eventType: string
  sessionId: string
  numeroContrato: string
  valorProximaParcela: number
  vencimentoProxParcela: string
  valorQuitacao: number
  valorEmprestimo: number
  taxaAtual: number
  valorLiberado: number
  quantidadeParcelas: number
  valorTotalRefinanciado: number
  saldoDevedorPreRefin: number
  valorTotalSolicitado: number
  taxaJuros: number
  iof: number
  custoEfetivoTotal: number
  valorCET: number
  origem: string
  convenioId: number
  produto: string
  documento: string
}
