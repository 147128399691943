import { useEffect } from 'react'

import { useSelector } from 'react-redux'

import GridStyled from '@atoms/GridStyled'
import { WarningIcon } from '@icons/Signals'
import { Page } from '@templates'
import { Button } from '@interco/inter-ui/components/Button'
import { ApplicationState } from '@store/types'
import { validaEnvHomeByBridge } from '@utils/functions'
import { ColorWrapper, TextParagraph } from '@atoms/TextParagraph'
import Tag from '@utils/Tag'

const SemEmprestimo = () => {
  const { origemGoBack, isApp } = useSelector((state: ApplicationState) => state.ui.navigation)

  useEffect(() => {
    Tag.enviarTagueamentoWeb({
      element_name: '',
      section_name: 'Sem contratos',
      element_action: 'page_loaded',
      c_page: window.location.href,
      redirect_url: '',
      step: 'null',
    })
  }, [])

  return (
    <Page
      stickyFooter={
        <Button
          onClick={() => {
            window.location.href = validaEnvHomeByBridge(String(origemGoBack), isApp) || ''
          }}
        >
          Voltar para home
        </Button>
      }
    >
      <GridStyled>
        <WarningIcon />
      </GridStyled>

      <ColorWrapper fontWeight="600" margin="24px 0 8px 0" style={{ textAlign: 'center' }}>
        <TextParagraph variant="headline-h1" textAlign="center">
          Nenhum contrato disponível
        </TextParagraph>
      </ColorWrapper>
      <ColorWrapper margin="16px 0 16px 0">
        <TextParagraph variant="body-3">
          No momento, você não possui nenhum contrato disponível para refinanciamento
        </TextParagraph>
      </ColorWrapper>
    </Page>
  )
}
export default SemEmprestimo
