import React, { useState } from 'react'

import { useNavigate } from 'react-router-dom'

import { Paragraph } from '@atoms'
import { Dialog } from '@organisms'
import routes from '@routes/Refin/routes'
import { Checkbox } from '@interco/inter-ui/components/Checkbox'

import { VariantDialogContratos } from './dialogVariants'
import { LinkTermoAutorizacao } from '../styles'

interface Props {
  setShowBottomSheet: React.Dispatch<React.SetStateAction<boolean>>
  variant: VariantDialogContratos
  customTextButton?: string
  customActionButton?: () => void
  linkAutorizacao?: boolean
}
export const BottomSheetsContrato = ({
  setShowBottomSheet,
  variant,
  customTextButton,
  customActionButton,
  linkAutorizacao,
}: Props) => {
  const navigate = useNavigate()
  const [checkConsultaMargemInss, setCheckConsultaMargemInss] = useState(false)
  return (
    <Dialog
      onClose={() => setShowBottomSheet(false)}
      headerTitle={variant.title}
      buttonText={customTextButton || 'Entendi'}
      buttonAction={customActionButton}
      shouldDisableButton={Boolean(linkAutorizacao && !checkConsultaMargemInss)}
    >
      {linkAutorizacao ? (
        <Checkbox
          id="checkbox-inss"
          reversed
          checked={checkConsultaMargemInss}
          onChange={(e) => setCheckConsultaMargemInss(e.target.checked)}
          label={
            <Paragraph margin="0">
              {variant.text}
              {linkAutorizacao && (
                <LinkTermoAutorizacao onClick={() => navigate(routes.AUTORIZACAO)}>
                  Termo de Autorização
                </LinkTermoAutorizacao>
              )}
            </Paragraph>
          }
        />
      ) : (
        <Paragraph margin="0 0 20px 0">{variant.text}</Paragraph>
      )}
    </Dialog>
  )
}
