import React, { useEffect } from 'react'

import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'

import { TextParagraph } from '@atoms/TextParagraph'
import { ApplicationState } from '@store/types'
import { BridgeService } from '@services/bridge'
import { interWbSession } from '@interco/inter-webview-bridge'
import { Alert } from '@interco/inter-ui/components/Alert'
import { Page } from '@templates'
import IcChevronRight from '@interco/icons/core/action-navigation/ic_chevron_right'
import IcContract from '@interco/icons/core/files/ic_contract'
import { Colors } from '@utils/Colors'
import routes from '@routes/RefinV2/routes'
import {
  setCheckMessageRevisao,
  setDocumentoRevisao,
  setTitleDocumentoRevisao,
} from '@store/refinanciamento/documentosAssinatura/actions'
import { TipoDocs } from '@store/refinanciamento/listaDocumentos/types'
import Tag from '@utils/Tag'
import { dadosPropostaRequest } from '@store/refinanciamento/proposta/actions'

import { BoxCustom } from './styles'

const Termos = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const { documentos } = useSelector(
    (state: ApplicationState) => state.refinanciamento.documentosAssinatura,
  )
  const enumDocumentos = useSelector(
    (state: ApplicationState) => state.refinanciamento.listaDocumentos,
  )

  useEffect(() => {
    if (!BridgeService.isBrowser()) {
      interWbSession.editToolbar('Termos de contratação', true)
    }
  }, [])

  useEffect(() => {
    dispatch(dadosPropostaRequest())
  }, [dispatch])

  useEffect(() => {
    Tag.enviarTagueamentoWeb({
      element_name: '',
      section_name: 'Termos',
      element_action: 'page_loaded',
      c_page: window.location.href,
      redirect_url: '',
      step: 'null',
    })
  }, [])

  return (
    <Page>
      {documentos.length === 0 ? (
        <Alert title="Erro ao buscar termos" type="error">
          Ocorreu um erro ao buscar os termos, por favor tente novamente.
        </Alert>
      ) : (
        <>
          {documentos.map((item: { idTipoDocumento: TipoDocs | string }) => {
            const document = {
              ...enumDocumentos[item?.idTipoDocumento as TipoDocs],
              idTipoDocumento: item?.idTipoDocumento,
            }
            return (
              document && (
                <BoxCustom
                  key={item?.idTipoDocumento}
                  onClick={() => {
                    dispatch(setDocumentoRevisao(item.idTipoDocumento))
                    dispatch(setCheckMessageRevisao(document.checkMessage))
                    dispatch(setTitleDocumentoRevisao(document.text))
                    navigate(routes.TERMO_VISUALIZACAO)
                  }}
                  actived={false}
                >
                  <IcContract height={24} width={24} color={Colors.GRAY500} />
                  <TextParagraph
                    variant="body-3"
                    style={{
                      width: '70%',
                      color: Colors.GRAY500,
                      fontWeight: 700,
                      alignContent: 'flex-start',
                    }}
                  >
                    {document.text}
                  </TextParagraph>
                  <IcChevronRight height={24} width={24} color={Colors.PRIMARY500} />
                </BoxCustom>
              )
            )
          })}
        </>
      )}
    </Page>
  )
}
export default Termos
