import styled from 'styled-components'

import { BoxComponent } from '@atoms/Box/styles'

export const BoxCustom = styled(BoxComponent)`
  margin-top: 16px;
  min-height: 60px;
  padding: 20px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`
