import { BaseServices, HttpMethod } from '@services/base'

import { BridgeService } from './bridge'

export default (benefitNumber: string, accessToken?: string) => {
  const pathname = `${process.env.REACT_APP_GET_DADOS_BANCARIOS_INSS_BFF}`
  const method = HttpMethod.GET
  const host = process.env.REACT_APP_API_HOST
  const headers: Record<string, string> = {}
  headers.benefitNumber = benefitNumber
  if (BridgeService.isBrowser() && accessToken) {
    headers.Authorization = `Bearer ${accessToken}`
  }
  const response = BaseServices.request({
    headers,
    pathname,
    method,
    host,
  })
  return response
}
