import { ContractsData, ContratosAPI } from '@store/refinanciamento/contratos/types'
import { BaseServices, HttpMethod } from '@services/base'

import { BridgeService } from './bridge'

export default (payload: string, newApiVersion?: boolean, accessToken?: string) => {
  const pathname = newApiVersion
    ? `${process.env.REACT_APP_GET_CONTRATO_V2}/${payload}`
    : `${process.env.REACT_APP_GET_CONTRATOS}/${payload}`
  const method = HttpMethod.GET
  const host = process.env.REACT_APP_API_HOST
  const headers: Record<string, string> = {}
  if (BridgeService.isBrowser() && accessToken) {
    headers.Authorization = `Bearer ${accessToken}`
  }

  const response = BaseServices.request<ContratosAPI | ContractsData>({
    headers,
    pathname,
    method,
    host,
  })
  return response
}
