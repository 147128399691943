import { BaseServices, HttpMethod } from '@services/base'

import { BridgeService } from './bridge'

interface DocAnexoType {
  file: string
  documentType: string
  extension: string
}

export default async (data: DocAnexoType, accessToken?: string) => {
  const pathname = process.env.REACT_APP_ENVIAR_ANEXO
  const method = HttpMethod.POST
  const host = process.env.REACT_APP_API_HOST
  const headers: Record<string, string> = {}
  if (BridgeService.isBrowser() && accessToken) {
    headers.Authorization = `Bearer ${accessToken}`
  }

  const response = await BaseServices.request({
    headers,
    pathname,
    method,
    host,
    data,
  })
  return response
}
