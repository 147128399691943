import { BaseServices, HttpMethod } from '@services/base'

import { BridgeService } from './bridge'

export default (data: SalvarPropostaRequest, accessToken?: string) => {
  const pathname = process.env.REACT_APP_GET_POST_CAPTURA_REFIN
  const method = HttpMethod.POST
  const host = process.env.REACT_APP_API_HOST
  const headers: Record<string, string> = {}
  if (BridgeService.isBrowser() && accessToken) {
    headers.Authorization = `Bearer ${accessToken}`
  }
  const response = BaseServices.request<DataResponseType>({
    headers,
    pathname,
    method,
    host,
    data,
  })
  return response
}

export interface SalvarPropostaRequest {
  clientId: string
  cpf: string
  escolaridade: string
  profissao: string
  banco: string
  agencia: string
  conta: string
  paisNaturalidade: string
  estadoNaturalidade: string
  cidadeNaturalidade: string
  cep: string
  endereco: string
  numero: string
  complemento: string
  bairro: string
  estado: string
  cidade: string
  termoAceite: boolean
  numeroProposta: string
  tipoLiberacao: number
  ipCliente: string
  digitoConta: number
  simulacaoId: number
  valorPatrimonio: number
  pep: boolean
  tin: boolean
  codigoConvenio: number
}

export interface DataResponseType {
  documentoId: string
}
