import styled from 'styled-components'

import { Input } from '@interco/inter-ui/components/Input'
import { Colors } from '@utils/Colors'

export const CustomInput = styled(Input)`
  & input {
    color: ${Colors.GRAY500};
  }
`
export const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
`
