import { put, select } from 'typed-redux-saga'
import { AnyAction } from 'redux'

import services from '@services'
import { ApplicationState } from '@store/types'
import { BridgeService } from '@services/bridge'
import { HttpStatus } from '@services/base'
import { showError } from '@store/ui/error/actions'
import { logout } from '@utils/functions'
import { ErroType } from '@utils/types'

export default function* postEditarLeadSaga({ payload }: AnyAction) {
  const { cpf } = yield* select((state: ApplicationState) => state.refinanciamento.dadosPessoais)
  const { simulacaoId } = yield* select(
    (state: ApplicationState) => state.refinanciamento.simulacao,
  )
  const { accessToken } = yield* select((state: ApplicationState) => state.session)
  try {
    const { tipo = 'Crédito Consignado Público' } = payload
    yield services.postEditarLead(
      {
        numeroProposta: payload?.numeroProposta || '',
        cpf,
        tipo,
        situacao: payload?.situacao || '',
        produto: 'REFINANCIAMENTO',
        idSimulacao: String(simulacaoId),
      },
      accessToken,
    )
    if (payload.callback instanceof Function) {
      payload.callback()
    }
  } catch (error) {
    const erroTyped = error as ErroType
    if (BridgeService.isBrowser() && erroTyped.status === HttpStatus.UNAUTHORIZED) {
      yield put(
        showError({
          title: 'Sessão expirada',
          message: 'Efetue o login novamente.',
          actionTitle: 'Continuar',
          functionCallback: () => logout(),
        }),
      )
    }
  }
}
