import { Reducer } from 'redux'

import { DocumentosTypes, DocumentosState } from './types'

const INITIAL_STATE: DocumentosState = {
  tipoDocumento: '',
  numeroDocumento: '',
  orgaoExpedidor: '',
  dataEmissao: '',
  nomeMae: '',
  genero: '',
  estadoSelecionado: '',

  orgaosExpedidores: [],
  orgaosExpedidoresError: false,
  orgaosExpedidoresLoading: false,

  loading: false,

  nome: '',
  telefone: '',
  email: '',
  valorLiberado: 0,
  valorParcela: 0,
  quantidadeParcelas: 0,
  taxa: 0,
  valorCET: 0,
  valorTotal: 0,
  idClient: '',
  isReassinatura: false,
  creditoProtegido: false,

  etapaBusca: 0,
  erroUpdateProposal: false,
}

const reducer: Reducer<DocumentosState> = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case DocumentosTypes.SET_ESTADO_SELECIONADO:
      return { ...state, estadoSelecionado: action.payload }
    case DocumentosTypes.SET_TIPO_DOCUMENTO:
      return { ...state, tipoDocumento: action.payload }
    case DocumentosTypes.SET_NUMERO_DOCUMENTO:
      return { ...state, numeroDocumento: action.payload }
    case DocumentosTypes.SET_ORGAO_EXPEDIDOR:
      return { ...state, orgaoExpedidor: action.payload }
    case DocumentosTypes.SET_DATA_EMISSAO:
      return { ...state, dataEmissao: action.payload }
    case DocumentosTypes.SET_NOME_MAE:
      return { ...state, nomeMae: action.payload }
    case DocumentosTypes.SET_GENERO:
      return { ...state, genero: action.payload }

    // Orgaos expedidores
    case DocumentosTypes.ORGAO_EXPEDIDOR_SUCCESS:
      return {
        ...state,
        orgaosExpedidoresError: false,
        orgaosExpedidoresLoading: false,
        orgaosExpedidores: action.payload,
      }
    case DocumentosTypes.ORGAO_EXPEDIDOR_REQUEST:
      return {
        ...state,
        orgaosExpedidoresError: false,
        orgaosExpedidoresLoading: true,
      }
    case DocumentosTypes.ORGAO_EXPEDIDOR_ERROR:
      return {
        ...state,
        orgaosExpedidoresLoading: false,
        orgaosExpedidoresError: true,
      }

    case DocumentosTypes.SHOW_LOADING:
      return { ...state, loading: true }

    case DocumentosTypes.HIDE_LOADING:
      return { ...state, loading: false }

    case DocumentosTypes.SET_NOME_CLIENTE:
      return { ...state, nome: action.payload }
    case DocumentosTypes.SET_TELEFONE:
      return { ...state, telefone: action.payload }
    case DocumentosTypes.SET_EMAIL:
      return { ...state, email: action.payload }
    case DocumentosTypes.SET_VALOR_LIBERADO:
      return { ...state, valorLiberado: action.payload }
    case DocumentosTypes.SET_VALOR_PARCELA:
      return { ...state, valorParcela: action.payload }
    case DocumentosTypes.SET_QTD_PARCELAS:
      return { ...state, quantidadeParcelas: action.payload }
    case DocumentosTypes.SET_TAXA:
      return { ...state, taxa: action.payload }
    case DocumentosTypes.SET_CET:
      return { ...state, valorCET: action.payload }
    case DocumentosTypes.SET_VALOR_TOTAL:
      return { ...state, valorTotal: action.payload }
    case DocumentosTypes.SET_ID_CLIENT:
      return { ...state, idClient: action.payload }
    case DocumentosTypes.SET_IS_REASSINATURA:
      return { ...state, isReassinatura: action.payload }
    case DocumentosTypes.SET_CREDITO_PROTEGIDO:
      return { ...state, creditoProtegido: action.payload }
    case DocumentosTypes.SET_ETAPA_BUSCA:
      return { ...state, etapaBusca: action.payload }

    case DocumentosTypes.SET_ERRO_UPDATE_PROPOSAL:
      return { ...state, erroUpdateProposal: action.payload }

    case DocumentosTypes.RESET:
      return { ...INITIAL_STATE }

    default:
      return state
  }
}

export default reducer
