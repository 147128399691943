import React from 'react'

import { useSelector } from 'react-redux'

import { formatCurrency } from '@utils/masks'
import { Colors } from '@utils/Colors'
import { ColorWrapper, TextParagraph } from '@atoms/TextParagraph'
import { ApplicationState } from '@store/types'
import { useMobileUserInfo } from '@hooks'
import { TipoEmprestimo } from '@utils/enums'
import { BridgeService } from '@services/bridge'

import { FadeIn, LineInfo } from '../styles'

export function roundToTwo(numero: number): number {
  return Math.round((numero + Number.EPSILON) * 100) / 100
}
const Detalhes = () => {
  const { parcelaAtual, debtBalance, simulacaoSelecionada } = useSelector(
    (state: ApplicationState) => state.refinanciamento.simulacao,
  )

  const userInfo = useMobileUserInfo()
  return (
    <FadeIn>
      <ColorWrapper fontWeight="600" margin="24px 0">
        <TextParagraph variant="headline-h3">Detalhes do refinanciamento</TextParagraph>
      </ColorWrapper>
      <LineInfo>
        <TextParagraph variant="caption-1">Saldo devedor pré-refinanciamento</TextParagraph>
        <ColorWrapper color={Colors.GRAY500} fontWeight="700" margin="0">
          <TextParagraph variant="caption-1" margin="0">
            {formatCurrency(debtBalance)}
          </TextParagraph>
        </ColorWrapper>
      </LineInfo>
      <LineInfo>
        <TextParagraph variant="caption-1" style={{ maxWidth: '55%' }}>
          Troco liberado
        </TextParagraph>
        <ColorWrapper color={Colors.GRAY500} fontWeight="700" margin="0">
          <TextParagraph variant="caption-1" margin="0">
            {formatCurrency(parcelaAtual.availableAmount)}
          </TextParagraph>
        </ColorWrapper>
      </LineInfo>
      <LineInfo>
        <TextParagraph variant="caption-1">Novo valor disponível</TextParagraph>
        <ColorWrapper color={Colors.GRAY500} fontWeight="700" margin="0">
          <TextParagraph variant="caption-1" margin="0">
            {formatCurrency(parcelaAtual.requestedAmount)}
          </TextParagraph>
        </ColorWrapper>
      </LineInfo>

      {simulacaoSelecionada === TipoEmprestimo.CREDITO_PROTEGIDO ? (
        <LineInfo>
          <TextParagraph variant="caption-1">Valor do seguro</TextParagraph>
          <ColorWrapper color={Colors.GRAY500} fontWeight="700" margin="0">
            <TextParagraph variant="caption-1" margin="0">
              {formatCurrency(parcelaAtual.insuranceFee)}
            </TextParagraph>
          </ColorWrapper>
        </LineInfo>
      ) : (
        <></>
      )}

      {!BridgeService.isBrowser() ? (
        <>
          <LineInfo>
            <TextParagraph variant="caption-1">Conta de recebimento</TextParagraph>
            <ColorWrapper color={Colors.GRAY500} fontWeight="700" margin="0">
              <TextParagraph variant="caption-1" margin="0">
                {userInfo?.account} (Sua conta Inter)
              </TextParagraph>
            </ColorWrapper>
          </LineInfo>

          <LineInfo>
            <TextParagraph variant="caption-1">Agência</TextParagraph>
            <ColorWrapper color={Colors.GRAY500} fontWeight="700" margin="0">
              <TextParagraph variant="caption-1" margin="0">
                0001
              </TextParagraph>
            </ColorWrapper>
          </LineInfo>
        </>
      ) : (
        <></>
      )}

      <LineInfo>
        <TextParagraph variant="caption-1">IOF</TextParagraph>
        <ColorWrapper color={Colors.GRAY500} fontWeight="700" margin="0">
          <TextParagraph variant="caption-1" margin="0">
            {`(${roundToTwo(parcelaAtual.percentageIOF).toFixed(2)}%) ${formatCurrency(
              parcelaAtual.amountIOF,
            )}`}
          </TextParagraph>
        </ColorWrapper>
      </LineInfo>

      <LineInfo>
        <TextParagraph variant="caption-1" style={{ maxWidth: '55%' }}>
          Custo efetivo total (CET)
        </TextParagraph>
        <ColorWrapper color={Colors.GRAY500} fontWeight="700" margin="0">
          <TextParagraph variant="caption-1" margin="0">
            {`(${roundToTwo(parcelaAtual.cETAa).toFixed(2)}% a.a.)`}
          </TextParagraph>
        </ColorWrapper>
      </LineInfo>
    </FadeIn>
  )
}
export default Detalhes
