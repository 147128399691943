import styled from 'styled-components'

import { Colors } from '@utils/Colors'

export const Grid = styled.div`
  justify-content: left;
`
export const GridScroll = styled.div`
  max-height: calc(100vh - 280px);
  overflow: scroll;
  ::-webkit-scrollbar {
    display: none;
  }
`

export const LinkTermoAutorizacao = styled.a`
  color: ${Colors.PRIMARY500};
  font-weight: 700;
`
export const LabelDiv = styled.div`
  display: flex;
  flex-direction: column;
  width: 90%;
`

export const Line = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: baseline;
`
export const LinkSite = styled.b`
  color: ${Colors.PRIMARY500};
  background-color: transparent;
  text-decoration: none;
  font-weight: 700;
  font-size: 12px;
  line-height: 15px;
`
