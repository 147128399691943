import { AnyAction } from 'redux'
import { call } from 'typed-redux-saga'

import postAutorizacaoISafe from '@services/postAutorizacaoISafe'
import { HttpStatus } from '@services/base'

import { PayloadAutorizacao } from '../types'

interface ErrorType {
  data: string
  status: HttpStatus
}
export default function* postContratacaoSaga(action: AnyAction) {
  const payload = action?.payload as PayloadAutorizacao
  try {
    yield* call(
      postAutorizacaoISafe,
      {
        tipoAutenticacao: payload.tipoAutenticacao,
        token: payload.token,
        valor: payload.valor,
      },
      payload.conta,
    )
    payload.callback()
  } catch (error) {
    const errorResponse = error as ErrorType
    if (errorResponse?.status === HttpStatus.FORBIDDEN) {
      if (payload?.erroAutorizacao) {
        yield payload?.erroAutorizacao()
      }
    } else {
      yield payload?.erro()
    }
  }
}
