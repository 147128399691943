import { Banco } from '@store/refinanciamento/dadosBancarios/types'
import { Cidade, Estado } from '@store/refinanciamento/endereco/types'

export const buildEstadosNovo = (estados: Estado[]) =>
  estados?.map(({ uf }) => ({ value: uf, text: uf }))

export const transformEstadoToOption = (estados: Estado[], estadoSelecionado: string) => {
  const estadoSelecionadoNovo = estados.find((estado) => estado.nome === estadoSelecionado)

  if (estadoSelecionadoNovo) {
    const optionEstadoSelecionado = {
      value: estadoSelecionadoNovo.nome,
      text: estadoSelecionadoNovo.nome,
    }
    return optionEstadoSelecionado
  }

  return { value: '', text: '' }
}

export const buildCidadesNovo = (cidades: Cidade[]) =>
  cidades?.map((cidade) => ({
    value: cidade.nome,
    text: cidade.nome,
  }))

export const transformCidadeToOption = (cidades: Cidade[], cidadeSelecionada: string) => {
  const cidadeSelecionadaNovo = cidades.find((cidade) => cidade.nome === cidadeSelecionada)

  if (cidadeSelecionadaNovo) {
    const optionCidadeSelecionada = {
      value: cidadeSelecionadaNovo.nome,
      text: cidadeSelecionadaNovo.nome,
    }

    return optionCidadeSelecionada
  }

  return { value: '', text: '' }
}

export const buildTipoEndereco = () => [
  {
    value: 'Rua',
    text: 'Rua',
  },
  {
    value: 'Avenida',
    text: 'Avenida',
  },
]

export const transformTipoEnderecoToOption = (
  lista: { value: string; text: string }[],
  tipoLogradouro: string,
) => {
  const tipoLogradouroSelecionado = lista.find((item) => item.value === tipoLogradouro)

  if (tipoLogradouroSelecionado) {
    const optionTipoLogradouroSelecionada = {
      value: tipoLogradouroSelecionado.value,
      text: tipoLogradouroSelecionado.text,
    }

    return optionTipoLogradouroSelecionada
  }

  return { value: '', text: '' }
}

export const buildBancosNovo = (bancos: Banco[]) =>
  bancos?.map(({ nome, codigo }) => ({ value: codigo, text: nome }))

export const transformBancoToOption = (lista: Banco[], bancoSelecionado: string) => {
  const bancoSelecionadoNovo = lista.find((item) => item.codigo === bancoSelecionado)

  if (bancoSelecionadoNovo) {
    const optionSelecionada = {
      value: bancoSelecionadoNovo.codigo,
      text: bancoSelecionadoNovo.nome,
    }

    return optionSelecionada
  }
  return { value: '', text: '' }
}

export const TiposContaNovo = [
  {
    value: '1',
    text: 'Conta Corrente',
  },
  {
    value: '2',
    text: 'Conta Poupança',
  },
]

export const transformTipoContaToOption = (
  lista: { value: string; text: string }[],
  tipoContaSelecionado: string,
) => {
  const tipoContaSelecionadoNovo = lista.find((item) => item.value === tipoContaSelecionado)

  if (tipoContaSelecionadoNovo) {
    const optionSelecionada = {
      value: tipoContaSelecionadoNovo.value,
      text: tipoContaSelecionadoNovo.text,
    }

    return optionSelecionada
  }

  return { value: '', text: '' }
}
