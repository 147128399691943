import { put, select } from 'typed-redux-saga'

import services from '@services'
import { ApplicationState } from '@store/types'
import { setPropostaAtualizada } from '@store/refinanciamento/documentosAssinatura/actions'
import { TipoEmprestimo } from '@utils/enums'
import { BridgeService } from '@services/bridge'
import { HttpStatus } from '@services/base'
import { showError } from '@store/ui/error/actions'
import { logout } from '@utils/functions'
import { ErroType } from '@utils/types'

import { hideLoading, setErroUpdateProposal, showLoading } from '../actions'

interface Props {
  noRemoveLoader: boolean
  type: string
}

export default function* postAtualizarPropostaSaga({ noRemoveLoader }: Props) {
  const appState = yield* select((state: ApplicationState) => state)
  try {
    yield put(showLoading())
    if (!appState.refinanciamento.documentosAssinatura.propostaAtualizada) {
      yield services.postAtualizarProposta(
        {
          simulacaoId: String(appState.refinanciamento.simulacao.simulacaoId),
          documentoId: Number(appState.refinanciamento.proposta.documentoId),
          possuiSeguro:
            appState.refinanciamento.simulacao.simulacaoSelecionada ===
            TipoEmprestimo.CREDITO_PROTEGIDO,
          tipoSimulacao: 'REFINANCIAMENTO',
        },
        appState.session.accessToken,
      )
      yield put(setPropostaAtualizada(true))
    }
    if (!noRemoveLoader) {
      yield put(hideLoading())
    }
    return true
  } catch (error) {
    const erroTyped = error as ErroType
    if (BridgeService.isBrowser() && erroTyped.status === HttpStatus.UNAUTHORIZED) {
      yield put(
        showError({
          title: 'Sessão expirada',
          message: 'Efetue o login novamente.',
          actionTitle: 'Continuar',
          functionCallback: () => logout(),
        }),
      )
      return false
    }
    yield put(setErroUpdateProposal(true))
    yield put(hideLoading())
    return false
  }
}
