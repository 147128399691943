/**
 * Actions Types
 */

export enum BiometriaTypes {
  SET_BASE64 = '@biometria/SET_BASE64',
  SET_ENCRYPTED = '@biometria/SET_ENCRYPTED',
  SET_ERROR = '@biometria/SET_ERROR',
  RESET_LIVENESS = '@biometria/RESET_LIVENESS',

  SET_UNICO_ID = '@biometria/SET_UNICO_ID',
  POST_FOTO_BIOMETRIA_LIVENESS = '@biometria/POST_FOTO_BIOMETRIA_LIVENESS',
  SET_LOADING_LIVENESS = '@biometria/SET_LOADING_LIVENESS',
  SET_RETRY_LIVENESS = '@biometria/SET_RETRY_LIVENESS',

  GET_LAST_LIVENESS = '@biometria/GET_LAST_LIVENESS',
  SET_LAST_LIVENESS = '@biometria/SET_LAST_LIVENESS',

  SET_BIOMETRIC_TOKEN = '@biometria/SET_BIOMETRIC_TOKEN',
  POST_BIOMETRICS_SAVE = '@biometria/POST_BIOMETRICS_SAVE',
  GET_VALIDATE_BIOMETRICS = '@biometria/GET_VALIDATE_BIOMETRICS',
  SET_LOADING_BIOMETRICS_VALIDATE = '@biometria/SET_LOADING_BIOMETRICS_VALIDATE',
  RESET = 'RESET',
}

export interface ILastLiveness {
  unicoId: string
  proposta: string
  status: string
  etapa: string
  dataCriacao: string
  score: number
}
/**
 * State Type
 */

export interface BiometriaState {
  readonly base64: string | undefined
  readonly encrypted: string | undefined
  readonly error: false
  readonly retryLiveness: false
  readonly loadingLiveness: false
  readonly unicoId: string
  readonly lastLiveness: ILastLiveness
  readonly biometricToken: string
  readonly loadingBiometricsValidate: boolean
}

export interface CallbackLastLiveness {
  callback: (lastLiveness: ILastLiveness) => void
}

export interface CallbackBiometria {
  base64: string
  encrypted: string
  callback: () => void
}
export interface CallbackBiometriaDados {
  callback: (biometricToken: string) => void
}

export interface CallbackBiometriaValidate {
  callback: (isValid: boolean) => void
}
