import { call, put, select } from 'typed-redux-saga'
import { AnyAction } from 'redux'

import { ApplicationState } from '@store/types'
import postSalvarProposta, { SalvarPropostaRequest } from '@services/postSalvarProposta'
import { showError } from '@store/ui/error/actions'
import { ErrorCodes } from '@utils/enums'
import { BridgeService } from '@services/bridge'
import { HttpStatus } from '@services/base'
import { logout } from '@utils/functions'
import { ErroType } from '@utils/types'

import { buildProposta } from './models'
import {
  salvarPropostaError,
  salvarPropostaRequest,
  salvarPropostaSuccess,
  setDocumentoId,
} from '../actions'
import { CallbackSalvarProposta } from '../types'

export default function* postSalvarPropostaSaga(action: AnyAction) {
  const payload = action.payload as CallbackSalvarProposta
  try {
    const state = yield* select((s: ApplicationState) => s)
    const proposta: SalvarPropostaRequest = buildProposta(state)
    const { data } = yield* call(postSalvarProposta, proposta, state.session.accessToken)
    yield put(setDocumentoId(data.documentoId))
    yield put(salvarPropostaSuccess())
    payload.callback()
  } catch (error) {
    const erroTyped = error as ErroType
    yield put(salvarPropostaError())
    if (BridgeService.isBrowser() && erroTyped.status === HttpStatus.UNAUTHORIZED) {
      yield put(
        showError({
          title: 'Sessão expirada',
          message: 'Efetue o login novamente.',
          actionTitle: 'Continuar',
          functionCallback: () => logout(),
        }),
      )
    } else {
      yield put(
        showError({
          title: 'Erro ao salvar',
          message: 'Ocorreu um erro ao salvar suas informações',
          actionTitle: 'Tentar Novamente',
          code: ErrorCodes.RE_008_SALVAR_PROPOSTA,
          actionCallback: () => salvarPropostaRequest(payload),
        }),
      )
    }
  }
}
