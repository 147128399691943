import React from 'react'

import { ParagraphProps } from './Props'
import P from './styles'

type Props = ParagraphProps & React.HtmlHTMLAttributes<HTMLParagraphElement>

const Paragraph: React.FC<Props> = ({ children, ...props }) => <P {...props}>{children}</P>

export default Paragraph
