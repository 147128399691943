import { BaseServices, HttpMethod } from '@services/base'

import { BridgeService } from './bridge'

export default async (biometricToken: string, accessToken?: string) => {
  const pathname = process.env.REACT_APP_BIOMETRICS_VALIDATE
  const method = HttpMethod.GET
  const host = process.env.REACT_APP_API_HOST
  const headers: Record<string, string> = {
    biometricToken,
  }
  if (BridgeService.isBrowser() && accessToken) {
    headers.Authorization = `Bearer ${accessToken}`
  }

  const response = await BaseServices.request<{ isValid: boolean }>({
    headers,
    pathname,
    method,
    host,
  })
  return response
}
