import React from 'react'

import { useSelector } from 'react-redux'
import { format } from 'date-fns'

import { TemplatesPDF } from '@interco/cp-react-ui-lib'
import { ApplicationState } from '@store/types'
import { Consignado, ConsignanteApi, ConsignantePdf } from '@utils/enums'
import { phoneMask } from '@utils/masks'

const ADF = () => {
  const { numeroProposta } = useSelector(
    (state: ApplicationState) => state.refinanciamento.proposta,
  )
  const { dadosProposta } = useSelector((state: ApplicationState) => state.refinanciamento.proposta)
  const telefone = `${dadosProposta.dddCelular}${dadosProposta.telefoneCelular}`
  const mesDesconto = dadosProposta?.primeiroDesconto
    ? new Date(dadosProposta.primeiroDesconto)
    : ''

  const variant = 'web'

  const consignante = () => {
    switch (dadosProposta.orgao) {
      case ConsignanteApi.SEF:
        return ConsignantePdf.SEF
      case ConsignanteApi.CBMMG:
        return ConsignantePdf.CBMMG
      case ConsignanteApi.IPSEMG:
        return ConsignantePdf.IPSEMG
      case ConsignanteApi.PMMG:
        return ConsignantePdf.PMMG
      case ConsignanteApi.IPSM:
        return ConsignantePdf.IPSM
      case ConsignanteApi.DPMG:
        return ConsignantePdf.DPMG
      default:
        return undefined
    }
  }

  const consignado = () => {
    switch (dadosProposta.orgao) {
      case ConsignanteApi.CBMMG || ConsignanteApi.PMMG:
        return Consignado.SERVIDOR
      case ConsignanteApi.IPSEMG || dadosProposta.orgao === ConsignanteApi.IPSM:
        return Consignado.PENSIONISTA
      default:
        return undefined
    }
  }

  return (
    <TemplatesPDF.AutorizacaoDesconto
      variant={variant}
      idConsignado={{
        nome: dadosProposta.nomeCliente,
        matricula: dadosProposta.matricula,
        cpf: dadosProposta.cpf,
        consignado: consignado(),
        consignante: consignante(),
        logradouro: dadosProposta.enderecoResidencial,
        numeroComplemento: dadosProposta.complementoResidencial
          ? `${dadosProposta.numeroResidencial}, ${dadosProposta.complementoResidencial}`
          : dadosProposta.numeroResidencial,
        cep: dadosProposta.cepResidencialComMascara,
        bairroDistrito: dadosProposta.bairroResidencial,
        municipio: dadosProposta.cidadeResidencial,
        uf: dadosProposta.estadoResidencial,
        telefone: phoneMask(telefone),
      }}
      idConsignacao={{
        numeroContrato: Number(numeroProposta),
        valorTotalSolicitado: dadosProposta.valorSolicitado,
        valorTotalFinanciado: dadosProposta.valorTotalASerPago,
        valorLiquidoPago: dadosProposta.valorSolicitado,
        jurosMensal: dadosProposta.taxaEfetivaMensal,
        qtdeParcelas: dadosProposta.quantidadeDeParcelas,
        valorParcela: dadosProposta.valorParcela,
        mesAnoInicioDesconto: mesDesconto ? format(mesDesconto, 'MMM/yyyy') : '',
        data: new Date(),
        isDgAssinado: true,
      }}
    />
  )
}

export default ADF
