import React from 'react'

import { BottomSheet } from '@interco/inter-ui/components/BottomSheet'
import { Button } from '@interco/inter-ui/components/Button'
import { ColorWrapper, TextParagraph } from '@atoms/TextParagraph'
import { Colors } from '@utils/Colors'

interface Props {
  setShowBottomSheet: React.Dispatch<React.SetStateAction<boolean>>
  variant: {
    title: string
    text: string
    button: string
  }
}
export const BottomSheetError = ({ setShowBottomSheet, variant }: Props) => (
  <BottomSheet onClose={() => setShowBottomSheet(false)}>
    <ColorWrapper color={Colors.GRAY500} fontWeight="600" margin="0">
      <TextParagraph variant="headline-h3">{variant.title}</TextParagraph>
    </ColorWrapper>

    <ColorWrapper color={Colors.GRAY500} margin="0 0 16px 0">
      <TextParagraph variant="body-3" margin="0 0 16px 0">
        {variant.text}
      </TextParagraph>
    </ColorWrapper>
    <Button fullWidth onClick={() => setShowBottomSheet(false)} style={{ marginTop: '14px' }}>
      {variant.button}
    </Button>
  </BottomSheet>
)
