import { SalvarPropostaRequest } from '@services/postSalvarProposta'
import { ApplicationState } from '@store/types'

export const buildProposta = ({
  refinanciamento,
  session,
}: ApplicationState): SalvarPropostaRequest => ({
  codigoConvenio: refinanciamento.contratos.convenioCodigo,
  clientId: String(session.sessionId || ''),
  cpf: String(refinanciamento.dadosPessoais.cpf || ''),
  escolaridade: '',
  profissao: '',
  valorPatrimonio: 0,
  pep: false,
  tin: false,
  banco: String(refinanciamento.dadosBancarios.bancoSelecionado || ''),
  agencia: String(refinanciamento.dadosBancarios.agencia || ''),
  conta: String(refinanciamento.dadosBancarios.conta || ''),
  paisNaturalidade: 'Brasil',
  estadoNaturalidade: String(refinanciamento.endereco.estadoSelecionado || ''),
  cidadeNaturalidade: String(refinanciamento.endereco.cidadeSelecionada || ''),
  cep: refinanciamento.endereco.cep,
  endereco: String(refinanciamento.endereco.logradouro || ''),
  numero: String(refinanciamento.endereco.numero || ''),
  complemento: String(refinanciamento.endereco.complemento || ''),
  bairro: String(refinanciamento.endereco.bairro || ''),
  estado: String(refinanciamento.endereco.estadoSelecionado || ''),
  cidade: String(refinanciamento.endereco.cidadeSelecionada || ''),
  termoAceite: true,
  numeroProposta: String(refinanciamento.proposta.numeroProposta || ''),
  tipoLiberacao: 1,
  ipCliente: '0.0.0.0',
  digitoConta: Number(refinanciamento.dadosBancarios.digito),
  simulacaoId: refinanciamento.simulacao.simulacaoId,
})

export const buildDocumentoId = ({ refinanciamento }: ApplicationState) => ({
  documentoId: String(refinanciamento.proposta.documentoId || ''),
})
