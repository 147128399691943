import { combineReducers } from 'redux'
import { persistReducer, persistStore } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import createSagaMiddleware from 'redux-saga'

import { configureStore } from '@reduxjs/toolkit'

import rootSaga from './rootSaga'
import ui from './ui'
import refinanciamento from './refinanciamento'
import session from './session'
import biometria from './biometria'

const sagaMiddleware = createSagaMiddleware()

export const reducers = combineReducers({
  ui: persistReducer({ key: 'ui', storage }, ui),
  refinanciamento: persistReducer({ key: 'refinanciamento', storage }, refinanciamento),
  biometria: persistReducer({ key: 'biometria', storage }, biometria),
  session: persistReducer({ key: 'session', storage }, session),
})

const store = configureStore({
  reducer: reducers,
  middleware: [sagaMiddleware],
  devTools: process.env.NODE_ENV !== 'production',
})

const persistor = persistStore(store)

sagaMiddleware.run(rootSaga)

export { store, persistor }
