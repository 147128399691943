export default () => (
  <svg width="64" height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="64" height="64" rx="32" fill="var(--alert500)" />
    <circle
      cx="31.9993"
      cy="32"
      r="13.3333"
      stroke="var(--neutral-theme)"
      strokeWidth="2.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M32 26V34"
      stroke="var(--neutral-theme)"
      strokeWidth="2.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <ellipse cx="31.9993" cy="38" rx="1.33333" ry="1.33333" fill="var(--neutral-theme)" />
  </svg>
)
