import React, { useCallback, useEffect, useState } from 'react'

import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

import { Text } from '@interco/inter-ui/components/Text'
import { Colors } from '@utils/Colors'
import { Page } from '@templates'
import { BridgeService } from '@services/bridge'
import { interWbSession } from '@interco/inter-webview-bridge'
import routes from '@routes/RefinV2/routes'
import Tag from '@utils/Tag'
import { buscarDocumentos } from '@store/refinanciamento/documentos/actions'
import { ConveniosCodes } from '@utils/enums'
import { validarRiscoEnderecoRequest } from '@store/refinanciamento/endereco/actions'
import { ApplicationState } from '@store/types'

import { FadeIn, LoadingComponent, TextContainer } from './styles'

interface PropsEnum {
  size: string
  bgColor: Colors
}

const enumLoading = {
  first: {
    size: '10%',
    bgColor: Colors.PRIMARY100,
  },
  second: {
    size: '30%',
    bgColor: Colors.PRIMARY200,
  },
  third: {
    size: '70%',
    bgColor: Colors.PRIMARY300,
  },
  fourth: {
    size: '100%',
    bgColor: Colors.PRIMARY400,
  },
}
const CarregamentoDados = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [enumLoad, setEnumLoad] = useState<PropsEnum>(enumLoading.first)
  const { convenioCodigo } = useSelector(
    (state: ApplicationState) => state.refinanciamento.contratos,
  )
  const { loading, erroUpdateProposal } = useSelector(
    (state: ApplicationState) => state.refinanciamento.documentos,
  )

  useEffect(() => {
    if (!BridgeService.isBrowser()) {
      interWbSession.editToolbar('Refinanciamento Consignado', true)
    }
  }, [])

  useEffect(() => {
    if (erroUpdateProposal) {
      navigate(routes.NAO_LIBERADO)
    }
  }, [erroUpdateProposal, navigate])

  const callBuscarDocs = useCallback(() => {
    if (!BridgeService.isBrowser()) {
      dispatch(
        buscarDocumentos({
          callback: () => {
            navigate(routes.STATUS_DOCUMENTO)
          },
        }),
      )
    } else if (convenioCodigo === ConveniosCodes.SIAPE_SERVIDOR_FEDERAL) {
      dispatch(
        validarRiscoEnderecoRequest({
          callback: (naoLiberado) => {
            if (naoLiberado) {
              navigate(routes.NAO_LIBERADO)
            } else {
              dispatch(
                buscarDocumentos({
                  callback: () => {
                    navigate(routes.STATUS_DOCUMENTO)
                  },
                }),
              )
            }
          },
        }),
      )
    } else {
      dispatch(
        buscarDocumentos({
          callback: () => {
            navigate(routes.STATUS_DOCUMENTO)
          },
        }),
      )
    }
  }, [convenioCodigo, dispatch, navigate])

  useEffect(() => {
    Tag.enviarTagueamentoWeb({
      element_name: '',
      section_name: 'Carregamento de dados',
      element_action: 'page_loaded',
      c_page: window.location.href,
      redirect_url: '',
      step: 'null',
    })
  }, [])

  useEffect(() => {
    const intervalId = setTimeout(() => {
      setEnumLoad(enumLoading.second)
    }, 2000)
    const intervalId2 = setTimeout(() => {
      setEnumLoad(enumLoading.third)
    }, 3000)
    const intervalId3 = setTimeout(() => {
      setEnumLoad(enumLoading.fourth)
    }, 6000)
    const intervalId4 = setTimeout(() => {
      callBuscarDocs()
    }, 11000)

    return () => {
      clearTimeout(intervalId)
      clearTimeout(intervalId2)
      clearTimeout(intervalId3)
      clearTimeout(intervalId4)
    }
  }, [callBuscarDocs, navigate])

  return (
    <Page
      footerTwoButtonsSpace="200px"
      stickyFooter={
        loading ? (
          <>
            <TextContainer size={enumLoad.size}>
              <FadeIn>
                <Text variant="headline-h1" colorWeight={500}>
                  Lembre-se: não solicitamos a devolução do valor depositado
                </Text>
              </FadeIn>
            </TextContainer>
            <LoadingComponent
              size={enumLoading.fourth.size}
              backgroundColor={enumLoading.fourth.bgColor}
            />
          </>
        ) : (
          <>
            <TextContainer size={enumLoad.size}>
              <FadeIn>
                <Text variant="headline-h1" colorWeight={500}>
                  Lembre-se: não solicitamos a devolução do valor depositado
                </Text>
              </FadeIn>
            </TextContainer>
            <LoadingComponent size={enumLoad.size} backgroundColor={enumLoad.bgColor} />
          </>
        )
      }
    >
      <div />
    </Page>
  )
}
export default CarregamentoDados
