import React from 'react'

import { useSelector } from 'react-redux'

import { ApplicationState } from '@store/types'
import { Colors } from '@utils/Colors'
import { ColorWrapper, TextParagraph } from '@atoms/TextParagraph'
import { Button } from '@interco/inter-ui/components/Button'

import {
  CustomGrid,
  LeftButton,
  LineButtons,
  LineButtonsWeb,
  LineDescription,
  PageImage,
  RightButton,
  VisualizarFoto,
  RootPage,
} from './styles'

interface ReviewProps {
  fotografia: string
  setHabilitaCamera: () => void
  onConfirm: (arg: string) => void
  isLoading: boolean
  forceIsMobile: boolean
}

interface ReviewPropsComp {
  fotografia: string
  setHabilitaCamera: () => void
  onConfirm: (arg: string) => void
  isLoading: boolean
}

const MobileView = ({ fotografia, setHabilitaCamera, onConfirm, isLoading }: ReviewPropsComp) => (
  <PageImage
    id="foto-review"
    isApp
    hideHeader
    hasProgressStep={false}
    fotografia={fotografia}
    stickyFooter={
      <>
        <LineDescription>
          <ColorWrapper color={Colors.WHITE} fontWeight="600" style={{ textAlign: 'center' }}>
            <TextParagraph variant="headline-h3">
              Sua foto ficou nítida e bem iluminada?
            </TextParagraph>
          </ColorWrapper>
          <ColorWrapper color={Colors.WHITE} style={{ textAlign: 'center' }}>
            <TextParagraph variant="body-3">Lembre-se de que não pode ter reflexos.</TextParagraph>
          </ColorWrapper>
        </LineDescription>
        <LineButtons>
          <LeftButton
            size="small"
            fullWidth
            variant="secondary"
            onClick={() => {
              setHabilitaCamera()
            }}
            disabled={isLoading}
          >
            Tirar outra
          </LeftButton>
          <div style={{ width: '16px' }} />
          <RightButton
            size="small"
            variant="primary"
            fullWidth
            onClick={() => {
              onConfirm(fotografia)
            }}
            disabled={isLoading}
            isLoading={isLoading}
          >
            Usar esta
          </RightButton>
        </LineButtons>
      </>
    }
  >
    <CustomGrid>
      <ColorWrapper color={Colors.WHITE} fontWeight="600" style={{ textAlign: 'center' }}>
        <TextParagraph variant="headline-h3">Revisar foto</TextParagraph>
      </ColorWrapper>
    </CustomGrid>
  </PageImage>
)

const WebView = ({ fotografia, setHabilitaCamera, onConfirm, isLoading }: ReviewPropsComp) => (
  <RootPage id="review-web" isApp={false} hideHeader={false} hasProgressStep={false} fullPage>
    <LineDescription>
      <ColorWrapper color={Colors.GRAY500} fontWeight="600" style={{ textAlign: 'left' }}>
        <TextParagraph variant="headline-h3">Sua foto ficou nítida e bem iluminada?</TextParagraph>
      </ColorWrapper>
      <ColorWrapper color={Colors.GRAY500} style={{ textAlign: 'left' }}>
        <TextParagraph variant="body-3">Lembre-se de que não pode ter reflexos.</TextParagraph>
      </ColorWrapper>
    </LineDescription>
    <VisualizarFoto src={fotografia} alt="Fotografia" />
    <LineButtonsWeb>
      <Button
        variant="secondary"
        fullWidth
        onClick={() => {
          setHabilitaCamera()
        }}
        disabled={isLoading}
        size="small"
      >
        Tirar outra
      </Button>
      <div style={{ width: '24px' }} />
      <Button
        fullWidth
        variant="primary"
        onClick={() => {
          onConfirm(fotografia)
        }}
        disabled={isLoading}
        isLoading={isLoading}
        size="small"
      >
        Usar esta
      </Button>
    </LineButtonsWeb>
  </RootPage>
)

export const ReviewFoto = ({
  fotografia,
  setHabilitaCamera,
  onConfirm,
  isLoading,
  forceIsMobile,
}: ReviewProps) => {
  const { isApp } = useSelector((state: ApplicationState) => state.ui.navigation)

  if (isApp || forceIsMobile) {
    return (
      <MobileView
        fotografia={fotografia}
        setHabilitaCamera={setHabilitaCamera}
        onConfirm={onConfirm}
        isLoading={isLoading}
      />
    )
  }

  return (
    <WebView
      fotografia={fotografia}
      setHabilitaCamera={setHabilitaCamera}
      onConfirm={onConfirm}
      isLoading={isLoading}
    />
  )
}
