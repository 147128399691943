import React from 'react'

import { useSelector } from 'react-redux'

import { cpfValue, formatCurrency } from '@interco/cp-react-ui-lib'
import { ApplicationState } from '@store/types'
import { TemplatesPDF } from '@interco/cp-react-ui-lib'
import { CCBEnum } from '@utils/enums'
import { DateFormat } from '@utils/functions'

import { TextCCBExercito } from './Clausulas'

const CCBExercito = () => {
  const { numeroProposta } = useSelector(
    (state: ApplicationState) => state.refinanciamento.proposta,
  )

  const { dadosProposta } = useSelector((state: ApplicationState) => state.refinanciamento.proposta)

  const variant = 'web'

  return (
    <TemplatesPDF.CedulaCreditoBancario
      variant={variant}
      typeCCB={CCBEnum.CCBEXERCITO}
      header={{
        numDoc: String(numeroProposta),
      }}
      emitente={{
        cpf: cpfValue(dadosProposta?.cpf),
        nome: dadosProposta?.nomeCliente,
        dataNascimento: DateFormat(new Date(dadosProposta?.dataNascimento), 'dd/MM/yyyy') || '',
        estadoCivil: dadosProposta?.estadoCivil,
        documentoIdentificacao: dadosProposta?.documentoIdentificacao,
        endereco: `${dadosProposta?.enderecoResidencial}, ${dadosProposta?.numeroResidencial} - ${dadosProposta?.bairroResidencial} - ${dadosProposta?.cidadeResidencial}/${dadosProposta?.estadoResidencial}`,
        naturalidade: dadosProposta?.cidadeResidencial,
        nacionalidade: dadosProposta?.nacionalidade,
        nomeMae: dadosProposta?.nomeMae,
        nomePai: dadosProposta?.nomePai,
        nomeConjuge: dadosProposta?.nomeConjuge,
        cpfConjuge: '',
        banco: dadosProposta?.banco,
        agencia: dadosProposta?.agencia,
        contaCorrente: dadosProposta?.conta,
      }}
      demonstrativoCalculo={{
        valorTotal: dadosProposta?.valorTotalASerPago,
        taxaJurosMensal: `${dadosProposta?.taxa}%`,
        taxaJurosAnual: `${dadosProposta?.taxaNominalAnual}%`,
        valorSolicitado: formatCurrency(Number(dadosProposta?.valorSolicitado)) || 'R$: 0,00',
        percentualValorSolicitado: `${dadosProposta?.valorSolicitadoPercentual}%`,
        cetMensal: `${dadosProposta?.atributosCetMensal}%`,
        cetAnual: `${dadosProposta?.atributosCetAnual}%`,
        tarifaCadastro: formatCurrency(Number(dadosProposta?.tarifaCadastro)) || 'R$: 0,00',
        percentualTarifaCadastro: `${dadosProposta?.taxaTarifaCadastro}%`,
        dataEmissao: DateFormat(new Date(dadosProposta?.dataEmissao), 'dd/MM/yyyy') || '',
        iof: formatCurrency(Number(dadosProposta?.valorIOF)),
        percentualIof: `${dadosProposta?.percentualIOF}%`,
        liberacaoCredito: `${dadosProposta?.tipoLiberacao}`,
        jurosContratados: formatCurrency(Number(dadosProposta?.jurosContratados)),
        percentualJurosContratados: `${dadosProposta?.jurosContratadosPercentual}%`,
        valorMutuo: formatCurrency(Number(dadosProposta?.valorDoMutuo)),
        pracaPagamento: `${dadosProposta?.cidadeResidencial}/${dadosProposta?.estadoResidencial}`,
        valorSeguro: formatCurrency(Number(dadosProposta?.valorTotalDestinadoPagamentoSeguro)),
        percentualValorSeguro: `${dadosProposta?.percentualPropostaSeguroPrestamista}%`,
        seguro: dadosProposta?.possuiSeguro ? 'Sim' : 'Não',
        matricula: dadosProposta?.matricula,
        qtdeParcelasMensais: dadosProposta?.quantidadeDeParcelas,
        valorParcelaMensal: formatCurrency(Number(dadosProposta?.valorParcela)),
        jurosCapitalizados: dadosProposta?.jurosCapitalizados,
        previsaoPrimeiroDesconto:
          DateFormat(new Date(dadosProposta?.primeiroDesconto), 'dd/MM/yyyy') || '',
        previsaoUltimoDesconto:
          DateFormat(new Date(dadosProposta?.ultimoDesconto), 'dd/MM/yyyy') || '',
        textAcordo:
          '2.23 -  O (A) EMITENTE declara estar ciente e de acordo que, na hipótese de haver divergência no valor do contrato negociado, por motivo de indisponibilidade de margem integral para averbação do mútuo, o CREDOR poderá realizar a operação nas novas condições. Dessa forma, as condições solicitadas nessa proposta podem ser alteradas em razão do limite disponível de margem e, havendo alteração, o(a) EMITENTE concorda com a variação. Assim que a operação for aprovada, o(a) EMITENTE receberá uma comunicação do CREDOR detalhando as condições pactuadas e, caso haja discordância, o(a) EMITENTE  poderá solicitar o cancelamento em até 7 dias.',
      }}
      textoAutorizacao={{ text: TextCCBExercito }}
      assinaturaAnalfabeto={{
        isDgAssinado: true,
        cidade: 'Belo Horizonte',
        data: new Date(),
      }}
      footer={{
        atualizado: new Date('15/08/2022'),
        codigo: 'CP083',
        edicao: '6º',
        contatos: [
          'www.bancointer.com.br - Central de Atendimento 3003-4070 (Capitais e Regiões Metropolitanas)',
          'SAC 0800 940 9999 - Ouvidoria 0800 940 7772 - Deﬁciente Fala e Audição 0800 979 7099',
        ],
      }}
    />
  )
}

export default CCBExercito
