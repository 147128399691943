import { action, Action } from 'typesafe-actions'

import {
  ConsultaMargemInssPayload,
  ConsultaMargemInssTypes,
  InfoInssType,
  StartPayload,
} from './types'

export const consultaMargemInssRequest = (payload: ConsultaMargemInssPayload): Action =>
  action(ConsultaMargemInssTypes.CONSULTA_MARGEM_INSS_REQUEST, payload)

export const consultaMargemInssSuccess = (payload: number): Action =>
  action(ConsultaMargemInssTypes.CONSULTA_MARGEM_INSS_SUCCESS, payload)

export const consultaMargemInssError = (): Action =>
  action(ConsultaMargemInssTypes.CONSULTA_MARGEM_INSS_ERROR)

export const setInfoInssDadosBancarios = (payload: InfoInssType): Action =>
  action(ConsultaMargemInssTypes.SET_INFO_INSS_DADOS_BANCARIOS, payload)

export const inssStartAsync = (payload: StartPayload): Action =>
  action(ConsultaMargemInssTypes.DADOS_INSS_START_ASYNC, payload)

export const setLoadingInssApis = (payload: boolean): Action =>
  action(ConsultaMargemInssTypes.SET_LOADING_INSS_APIS, payload)

export const setRecallInss = (payload: number): Action =>
  action(ConsultaMargemInssTypes.SET_COUNTER_RECALL, payload)
