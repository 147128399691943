import React, { useCallback, useEffect, useState } from 'react'

import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'

import { Separator } from '@atoms/Separator'
import { Button } from '@interco/inter-ui/components/Button'
import { ApplicationState } from '@store/types'
import { validaEnvHomeByBridge } from '@utils/functions'
import { BridgeService } from '@services/bridge'
import { interWbSession, IWbTac } from '@interco/inter-webview-bridge'
import { Page } from '@templates'
import { clearStore } from '@store/refinanciamento/documentos/actions'
import { GridStyled } from '@atoms'
import { ErrorIcon, SuccessIcon } from '@icons/Signals'
import { ColorWrapper, TextParagraph } from '@atoms/TextParagraph'
import { Colors } from '@utils/Colors'
import WaitingIcon from '@icons/Signals/WaitingIcon'
import Tag from '@utils/Tag'

import { TimelineCustom } from './styles'

const tacConfirmarContratacao = () => {
  const tacProps: IWbTac = {
    name: 'tela_sucesso',
    module: 'refinanciamento_consignado',
    actions: ['confirmar_contratacao'],
    params: {},
  }

  interWbSession.tac(tacProps)
}

const Sucesso = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [errorPage, setErrorPage] = useState(false)
  const { isApp, origemGoBack } = useSelector((state: ApplicationState) => state.ui.navigation)
  const { convenioCodigo } = useSelector(
    (state: ApplicationState) => state.refinanciamento.contratos,
  )

  const finish = useCallback(() => {
    dispatch(clearStore())
    setErrorPage(false)
  }, [dispatch])

  useEffect(() => {
    tacConfirmarContratacao()
    finish()
  }, [finish])

  useEffect(() => {
    if (!BridgeService.isBrowser()) {
      interWbSession.editToolbar('Refinanciamento Consignado', true)
    }
  }, [])

  useEffect(() => {
    Tag.enviarTagueamentoApp({
      name: 'CONSIGNADO_REFIN_SUCESSO',
      ref_figma: '5',
      tela: 'Refinanciamento solicitado',
      ref_type: 'sucesso',
      content_action: 'Dado carregado',
      content_name: 'Ja estamos validando seus dados',
      product: 'CONSIGNADO_REFIN_INSS',
    })
    Tag.enviarTagueamentoWeb({
      element_name: 'Ja estamos validando seus dados',
      section_name: 'Refinanciamento solcitiado',
      element_action: 'page_loaded',
      c_page: window.location.href,
      redirect_url: '',
      step: 'null',
    })
  }, [convenioCodigo])

  if (errorPage) {
    return (
      <Page
        stickyContainerFooterBottom="30px"
        positionFooter="sticky"
        stickyFooter={
          <>
            <Button
              variant="primary"
              onClick={() => {
                navigate(-1)
              }}
            >
              Tentar novamente
            </Button>
            <Button
              variant="secondary"
              onClick={() => {
                window.location.href = validaEnvHomeByBridge(String(origemGoBack), isApp) || ''
              }}
            >
              Voltar para home
            </Button>
          </>
        }
      >
        <GridStyled justify="space-around">
          <ErrorIcon />
        </GridStyled>
        <ColorWrapper
          color={Colors.GRAY500}
          fontWeight="600"
          margin="32px 0 16px 0"
          style={{ textAlign: 'center' }}
        >
          <TextParagraph variant="headline-h1">Encontramos um erro</TextParagraph>
        </ColorWrapper>

        <TextParagraph variant="body-3" margin="10px 0">
          Mas fique tranquilo, estamos resolvendo e logo mais você poderá dar continuidade no
          processo de contratação.
        </TextParagraph>
      </Page>
    )
  }

  return (
    <Page
      stickyContainerFooterBottom="30px"
      positionFooter="sticky"
      stickyFooter={
        isApp ? (
          <>
            <Button
              onClick={() => {
                window.location.href = validaEnvHomeByBridge(String(origemGoBack), isApp) || ''
              }}
            >
              Ir para a home
            </Button>
            <Button
              variant="secondary"
              onClick={() => {
                if (isApp) {
                  window.location.href = String(
                    process.env.REACT_APP_HOME_EMPRESTIMO_PROPOSTAS_CONTRATOS,
                  )
                } else {
                  window.location.href = String(process.env.REACT_APP_HOME_EMPRESTIMO_NOVO)
                }
              }}
            >
              Acompanhar proposta
            </Button>
          </>
        ) : (
          <Button
            onClick={() => {
              window.location.href = process.env.REACT_APP_CONTA_DIGITAL || ''
            }}
          >
            Abra sua conta
          </Button>
        )
      }
    >
      <GridStyled justify="space-around">
        <SuccessIcon />
      </GridStyled>
      <ColorWrapper
        color={Colors.GRAY500}
        fontWeight="600"
        margin="22px 0 16px 0"
        style={{ textAlign: 'center' }}
      >
        <TextParagraph variant="headline-h1">Contração solicitada!</TextParagraph>
      </ColorWrapper>
      <Separator margin="24px 0" variant="small" style={{ backgroundColor: Colors.GRAY100 }} />
      <ColorWrapper color={Colors.GRAY500} fontWeight="600" margin="0 0 16px 0">
        <TextParagraph variant="headline-h3" textAlign="center">
          O que acontece a partir de agora:
        </TextParagraph>
      </ColorWrapper>
      <TimelineCustom>
        <TimelineCustom.Item
          title="Já estamos validando seus dados"
          type="Alert"
          icon={
            <div style={{ fill: 'white !important' }}>
              <WaitingIcon />
            </div>
          }
        >
          <TextParagraph variant="body-3" style={{ marginTop: '14px' }}>
            Estando tudo certo, te avisaremos por notificação no seu celular e por e-mail.
          </TextParagraph>
        </TimelineCustom.Item>
        <TimelineCustom.Item title="O troco é liberado em sua conta" type="Disabled" />
      </TimelineCustom>
      {!isApp ? (
        <>
          <Separator margin="24px -24px" style={{ backgroundColor: Colors.GRAY100 }} />
          <ColorWrapper fontWeight="600">
            <TextParagraph variant="headline-h1">Abra sua conta</TextParagraph>
          </ColorWrapper>
          <ColorWrapper fontWeight="600" margin="0 0 16px 0">
            <TextParagraph variant="headline-h3">Gratuita, simples e completa</TextParagraph>
          </ColorWrapper>
          <ColorWrapper color={Colors.GRAY500} margin="0 0 32px 0">
            <TextParagraph variant="caption-1">
              Simplifique toda a sua vida com uma conta digital completa e um Super App com
              shopping, investimento, recarga e muito mais!
            </TextParagraph>
          </ColorWrapper>
        </>
      ) : (
        <></>
      )}
    </Page>
  )
}
export default Sucesso
