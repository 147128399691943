import * as React from 'react'

import { useSelector } from 'react-redux'

import { ApplicationState } from '@store/types'
import { ProfileHeader } from '@organisms'

import { Container, Content, ContainerFooter } from './styles'

interface Props {
  children: React.ReactElement | React.ReactElement[]
  stickyFooter?: React.ReactElement | React.ReactElement[]
  footerTwoButtonsSpace?: string
  stickyContainerFooterBottom?: string
  positionFooter?: string
}

const Page = ({
  children,
  stickyFooter,
  footerTwoButtonsSpace = '120px',
  stickyContainerFooterBottom,
  positionFooter,
}: Props) => {
  const { isApp } = useSelector((state: ApplicationState) => state.ui.navigation)
  return (
    <>
      <Container id="page-container">
        {!isApp && <ProfileHeader />}
        <Content isWeb={!isApp} id="page-content" footerTwoButtonsSpace={footerTwoButtonsSpace}>
          {children}
        </Content>
        {stickyFooter && (
          <ContainerFooter
            id="page-sticky-footer"
            stickyContainerFooterBottom={stickyContainerFooterBottom}
            positionFooter={positionFooter}
          >
            {stickyFooter}
          </ContainerFooter>
        )}
      </Container>
    </>
  )
}
export default Page
