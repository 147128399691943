import React, { useEffect, useState } from 'react'

import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'

import { IWbISafeResponse, IWbTac, IWbTic, interWbSession } from '@interco/inter-webview-bridge'
import { ApplicationState } from '@store/types'
import { ColorWrapper, TextParagraph } from '@atoms/TextParagraph'
import { Separator } from '@atoms/Separator'
import { Button } from '@interco/inter-ui/components/Button'
import { ISafeBottomSheet } from '@interco/inter-ui/components/BottomSheet'
import { BridgeService } from '@services/bridge'
import { formatCurrency } from '@utils/masks'
import { finalizarProposta } from '@store/refinanciamento/documentosAssinatura/actions'
import routes from '@routes/RefinV2/routes'
import { postIsafeAutorizacao } from '@store/refinanciamento/valida/actions'
import { Page } from '@templates'
import IcChevronRight from '@interco/icons/core/action-navigation/ic_chevron_right'
import IcContract from '@interco/icons/core/files/ic_contract'
import IcChevronUp from '@interco/icons/core/action-navigation/ic_chevron_up'
import IcChevronDown from '@interco/icons/core/action-navigation/ic_chevron_down'
import { Colors } from '@utils/Colors'
import { StepRefin } from '@utils/enums'
import { trackingRefin } from '@store/refinanciamento/simulacao/actions'
import Tag from '@utils/Tag'
import { getValidateBiometrics, postBiometriaDadoSave } from '@store/biometria/actions'

import { BoxCustom, LineInfo } from './styles'
import { variants } from './Variants'
import { BottomSheetError } from './Bottomsheets/BottomSheetError'
import Detalhes from './Detalhes'
import { BottomSheetRefazerBiometria } from './Bottomsheets/BottomSheetRefazerBiometria'

const tacBiometria = () => {
  const tacProps: IWbTac = {
    name: 'resultado_biometria',
    module: 'refinanciamento_consignado',
    actions: ['continuar_refinanciamento_biometria'],
    params: {},
  }

  interWbSession.tac(tacProps)
}

const ticBotaoConfirmarContratacao = () => {
  const ticProps: IWbTic = {
    name: 'btn_confirmar_contratacao',
    module: 'refinanciamento_consignado',
    action: 'confirmar_contratacao',
    params: {},
  }

  interWbSession.tic(ticProps)
}

const Revisao = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [showDetails, setShowDetails] = useState(false)
  const [variant, setVariant] = useState(variants.autenticacaoRealizada)
  const [showBottomSheet, setShowBottomSheet] = useState(false)
  const [showBottomSheetRefazerBiometria, setShowBottomSheetRefazerBiometria] = useState(false)
  const [showISafe, setShowISafe] = useState(false)
  const { parcelaAtual, debtBalance } = useSelector(
    (state: ApplicationState) => state.refinanciamento.simulacao,
  )
  const { contratosSelecionados, convenioCodigo } = useSelector(
    (state: ApplicationState) => state.refinanciamento.contratos,
  )
  const { isApp, origem } = useSelector((state: ApplicationState) => state.ui.navigation)
  const { conta } = useSelector((state: ApplicationState) => state.refinanciamento.dadosPessoais)
  const { loadingBiometricsValidate } = useSelector((state: ApplicationState) => state.biometria)
  const { accessToken } = useSelector((state: ApplicationState) => state.session)
  const { loadingFinalizarProposta } = useSelector(
    (state: ApplicationState) => state.refinanciamento.documentosAssinatura,
  )

  let isafeResponse: IWbISafeResponse | undefined

  useEffect(() => {
    tacBiometria()
    if (!BridgeService.isBrowser()) {
      interWbSession.editToolbar('Revisão do contrato', true)
    }
  }, [])

  useEffect(() => {
    dispatch(
      getValidateBiometrics({
        callback: (isValid) => {
          if (!isValid) {
            setShowBottomSheetRefazerBiometria(true)
          } else {
            dispatch(trackingRefin(StepRefin.CONCLUSAO_ENVIO_DOCUMENTOS))
          }
        },
      }),
    )
  }, [dispatch])

  const callFinalizar = () => {
    dispatch(
      finalizarProposta({
        callback: () => {
          dispatch(trackingRefin(StepRefin.PROPOSTA_FINALIZADA))
          Tag.enviarTagueamentoWeb({
            element_name: 'Confirmar contratação',
            section_name: 'Revisão',
            element_action: 'click button',
            c_page: window.location.href,
            redirect_url: `${window.location.origin}${routes.SUCESSO}`,
            step: 'null',
          })
          navigate(routes.SUCESSO)
        },
        callbackTryAgain: () => {
          Tag.enviarTagueamentoWeb({
            element_name: 'Confirmar contratação',
            section_name: 'Revisão',
            element_action: 'click button',
            c_page: window.location.href,
            redirect_url: `${process.env.REACT_APP_BIOMETRIA_MODULO}`,
            step: 'null',
          })
          setShowBottomSheetRefazerBiometria(true)
        },
      }),
    )
  }

  const onAuthenticateComplete = async (status: boolean) => {
    if (!BridgeService.isBrowser()) {
      isafeResponse = await BridgeService?.authenticateRequestISafe(
        Number(parcelaAtual.availableAmount),
      )
    }
    if (status && isafeResponse) {
      const { authenticationToken, authenticationType, authenticationValue } = isafeResponse
      dispatch(
        postIsafeAutorizacao({
          callback: () => {
            setShowISafe(false)
            dispatch(trackingRefin(StepRefin.REVISAO_ASSINATURA))
            callFinalizar()
          },
          erro: () => {
            setShowISafe(false)
            setVariant(variants.erroAutenticacao)
            setShowBottomSheet(true)
          },
          erroAutorizacao: () => {
            setShowISafe(false)
            setVariant(variants.erroAutorizacao)
            setShowBottomSheet(true)
          },
          tipoAutenticacao: `${authenticationType || ''}`,
          token: `${authenticationToken || ''}`,
          valor: `${authenticationValue || ''}`,
          conta,
        }),
      )
    } else {
      setShowISafe(false)
      setVariant(variants.erroAutenticacao)
      setShowBottomSheet(true)
    }
  }

  const iSafeAuthenticate = async () => Promise.resolve()

  const contratar = () => {
    ticBotaoConfirmarContratacao()
    if (isApp || !BridgeService.isBrowser()) {
      setShowISafe(true)
    } else {
      dispatch(trackingRefin(StepRefin.REVISAO_ASSINATURA))
      callFinalizar()
    }
  }

  return (
    <Page
      stickyContainerFooterBottom="0px"
      positionFooter="sticky"
      stickyFooter={
        <>
          <Separator margin="8px -24px" height="1px" />
          {showDetails ? (
            <>
              <div
                tabIndex={0}
                role="button"
                onKeyDown={() => {
                  setShowDetails(false)
                }}
                onClick={() => {
                  setShowDetails(false)
                }}
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignSelf: 'center',
                }}
              >
                <ColorWrapper fontWeight="700" color={Colors.PRIMARY500} margin="0 8px 0 0">
                  <TextParagraph variant="body-3">Exibir menos detalhes</TextParagraph>
                </ColorWrapper>
                <IcChevronDown width={24} height={24} color={Colors.PRIMARY500} />
              </div>
              <Detalhes />
            </>
          ) : (
            <div
              tabIndex={0}
              role="button"
              onKeyDown={() => {
                setShowDetails(true)
              }}
              onClick={() => {
                setShowDetails(true)
              }}
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignSelf: 'center',
              }}
            >
              <ColorWrapper fontWeight="700" color={Colors.PRIMARY500} margin="0 8px 0 0">
                <TextParagraph variant="body-3">Exibir mais detalhes</TextParagraph>
              </ColorWrapper>
              <IcChevronUp width={24} height={24} color={Colors.PRIMARY500} />
            </div>
          )}
          <ColorWrapper margin="0 0 8px 0" color={Colors.GRAY500}>
            <TextParagraph variant="caption-1">
              Ao clicar em confirmar contratação, eu reconheço que li e concordo com os termos
              acima.
            </TextParagraph>
          </ColorWrapper>

          <Button
            disabled={
              loadingFinalizarProposta ||
              loadingBiometricsValidate ||
              showBottomSheetRefazerBiometria
            }
            isLoading={loadingBiometricsValidate || loadingFinalizarProposta}
            fullWidth
            onClick={() => contratar()}
          >
            {loadingBiometricsValidate ? 'Validando biometria enviada...' : 'Confirmar contratação'}
          </Button>
        </>
      }
    >
      <ColorWrapper fontWeight="600" margin="24px 0 0 0" style={{ textAlign: 'center' }}>
        <TextParagraph variant="headline-h1" margin="0" textAlign="center">
          Para finalizar, toque em Confirmar contratação
        </TextParagraph>
      </ColorWrapper>
      <ColorWrapper
        fontWeight="600"
        style={{
          width: '60%',
          textAlign: 'center',
          borderBottom: `1px solid ${Colors.GRAY300}`,
          alignSelf: 'center',
        }}
        margin="24px 0 0 0"
      >
        <TextParagraph variant="headline-h1" margin="0" textAlign="center">
          {formatCurrency(parcelaAtual?.requestedAmount)}
        </TextParagraph>
      </ColorWrapper>
      <TextParagraph textAlign="center" variant="caption-1" margin="8px 0 0 0">
        Valor do refinanciamento
      </TextParagraph>
      <Separator margin="24px 0" variant="small" style={{ backgroundColor: Colors.GRAY100 }} />
      <ColorWrapper fontWeight="600">
        <TextParagraph variant="headline-h3" margin="8px 0">
          Detalhes do refinanciamento
        </TextParagraph>
      </ColorWrapper>
      <LineInfo>
        <TextParagraph variant="caption-1">Saldo devedor pré-refinanciamento</TextParagraph>
        <ColorWrapper color={Colors.GRAY500} fontWeight="700" margin="0">
          <TextParagraph variant="caption-1" margin="0">
            {formatCurrency(debtBalance)}
          </TextParagraph>
        </ColorWrapper>
      </LineInfo>
      <LineInfo>
        <TextParagraph variant="caption-1">Troco liberado</TextParagraph>
        <ColorWrapper color={Colors.GRAY500} fontWeight="700" margin="0">
          <TextParagraph variant="caption-1" margin="0">
            {formatCurrency(parcelaAtual?.availableAmount)}
          </TextParagraph>
        </ColorWrapper>
      </LineInfo>
      <LineInfo>
        <TextParagraph variant="caption-1" style={{ maxWidth: '55%' }}>
          Novo valor disponível
        </TextParagraph>
        <ColorWrapper color={Colors.GRAY500} fontWeight="700" margin="0">
          <TextParagraph variant="caption-1" margin="0">
            {formatCurrency(parcelaAtual?.requestedAmount)}
          </TextParagraph>
        </ColorWrapper>
      </LineInfo>

      <Separator margin="8px 0 24px 0" height="1px" />

      <ColorWrapper color={Colors.GRAY500} fontWeight="700" margin="0 0 24px 0">
        <TextParagraph variant="body-3">Contratos selecionados</TextParagraph>
      </ColorWrapper>
      <>
        {contratosSelecionados?.map((contrato, index) => (
          <div key={`${contrato.numeroProposta}`}>
            {index !== 0 ? <Separator variant="small" margin="16px 0" /> : <></>}
            <LineInfo>
              <TextParagraph variant="caption-1">Valor da parcela:</TextParagraph>
              <TextParagraph variant="caption-1" colorWeight={500} bold>
                {formatCurrency(contrato.valorProximaParcela)}
              </TextParagraph>
            </LineInfo>
            <LineInfo style={{ marginBottom: '0px' }}>
              <TextParagraph variant="caption-1">Nº da proposta:</TextParagraph>
              <TextParagraph variant="caption-1" colorWeight={500} bold>
                {contrato.numeroProposta}
              </TextParagraph>
            </LineInfo>
          </div>
        ))}
      </>
      <BoxCustom
        onClick={() => {
          navigate(routes.TERMOS_CONTRATACAO)
        }}
        actived={false}
      >
        <IcContract height={24} width={24} color={Colors.GRAY500} />
        <TextParagraph
          variant="body-3"
          style={{
            width: '70%',
            color: Colors.GRAY500,
            fontWeight: 700,
            alignContent: 'flex-start',
          }}
        >
          Termos da contratação
        </TextParagraph>
        <IcChevronRight height={24} width={24} color={Colors.PRIMARY500} />
      </BoxCustom>

      {showISafe ? (
        <ISafeBottomSheet
          authenticate={iSafeAuthenticate}
          onComplete={onAuthenticateComplete}
          onClose={() => setShowISafe(false)}
          description="Para sua segurança estamos autenticando sua transação com o i-safe."
        />
      ) : (
        <></>
      )}
      {showBottomSheet ? (
        <BottomSheetError setShowBottomSheet={setShowBottomSheet} variant={variant} />
      ) : (
        <></>
      )}
      {showBottomSheetRefazerBiometria ? (
        <BottomSheetRefazerBiometria
          setShowBottomSheetRefazerBiometria={setShowBottomSheetRefazerBiometria}
          redirectBiometria={() => {
            dispatch(
              postBiometriaDadoSave({
                callback: (biometricToken: string) => {
                  dispatch(trackingRefin(StepRefin.ORIENTACOES_VIDEO_SELFIE))
                  window.location.href = BridgeService.isBrowser()
                    ? `${process.env.REACT_APP_BIOMETRIA_MODULO}?biometricToken=${biometricToken}&accessToken=${accessToken}&origem=${origem}&idConvenio=${convenioCodigo}&produto=REFINANCIAMENTO`
                    : `${process.env.REACT_APP_BIOMETRIA_MODULO}?biometricToken=${biometricToken}&origem=${origem}&idConvenio=${convenioCodigo}&produto=REFINANCIAMENTO`
                },
              }),
            )
          }}
        />
      ) : (
        <></>
      )}
    </Page>
  )
}
export default Revisao
