enum routes {
  ROOT = '/refinanciamento-novo',
  CONTRATOS = '/refinanciamento-novo/contratos',
  AUTORIZACAO = '/refinanciamento-novo/autorizacao',
  OFERTA_SEGURO = '/refinanciamento-novo/oferta-seguro',
  RESULTADO_SIMULACAO = '/refinanciamento-novo/resultado-simulacao',
  DADOS_RECEBIDOS = '/refinanciamento-novo/dados-recebidos',
  CARREGAMENTO_DADOS = '/refinanciamento-novo/carregamento-dados',
  STATUS_DOCUMENTO = '/refinanciamento-novo/status-documento',
  FOTO_DOCUMENTO = '/refinanciamento-novo/foto-documento',
  MODO_ENVIO_CONTRA_CHEQUE = '/refinanciamento-novo/modo-envio-contracheque',
  FOTO_CONTRA_CHEQUE = '/refinanciamento-novo/foto-contracheque',
  MODO_ENVIO = '/refinanciamento-novo/modo-envio',
  INSTRUCOES_BIOMETRIA = '/refinanciamento-novo/instrucoes-biometria',
  BIOMETRIA = '/refinanciamento-novo/biometria',
  REVISAO = '/refinanciamento-novo/revisao',
  TERMOS_CONTRATACAO = '/refinanciamento-novo/termos',
  TERMO_VISUALIZACAO = '/refinanciamento-novo/termo-visualizacao',
  SUCESSO = '/refinanciamento-novo/sucesso',
  NAO_LIBERADO = '/refinanciamento-novo/nao-liberado',
  CRIACAO_PROPOSTA = '/refinanciamento-novo/criacao-proposta',
  DADOS_BANCARIOS = '/refinanciamento-novo/dados-bancarios',
  ENDERECO = '/refinanciamento-novo/endereco',
  INSS_INDISPONIVEL = '/refinanciamento-novo/inss-indisponivel',
  SEM_EMPRESTIMO = '/refinanciamento-novo/sem-emprestimo',
}
export default routes
