import PDFMerger from 'pdf-merger-js/browser'

import { Anexo as AnexoFoto } from '@store/refinanciamento/fotoDocumentos/types'

/**
 * Junta os pdfs,
 * tanto os gerados das imagens quanto os anexos que eram no formato pdf
 * @param anexos
 * @returns Blob
 */
export const appendPdfs = async (anexos: AnexoFoto[], pdfFile?: File | Blob) => {
  const anexosPdfs = anexos.filter((anexo) => anexo.anexo.tipoExtensao === 'application/pdf')

  if (anexosPdfs.length) {
    const merger = new PDFMerger()

    for (let i = 0; i < anexosPdfs.length; i += 1) {
      const blob = anexosPdfs[i].anexo.blobs
      for (let j = 0; j < blob.length; j += 1) {
        const page = blob[j]
        await merger.add(page.data)
      }
    }
    if (pdfFile) {
      await merger.add(pdfFile)
    }

    // Descomentar para testes, faz o download direto no navegador
    // await merger.save('pdf_gerado')
    const mergedPdf = await merger.saveAsBlob()

    return mergedPdf
  }
  return pdfFile
}
