import getCPF from './getCPF'
import getValidaCliente from './getValidaCliente'
import getContratos from './getContratos'
import postSimulacaoRefinanciamento from './postSimulacaoRefinanciamento'
import postSalvarLeadRefinanciamento from './postSalvarLeadRefinanciamento'
import postValidaMatricula from './postValidaMatricula'
import postCloudMarketRemove from './postCloudMarketRemove'
import getDadosBeneficiarioInss from './getDadosBeneficiarioInss'
import postValidaRefin from './postValidaRefin'
import postAssinarDocumentos from './postAssinarDocumentos'
import postDocumentoAssincrono from './postDocumentoAssincrono'
import postPropostaFinalizar from './postPropostaFinalizar'
import postDadosProposta from './postDadosProposta'
import getSimulacaoId from './getSimulacaoId'
import postAutorizacaoISafe from './postAutorizacaoISafe'
import postProposta from './postProposta'
import postCaptureDocuments from './postCaptureDocuments'
import getCaptura from './getCaptura'
import postAtualizarProposta from './postAtualizarProposta'
import postDocumentosAssinatura from './postDocumentosAssinatura'
import postEditarLead from './postEditarLead'
import postInfoValida from './postInfoValida'
import postLivenessBiometria from './postLivenessBiometria'
import postEnviarDocumentos from './postEnviarDocumentos'
import getBancos from './getBancos'
import getDadosBancariosInss from './getDadosBancariosInss'
import getEndereco from './getEndereco'
import getCidades from './getCidades'
import postRiscoEndereco from './postRiscoEndereco'
import getEstados from './getEstados'
import postInssAsyncStart from './postInssAsyncStart'
import postVerificaPendenciaLead from './postVerificaPendenciaLead'
import postBiometriaDados from './postBiometriaDados'
import getBiometricsValidate from './getBiometricsValidate'
import postEnviarDocumentosPortal from './postEnviarDocumentosPortal'

export default {
  getValidaCliente,
  getContratos,
  getCPF,
  postSimulacaoRefinanciamento,
  postSalvarLeadRefinanciamento,
  postValidaMatricula,
  postCloudMarketRemove,
  getDadosBeneficiarioInss,
  postValidaRefin,
  postAssinarDocumentos,
  postDocumentoAssincrono,
  postPropostaFinalizar,
  postDadosProposta,
  getSimulacaoId,
  postAutorizacaoISafe,
  postProposta,
  postCaptureDocuments,
  getCaptura,
  postAtualizarProposta,
  postDocumentosAssinatura,
  postEditarLead,
  postInfoValida,
  postLivenessBiometria,
  postEnviarDocumentos,
  getBancos,
  getDadosBancariosInss,
  getEndereco,
  getCidades,
  postRiscoEndereco,
  getEstados,
  postInssAsyncStart,
  postVerificaPendenciaLead,
  postBiometriaDados,
  getBiometricsValidate,
  postEnviarDocumentosPortal,
}
