import styled from 'styled-components'

import { Colors } from '@utils/Colors'

export const LineInfo = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 16px 0;
  margin: 10px 0;
  border-bottom: 1px solid ${Colors.GRAY200};
`

export const TitleLine = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 18px;
`

export const Bolder = styled.b`
  font-weight: 700;
`

export const ListStyle = styled.section`
  height: 70vh;
  overflow: auto;
`
