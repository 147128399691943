/**
 * Actions Types
 */

export enum EnderecoTypes {
  ENDERECO_REQUEST = '@refinanciamento/endereco/ENDERECO_REQUEST',
  ENDERECO_SUCCESS = '@refinanciamento/endereco/ENDERECO_SUCCESS',
  ENDERECO_LOADING = '@refinanciamento/endereco/ENDERECO_LOADING',
  ENDERECO_ERROR = '@refinanciamento/endereco/ENDERECO_ERROR',

  CIDADES_REQUEST = '@refinanciamento/CIDADES_REQUEST',
  CIDADES_SUCCESS = '@refinanciamento/CIDADES_SUCCESS',
  CIDADES_LOADING = '@refinanciamento/CIDADES_LOADING',
  CIDADES_ERROR = '@refinanciamento/CIDADES_ERROR',

  ESTADOS_REQUEST = '@refinanciamento/ESTADOS_REQUEST',
  ESTADOS_SUCCESS = '@refinanciamento/ESTADOS_SUCCESS',
  ESTADOS_LOADING = '@refinanciamento/ESTADOS_LOADING',
  ESTADOS_ERROR = '@refinanciamento/ESTADOS_ERROR',

  SET_CEP = '@refinanciamento/endereco/SET_CEP',
  SET_TIPO_LOGRADOURO = '@refinanciamento/endereco/SET_TIPO_LOGRADOURO',
  SET_LOGRADOURO = '@refinanciamento/endereco/SET_LOGRADOURO',
  SET_BAIRRO = '@propsota/endereco/SET_BAIRRO',
  SET_NUMERO = '@refinanciamento/endereco/SET_NUMERO',
  SET_COMPLEMENTO = '@refinanciamento/endereco/SET_COMPLEMENTO',
  SET_ESTADO_SELECIONADO = '@refinanciamento/endereco/SET_ESTADO_SELECIONADO',
  SET_CIDADE_SELECIONADA = '@refinanciamento/endereco/SET_CIDADE_SELECIONADA',
  RISCO_ENDERECO_REQUEST = '@refinanciamento/endereco/RISCO_ENDERECO_REQUEST',
  RESET = 'RESET',
}

/**
 * Data Types
 */

export interface Endereco {
  cep: string
  tipoLogradouro: string
  logradouro: string
  bairro: string
  uf: string
  cidade: string
}

/**
 * State Type
 */

export interface EnderecoState {
  readonly endereco: Endereco[]
  readonly enderecoError: boolean
  readonly enderecoLoading: boolean

  readonly cep: string
  readonly tipoLogradouro: string
  readonly logradouro: string
  readonly bairro: string
  readonly numero: string
  readonly complemento: string
  readonly estadoSelecionado: string
  readonly cidadeSelecionada: string

  readonly estados?: Estado[]
  readonly estadosError?: boolean
  readonly estadosLoading?: boolean

  readonly cidades?: Cidade[]
  readonly cidadesError?: boolean
  readonly cidadesLoading?: boolean
}

/**
 * APIs Type
 */

export interface EnderecoAPI {
  cep: string
  tipoLogradouro: string
  logradouro: string
  bairro: string
  uf: string
  cidade: string
}

export interface EnderecoPayload {
  cep: string
  callback?: () => void
}

export interface RiscoEnderecoPayload {
  callback: (naoLiberado: boolean) => void
}

export interface Estado {
  uf: string
  nome: string
}

export interface Cidade {
  codigo: string
  nome: string
}
export interface CidadesAPI {
  cidades: Cidade[]
}
export interface EstadosAPI {
  uf: string[]
}
