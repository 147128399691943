import { BaseServices, HttpMethod } from '@services/base'

export interface IBiometria {
  document: string
  name: string
  email: string
  celular: string
  proposalCode: string
  onlySelfie: boolean
  photo: string
  encryptedPhoto: string
}

export interface IResponseBiometria {
  unicoId: string
}

export default async (data: IBiometria) => {
  const pathname = process.env.REACT_APP_BIOMETRIA_LIVENESS
  const method = HttpMethod.POST
  const host = process.env.REACT_APP_HOST

  const response = await BaseServices.request<IResponseBiometria>({
    pathname,
    method,
    host,
    data,
  })
  return response
}
