import { BaseServices, HttpMethod } from '@services/base'

import { BridgeService } from './bridge'

export interface DataType {
  cpf: string
  numeroProposta: string
}

export default async (data: DataType, accessToken?: string) => {
  const pathname = BridgeService.isBrowser()
    ? `documents/${data.numeroProposta}/signature`
    : process.env.REACT_APP_DOCUMENTOS_ASSINATURA
  const method = BridgeService.isBrowser() ? HttpMethod.GET : HttpMethod.POST
  const host = process.env.REACT_APP_API_HOST
  const headers: Record<string, string> = {}
  if (BridgeService.isBrowser() && accessToken) {
    headers.Authorization = `Bearer ${accessToken}`
  }

  const response = await BaseServices.request<DocumentoAssinaturaRequest>({
    headers,
    pathname,
    method,
    host,
    data,
  })
  return response
}

export interface DocumentoAssinaturaRequest {
  data: {
    idInteracao: string
    numeroProposta: string
    pendentes: []
    enviados: []
  }
}
