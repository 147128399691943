import React, { useEffect, useMemo, useRef } from 'react'

import { useSelector } from 'react-redux'

import { ApplicationState } from '@store/types'
import { BridgeService } from '@services/bridge'
import { interWbSession } from '@interco/inter-webview-bridge'
import { Loading } from '@atoms'

import { PageVisualizarPdf, PdfGrid } from './styles'
import Autorizacao from './Autorizacao'
import Ccb from './CCB'
import DeclaracaoResidencia from './DeclaracaoResidencia'
import SeguroPrestamista from './SeguroPrestamista'
import SeguroPrestamistaInss from './SeguroPrestamistaInss'
import CCBExercito from './CCBExercito'
import PublicoVulneravel from './PublicoVulneravel'
import ADF from './ADF'
import Portabilidade from './Portabilidade'
import Refinanciamento from './Refinanciamento'
import CapaCCB from './CapaCCB'

const VisualizadorTermo = () => {
  const { isApp } = useSelector((state: ApplicationState) => state.ui.navigation)
  const ref = useRef<HTMLDivElement>(null)

  const PdfAssinatura = useMemo(
    () => ({
      AUTORIZACAO_CONSULTA_MARGEM: <Autorizacao />,
      CCB: <Ccb />,
      DECLARACAO_RESIDENCIA: <DeclaracaoResidencia />,
      SEGURO_PRESTAMISTA: <SeguroPrestamista />,
      SEGURO_PRESTAMISTA_INSS: <SeguroPrestamistaInss />,
      CCB_EXERCITO: <CCBExercito />,
      ADF: <ADF />,
      TERMO_PORTABILIDADE: <Portabilidade />,
      TERMO_REFINANCIAMENTO: <Refinanciamento />,
      PUBLICO_VULNERAVEL: <PublicoVulneravel />,
      CCB_CAPA: <CapaCCB />,
    }),
    [],
  )

  const { loading, documentoRevisao, tituloDocumentoRevisao } = useSelector(
    (state: ApplicationState) => state.refinanciamento.documentosAssinatura,
  )
  const isPublicoVulneravel = documentoRevisao === 'PUBLICO_VULNERAVEL'
  const isAdf = documentoRevisao === 'ADF'

  useEffect(() => {
    if (!BridgeService.isBrowser()) {
      interWbSession.editToolbar(tituloDocumentoRevisao, true)
    }
  }, [tituloDocumentoRevisao])

  if (loading) return <Loading height="150px" />

  return (
    <PageVisualizarPdf id="visualizar-documentos-assinatura">
      <PdfGrid ref={ref} isCartilha={isPublicoVulneravel} isAdf={isAdf} isWeb={!isApp}>
        {PdfAssinatura[documentoRevisao]}
      </PdfGrid>
    </PageVisualizarPdf>
  )
}
export default VisualizadorTermo
