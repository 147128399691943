import React from 'react'

export default () => (
  <svg width="64" height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M0 32C0 14.3269 14.3269 0 32 0C49.6731 0 64 14.3269 64 32C64 49.6731 49.6731 64 32 64C14.3269 64 0 49.6731 0 32Z"
      fill="var(--success500)"
    />
    <circle
      cx="31.9998"
      cy="32"
      r="13.3333"
      stroke="var(--neutral-theme)"
      strokeWidth="2.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M28.8702 32.0117C28.3746 31.5311 27.5832 31.5433 27.1026 32.0389C26.622 32.5345 26.6342 33.3259 27.1298 33.8065L28.8702 32.0117ZM30.5 35.3333L29.6298 36.2307C30.1147 36.7009 30.8853 36.7009 31.3702 36.2307L30.5 35.3333ZM36.8702 30.8974C37.3658 30.4168 37.378 29.6254 36.8974 29.1298C36.4168 28.6342 35.6254 28.622 35.1298 29.1026L36.8702 30.8974ZM27.1298 33.8065L29.6298 36.2307L31.3702 34.436L28.8702 32.0117L27.1298 33.8065ZM31.3702 36.2307L36.8702 30.8974L35.1298 29.1026L29.6298 34.436L31.3702 36.2307Z"
      fill="var(--neutral-theme)"
    />
  </svg>
)
