import React from 'react'

import { PagePdf, TemplatesPDF } from '@interco/cp-react-ui-lib'

const PublicoVulneravel = () => (
  <PagePdf variant="web">
    <TemplatesPDF.PagePublicoVulneravel />
  </PagePdf>
)

export default PublicoVulneravel
