import { call, put, select } from 'typed-redux-saga'

import services from '@services'
import { showError } from '@store/ui/error/actions'
import { ErrorCodes } from '@utils/enums'
import { removeMask } from '@utils/masks'
import { ApplicationState } from '@store/types'
import { AnyAction } from '@reduxjs/toolkit'
import { BridgeService } from '@services/bridge'
import { HttpStatus } from '@services/base'
import { logout } from '@utils/functions'
import { ErroType } from '@utils/types'

import { SimulacaoIdPayload } from '../types'
import { getSimulacaoId, setLoadingGetSimulacaoId, setSimulacaoId } from '../actions'

export default function* getSimulacaoIdSaga(action: AnyAction) {
  const payload = action.payload as SimulacaoIdPayload

  try {
    yield put(setLoadingGetSimulacaoId(true))
    const cpfSession = yield* select((state: ApplicationState) => state.session.cpf)
    const cpfDados = yield* select(
      (state: ApplicationState) => state.refinanciamento.dadosPessoais.cpf,
    )
    const { accessToken } = yield* select((state: ApplicationState) => state.session)
    const cpf = cpfDados || cpfSession
    if (cpf) {
      const { data } = yield* call(services.getSimulacaoId, removeMask(String(cpf)), accessToken)

      yield put(setSimulacaoId(data.simulationId))
      yield put(setLoadingGetSimulacaoId(false))
      yield payload.callback()
    } else {
      throw new Error('Erro ao recuperar CPF')
    }
  } catch (error) {
    const erroTyped = error as ErroType
    yield put(setLoadingGetSimulacaoId(false))
    if (BridgeService.isBrowser() && erroTyped.status === HttpStatus.UNAUTHORIZED) {
      yield put(
        showError({
          title: 'Sessão expirada',
          message: 'Efetue o login novamente.',
          actionTitle: 'Continuar',
          functionCallback: () => logout(),
        }),
      )
    } else {
      yield put(
        showError({
          title: 'Erro ao buscar dados da simulação',
          message: 'Ocorreu um erro ao buscar os dados da simulação',
          actionTitle: 'Tentar Novamente',
          code: ErrorCodes.RE_006_BUSCAR_IDSIMULACAO,
          actionCallback: () => getSimulacaoId(payload),
        }),
      )
    }
  }
}
