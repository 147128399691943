import { call, put, select } from 'typed-redux-saga'
import { AnyAction } from 'redux'

import { ApplicationState } from '@store/types'
import { showError } from '@store/ui/error/actions'
import { ErrorCodes, PendenciaEnumV3, TipoEmprestimo } from '@utils/enums'
import postSimulacaoRefinanciamento, {
  SimulacaoRequest,
} from '@services/postSimulacaoRefinanciamento'
import { getParcelaAtualSimulacoes, logout } from '@utils/functions'
import { BridgeService } from '@services/bridge'
import { HttpStatus } from '@services/base'
import { ErroType } from '@utils/types'

import { SimulacaoRequestPayload } from '../types'
import {
  selectSimulacao,
  setParcelaSelecionada,
  setRecallInss,
  setSaldoDevedor,
  setTotalParcelas,
  simulacaoError,
  simulacaoRequest,
  simulacaoSuccess,
} from '../actions'

export default function* postSimulacao(action: AnyAction) {
  const payload = action.payload as SimulacaoRequestPayload
  const { counterRecall } = yield* select(
    (state: ApplicationState) => state.refinanciamento.simulacao,
  )
  const { accessToken } = yield* select((state: ApplicationState) => state.session)

  try {
    const contratosSelecionados = yield* select(
      (state: ApplicationState) => state.refinanciamento.contratos.contratosSelecionados || [],
    )
    const { dadosPessoais } = yield* select((state: ApplicationState) => state.refinanciamento)
    const sessionState = yield* select((state: ApplicationState) => state.session)

    const novoArrayContratos = contratosSelecionados.map((contrato) => {
      const { valorQuitacao, numeroContrato, valorProximaParcela } = contrato
      return {
        contractNumber: numeroContrato,
        installmentAmount: valorProximaParcela,
        debtBalance: valorQuitacao,
      }
    })

    const [primeiroContrato] = contratosSelecionados

    const simulacao: SimulacaoRequest = {
      socialNumber: dadosPessoais.cpf || sessionState.cpf || '',
      benefitNumber: primeiroContrato.matricula,
      proposalNumber: primeiroContrato.numeroProposta,
      categoryId: String(primeiroContrato.convenioId),
      contracts: novoArrayContratos,
    }

    const { data } = yield* call(postSimulacaoRefinanciamento, simulacao, accessToken)

    if (data?.pending === PendenciaEnumV3.PENDENCIA_VALIDACAO) {
      yield put(setRecallInss(1))
    }
    if (counterRecall > 2) {
      // Tenta até 3 vezes chamar a api de resultados, se a mesma permanecer "em validação" leva para tela de não liberado
      // yield put(setLoadingInssApis(false))
      yield payload.callback({
        pending: PendenciaEnumV3.PENDENCIA_IRREVERSIVEL,
        simulations: [],
        simulationsWithInsurance: [],
      })
    } else {
      yield put(setSaldoDevedor(data?.debtBalance))
      yield put(simulacaoSuccess(data))

      // Se tiver simulações mas não tiver array de simulações com seguro / Se tiver somente o de seguro e sem o array de simulações
      if (
        data?.simulations &&
        data?.simulations.length > 0 &&
        ((data?.simulationsWithInsurance && data?.simulationsWithInsurance.length === 0) ||
          !data?.simulationsWithInsurance)
      ) {
        const getParcelaAtual = getParcelaAtualSimulacoes(data.simulations)
        yield put(setParcelaSelecionada(getParcelaAtual))
        yield put(setTotalParcelas(getParcelaAtual.numberOfInstallments))
        yield put(selectSimulacao(TipoEmprestimo.SEM_CREDITO_PROTEGIDO))
      } else if (
        ((data?.simulations && data?.simulations.length === 0) || !data?.simulations) &&
        data?.simulationsWithInsurance &&
        data?.simulationsWithInsurance.length > 0
      ) {
        const getParcelaAtual = getParcelaAtualSimulacoes(data.simulationsWithInsurance)
        yield put(setParcelaSelecionada(getParcelaAtual))
        yield put(setTotalParcelas(getParcelaAtual.numberOfInstallments))
        yield put(selectSimulacao(TipoEmprestimo.CREDITO_PROTEGIDO))
      }
      // Caso tenha as duas opções de simulacoes deve setar as parcelas selecionadas e o total de parcelas após escolha do usuario
      yield payload.callback({
        pending: data.pending,
        simulations: data?.simulations || [],
        simulationsWithInsurance: data?.simulationsWithInsurance || [],
      })
    }
  } catch (error) {
    const erroTyped = error as ErroType
    yield put(simulacaoError())
    if (BridgeService.isBrowser() && erroTyped.status === HttpStatus.UNAUTHORIZED) {
      yield put(
        showError({
          title: 'Sessão expirada',
          message: 'Efetue o login novamente.',
          actionTitle: 'Continuar',
          functionCallback: () => logout(),
        }),
      )
    } else {
      yield put(
        showError({
          title: 'Erro na simulação',
          message: 'Ocorreu um erro ao simular seu refinanciamento',
          actionTitle: 'Tentar Novamente',
          code: ErrorCodes.RE_002_SIMULACAO,
          actionCallback: () => simulacaoRequest(payload),
        }),
      )
    }
  }
}
