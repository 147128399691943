/**
 * Actions Types
 */

import { TipoDocs } from '../listaDocumentos/types'

export enum DocumentosAssinaturaTypes {
  SET_DOCUMENTOS = '@refinanciamento/documentosAssinatura/SET_DOCUMENTOS',
  SET_ID_ITERACAO = '@refinanciamento/documentosAssinatura/SET_ID_ITERACAO',
  SET_PROPOSTA_ATUALIZADA = '@refinanciamento/documentosAssinatura/SET_PROPOSTA_ATUALIZADA',
  SET_DOCUMENTOS_GERADOS = '@refinanciamento/documentosAssinatura/SET_DOCUMENTOS_GERADOS',
  SET_DOCUMENTO_REVISAO = '@refinanciamento/documentosAssinatura/SET_DOCUMENTO_REVISAO',
  SET_CHECK_MESSAGE_REVISAO = '@refinanciamento/documentosAssinatura/SET_CHECK_MESSAGE_REVISAO',
  SET_TITLE_DOCUMENTO_REVISAO = '@refinanciamento/documentosAssinatura/SET_TITLE_DOCUMENTO_REVISAO',
  RESET = 'RESET',
  SET_LOADING = '@refinanciamento/documentosAssinatura/SET_LOADING',

  ATUALIZAR_PROPOSTA = '@refinanciamento/documentosAssinatura/ATUALIZAR_PROPOSTA',
  GERAR_DOCUMENTO_MERGE_SAGA = '@refinanciamento/documentosAssinatura/GERAR_DOCUMENTO_MERGE_SAGA',
  SEND_ASSINATURA = '@refinanciamento/documentosAssinatura/SEND_ASSINATURA',
  FINALIZAR_PROPOSTA = '@refinanciamento/documentosAssinatura/FINALIZAR_PROPOSTA',
  FINALIZAR_PROPOSTA_ERROR = '@refinanciamento/documentosAssinatura/FINALIZAR_PROPOSTA_ERROR',
  FINALIZAR_PROPOSTA_SUCCESS = '@refinanciamento/documentosAssinatura/FINALIZAR_PROPOSTA_SUCCESS',

  EDITAR_LEAD = '@refinanciamento/documentosAssinatura/EDITAR_LEAD',

  SET_ASSINATURAS = '@refinanciamento/documentosAssinatura/SET_ASSINATURAS',
  SEND_DOCUMENTO_ASSINCRONO = '@refinanciamento/documentosAssinatura/SEND_DOCUMENTO_ASSINCRONO',

  BUSCAR_DOCUMENTOS_ASSINATURA_REQUEST = '@refinanciamento/documentosAssinatura/BUSCAR_DOCUMENTOS_ASSINATURA_REQUEST',
}

/**
 * State Type
 */

export interface DocumentosAssinaturaState {
  readonly documentos: {
    idTipoDocumento: TipoDocs
  }[]
  readonly propostaAtualizada: boolean
  readonly documentosGerados: boolean
  readonly idIteracao: number | string
  readonly documentoRevisao: TipoDocs
  readonly checkMessageRevisao: string
  readonly tituloDocumentoRevisao: string
  readonly loading: boolean
  readonly loadingFinalizarProposta: boolean
  readonly erroFinalizarProposta: boolean
  readonly assinaturas: string[]
  readonly propostaFinalizada: boolean
}

export type FinalizarPropostaPayload = {
  callback: () => void
  callbackTryAgain?: () => void
}

export type EditarLeadPayload = {
  numeroProposta?: string
  situacao?: string
  callback?: () => void
}

export type AssinaturaDataType = {
  timestamp_assinatura: string
  ip_cliente: string
  id_documentos_assinatura: string
}
export type ReturnType = {
  data: AssinaturaDataType
}

export type GerarPayload = {
  tipo: TipoDocs
  assinaturaData: AssinaturaDataType
  type: string
}

export type SendDocumentoAssincronoPayload = {
  success: () => void
}

export interface BuscarDocumentosAssinaturaPayload {
  callback: () => void
}
