import React, { useEffect, useState } from 'react'

import { useDispatch, useSelector } from 'react-redux'
import { v4 as uuidv4 } from 'uuid'
import { useNavigate } from 'react-router-dom'

import { ConveniosCodes, StepRefin, TipoDocumento, TipoMiniatura } from '@utils/enums'
import { ApplicationState } from '@store/types'
import { Camera } from '@interco/cp-react-ui-lib'
import { setHideFooter, setHideHeader } from '@store/ui/navigation/actions'
import {
  sendToS3,
  setAnexos,
  setIsError,
  setIsLoading,
  setRollbackRouterError,
} from '@store/refinanciamento/fotoDocumentos/actions'
import routes from '@routes/RefinV2/routes'
import { Page } from '@templates'
import { useWindowSize } from '@hooks'
import { ReviewFoto } from '@organisms/ReviewFoto'
import Tag from '@utils/Tag'
import { postBiometriaDadoSave } from '@store/biometria/actions'
import { BridgeService } from '@services/bridge'
import { trackingRefin } from '@store/refinanciamento/simulacao/actions'
import { interWbSession, IWbTic } from '@interco/inter-webview-bridge'

const ticBotaoContinuar = () => {
  const ticProps: IWbTic = {
    name: 'btn_solicitar_biometria',
    module: 'refinanciamento_consignado',
    action: 'solicitar_biometria',
    params: {},
  }

  interWbSession.tic(ticProps)
}

const Foto = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [habilitaCamera, setHabilitaCamera] = useState(true)
  const [foto, setFoto] = useState('')
  const [descricaoFoto, setDescricaoFoto] = useState('Frente')
  const [fotoFrente, setFotoFrente] = useState(false)
  const [fullScreenMobile, setFullScreenMobile] = useState(false)

  const { anexos, isLoading, isDone } = useSelector(
    (state: ApplicationState) => state.refinanciamento.fotoDocumentos,
  )
  const { convenioCodigo } = useSelector(
    (state: ApplicationState) => state.refinanciamento.contratos,
  )
  const { tipoDocumento } = useSelector(
    (state: ApplicationState) => state.refinanciamento.documentos,
  )

  const { isApp, origem } = useSelector((state: ApplicationState) => state.ui.navigation)
  const { accessToken } = useSelector((state: ApplicationState) => state.session)

  const size = useWindowSize()

  useEffect(() => {
    if (size.width < 680) {
      setFullScreenMobile(true)
    } else {
      setFullScreenMobile(false)
    }
    return () => {
      setFullScreenMobile(false)
    }
  }, [size])

  useEffect(() => {
    if (isDone) {
      Tag.enviarTagueamentoWeb({
        element_name: '',
        section_name: 'Foto de documento',
        element_action: 'submit',
        c_page: window.location.href,
        redirect_url: `${process.env.REACT_APP_BIOMETRIA_MODULO}`,
        step: 'null',
      })
      if (convenioCodigo === ConveniosCodes.SIAPE_SERVIDOR_FEDERAL) {
        navigate(routes.STATUS_DOCUMENTO)
      } else {
        ticBotaoContinuar()
        dispatch(
          postBiometriaDadoSave({
            callback: (biometricToken: string) => {
              dispatch(trackingRefin(StepRefin.ORIENTACOES_VIDEO_SELFIE))
              window.location.href = BridgeService.isBrowser()
                ? `${process.env.REACT_APP_BIOMETRIA_MODULO}?biometricToken=${biometricToken}&accessToken=${accessToken}&origem=${origem}&idConvenio=${convenioCodigo}&produto=REFINANCIAMENTO`
                : `${process.env.REACT_APP_BIOMETRIA_MODULO}?biometricToken=${biometricToken}&origem=${origem}&idConvenio=${convenioCodigo}&produto=REFINANCIAMENTO`
            },
          }),
        )
      }
    }
  }, [accessToken, convenioCodigo, dispatch, isApp, isDone, navigate, origem])

  useEffect(() => {
    if (isApp || fullScreenMobile) {
      dispatch(setHideFooter(true))
      dispatch(setHideHeader(true))
    } else {
      dispatch(setHideFooter(false))
      dispatch(setHideHeader(false))
    }
  }, [dispatch, fullScreenMobile, isApp])

  const onConfirmReview = (fotografia: string) => {
    if (anexos.length === 2) {
      dispatch(setIsLoading(true))
      dispatch(setIsError(false))
      dispatch(setRollbackRouterError(routes.STATUS_DOCUMENTO))
      dispatch(sendToS3({ tipo: 'RG_CNH', anexos }))
    } else {
      const img = new Image()
      img.onload = () => {
        dispatch(
          setAnexos({
            anexo: {
              id: uuidv4(),
              nome: `RG/CNH ${anexos ? anexos.length + 1 : 1}`,
              tipoExtensao: 'jpeg',
              blobs: [
                {
                  height: img.height,
                  width: img.width,
                  data: fotografia,
                },
              ],
            },
            tipo: 'RG_CNH',
          }),
        )
        if (anexos.length === 2) {
          dispatch(setIsLoading(true))
          dispatch(setIsError(false))
          dispatch(setRollbackRouterError(routes.STATUS_DOCUMENTO))
          dispatch(sendToS3({ tipo: 'RG_CNH', anexos }))
        }
        setFotoFrente(true)
        setDescricaoFoto('Verso')
      }
      img.src = fotografia
    }
  }

  useEffect(() => {
    if (fotoFrente) {
      setHabilitaCamera(true)
    }
  }, [fotoFrente])

  const tipoTexto = () => {
    if (tipoDocumento === TipoDocumento.CNH) {
      return `${descricaoFoto} da ${tipoDocumento?.toUpperCase()}`
    }
    return `${descricaoFoto} do ${tipoDocumento?.toUpperCase()}`
  }

  const tipoMiniatura = () => {
    if (descricaoFoto === 'Frente') {
      if (tipoDocumento === TipoDocumento.RG) return TipoMiniatura.RG_FRENTE
      if (tipoDocumento === TipoDocumento.CNH) return TipoMiniatura.CNH_FRENTE
      return TipoMiniatura.RNE_FRENTE
    }
    if (tipoDocumento === TipoDocumento.RG) return TipoMiniatura.RG_VERSO
    if (tipoDocumento === TipoDocumento.CNH) return TipoMiniatura.CNH_VERSO
    return TipoMiniatura.RNE_VERSO
  }

  return habilitaCamera ? (
    <Page>
      <Camera
        audio={false}
        mask="secondary"
        mode="photo"
        facing="back"
        mobile={isApp || fullScreenMobile}
        onCapturedPhoto={(fotografia) => {
          setHabilitaCamera(false)
          setFoto(String(fotografia))
        }}
        titleDescription={tipoTexto()}
        imagemMiniatura={tipoMiniatura()}
      />
    </Page>
  ) : (
    <ReviewFoto
      fotografia={foto}
      onConfirm={onConfirmReview}
      setHabilitaCamera={() => {
        setHabilitaCamera(true)
      }}
      isLoading={isLoading}
      forceIsMobile={fullScreenMobile}
    />
  )
}

export default Foto
