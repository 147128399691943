import React, { useState } from 'react'

import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

import { Page } from '@templates'
import { StepRefin } from '@utils/enums'
import { useMobileUserInfo, useTrackingRefin } from '@hooks'
import { Paragraph } from '@atoms'
import { Button } from '@interco/inter-ui/components/Button'
import { Alert } from '@interco/inter-ui/components/Alert'
import UserAccount from '@interco/icons/core/human/ic_user'
import Info from '@interco/icons/core/status/ic_info_circle'
import routes from '@routes/Refin/routes'
import { validaRequest } from '@store/refinanciamento/valida/actions'
import { CallBackContatoType } from '@store/refinanciamento/valida/types'
import { salvarLeadRequest } from '@store/refinanciamento/simulacao/actions'
import { ApplicationState } from '@store/types'
import { Colors } from '@utils/Colors'

import { BoxContato, BoxDescription, BoxTitle, Description, Redirect, Title } from './styles'
import { BottomSheetContato } from './Bottomsheet'

const ConfirmarContato = () => {
  useTrackingRefin(StepRefin.CONTATOS)
  const [showBottomSheet, setShowBottomSheet] = useState(false)
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { loading } = useSelector((state: ApplicationState) => state.refinanciamento.valida)

  const userInfo = useMobileUserInfo()
  return (
    <Page
      stickyFooter={
        <>
          <Button
            variant="primary"
            isLoading={loading}
            disabled={loading}
            onClick={() => {
              dispatch(
                validaRequest({
                  callback: ({ contato }: CallBackContatoType) => {
                    if (contato) {
                      dispatch(
                        salvarLeadRequest({
                          callback: () => {
                            navigate(routes.RESULTADO)
                          },
                        }),
                      )
                    } else {
                      navigate(routes.NAO_LIBERADO)
                    }
                  },
                }),
              )
            }}
          >
            Continuar
          </Button>
        </>
      }
    >
      <Paragraph
        fontSize="16px"
        fontWeight={600}
        fontFamily="sora"
        color={Colors.GRAY500}
        lineHeight="20px"
      >
        Confirme seus dados de contato:
      </Paragraph>
      <BoxContato>
        <BoxTitle>
          <UserAccount width={24} height={24} color={Colors.GRAY400} />
          <Title color={Colors.GRAY400} margin="0px 0px 0px 8px" fontWeight="700">
            Contato
          </Title>
        </BoxTitle>
        <BoxDescription>
          <Description>
            <Paragraph margin="0" color={Colors.GRAY400}>
              E-mail
            </Paragraph>
            <Paragraph color={Colors.GRAY500} fontWeight="700">
              {userInfo?.email}
            </Paragraph>
          </Description>
          <Description>
            <Paragraph margin="0" color={Colors.GRAY400}>
              Telefone
            </Paragraph>
            <Paragraph color={Colors.GRAY500} fontWeight="700">
              {userInfo?.cellPhoneNumber}
            </Paragraph>
          </Description>
        </BoxDescription>
      </BoxContato>
      <Alert
        title="Alterar dados de contato"
        customBackgroundColor={Colors.ALERT100}
        customIcon={<Info height={25} width={25} color={Colors.GRAY500} />}
      >
        Para alterar dados pessoais da sua Conta Digital Inter,
        <Redirect onClick={() => setShowBottomSheet(true)}> confira as instruções.</Redirect>
      </Alert>
      {showBottomSheet ? <BottomSheetContato setShowBottomSheet={setShowBottomSheet} /> : <></>}
    </Page>
  )
}

export default ConfirmarContato
