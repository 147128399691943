import { call, put, select } from 'typed-redux-saga'

import services from '@services'
import { ApplicationState } from '@store/types'
import { base64toBlob, fileToBase64, logout } from '@utils/functions'
import {
  deleteAnexo as deleteAnexoExtrato,
  setIsDone as setIsDoneExtrato,
  setIsError as setIsErrorExtrato,
  setIsLoading as setIsLoadingExtrato,
} from '@store/refinanciamento/contrachequeDocumentos/actions'
import { BridgeService } from '@services/bridge'
import { HttpStatus } from '@services/base'
import { showError } from '@store/ui/error/actions'
import { ErroType } from '@utils/types'
import GeneratePdf from '@services/GeneratePdf'
import { appendPdfs } from '@services/AppendPdf'

import { Anexo } from '../types'
import { deleteAnexo, setIsDone, setIsError, setIsLoading } from '../actions'

interface Props {
  payload: {
    anexos: Anexo[]
    tipo: string
    callback?: () => void
  }
  type: string
}
export default function* sendToS3PDFSaga({ payload }: Props) {
  const tipoDocumentos = yield* select(
    (s: ApplicationState) => s.refinanciamento.proposta.tipoDocumento,
  )
  const { accessToken } = yield* select((state: ApplicationState) => state.session)

  const pdfFileFromImg: File = yield GeneratePdf.generate(payload.anexos)
  const pdfFinal: File = yield appendPdfs(payload.anexos, pdfFileFromImg)
  const base64: string = yield fileToBase64(pdfFinal)

  try {
    if (BridgeService.isBrowser()) {
      const formDataDoc = new FormData()

      payload.anexos.forEach(({ anexo }) => {
        const blob = base64toBlob(anexo.blobs[0].data)
        const imageFile = new File([blob], 'image.png', { type: blob.type })

        formDataDoc.append('file', imageFile, anexo.nome)
      })
      formDataDoc.append(
        'documentType',
        tipoDocumentos?.filter((tipo) => tipo.idTipoDocumento === payload.tipo)[0]
          ?.idTipoDocumento || payload.tipo,
      )
      yield* call(services.postEnviarDocumentosPortal, formDataDoc, accessToken)
    } else if (base64) {
      yield* call(
        services.postEnviarDocumentos,
        {
          file: base64.split(',').pop() || '',
          documentType:
            tipoDocumentos?.filter((tipo) => tipo.idTipoDocumento === payload.tipo)[0]
              ?.idTipoDocumento || payload.tipo,
          extension: 'pdf',
        },
        accessToken,
      )
    }

    if (payload.tipo === 'RG_CNH') {
      yield put(setIsDone(true))
      yield put(setIsLoading(false))
    } else {
      yield put(setIsDoneExtrato(true))
      yield put(setIsLoadingExtrato(false))
    }
  } catch (error) {
    const erroTyped = error as ErroType
    if (BridgeService.isBrowser() && erroTyped.status === HttpStatus.UNAUTHORIZED) {
      yield put(
        showError({
          title: 'Sessão expirada',
          message: 'Efetue o login novamente.',
          actionTitle: 'Continuar',
          functionCallback: () => logout(),
        }),
      )
    }
    if (payload.tipo === 'RG_CNH') {
      yield put(setIsError(true))
      yield put(setIsLoading(false))
      yield put(deleteAnexo(0))
    } else {
      yield put(setIsErrorExtrato(true))
      yield put(setIsLoadingExtrato(false))
      yield put(deleteAnexoExtrato(0))
    }

    if (payload.callback) {
      yield payload.callback()
    }
  }
}
