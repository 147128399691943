import React, { useEffect } from 'react'

import { useSelector } from 'react-redux'

import { Page } from '@templates'
import { StepRefin } from '@utils/enums'
import { useTrackingRefin } from '@hooks'
import { validaEnvHomeByBridge } from '@utils/functions'
import GridStyled from '@atoms/GridStyled'
import { Button } from '@interco/inter-ui/components/Button'
import { Colors } from '@utils/Colors'
import { ApplicationState } from '@store/types'
import { ColorWrapper, TextParagraph } from '@atoms/TextParagraph'
import Tag from '@utils/Tag'
import Timer from '@icons/Signals/Timer'

const DadosRecebidos = () => {
  useTrackingRefin(StepRefin.DADOS_RECEBIDO)
  const { origemGoBack, isApp } = useSelector((state: ApplicationState) => state.ui.navigation)
  const { utmCampaign } = useSelector((state: ApplicationState) => state.session)

  useEffect(() => {
    Tag.enviarTagueamentoApp({
      name: 'CONSIGNADO_REFIN_ACESSO',
      ref_figma: '4',
      tela: 'Refinanciamento Consig',
      content_action: 'Dado carregado',
      product: 'REFINANCIAMENTO_CONSIGNADO',
      utm_campaign: utmCampaign,
    })
    Tag.enviarTagueamentoWeb({
      element_name: '',
      section_name: 'Refinanciamento Consig',
      element_action: 'page_loaded',
      c_page: window.location.href,
      redirect_url: '',
      step: 'null',
    })
  }, [utmCampaign])

  return (
    <Page
      stickyFooter={
        <>
          <Button
            variant="primary"
            onClick={() => {
              window.location.href = validaEnvHomeByBridge(String(origemGoBack), isApp) || ''
            }}
          >
            Ir para a home
          </Button>
        </>
      }
    >
      <GridStyled justify="space-around">
        <Timer />
      </GridStyled>
      <ColorWrapper
        color={Colors.GRAY500}
        fontWeight="600"
        margin="24px 0 16px 0"
        style={{ textAlign: 'center' }}
      >
        <TextParagraph variant="headline-h1">
          Recebemos sua solicitação de refinanciamento
        </TextParagraph>
      </ColorWrapper>

      <TextParagraph variant="body-3" textAlign="center" margin="16px 0 0 0">
        Em breve, entraremos em contato para confirmar a negociação.
      </TextParagraph>
    </Page>
  )
}

export default DadosRecebidos
